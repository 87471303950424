import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import InspirationItems from "./InspirationItems";
import HeroSection from "../components/HeroSection";
import univers from "../../../assets/images/inspirations.jpg";

const InspirationsPage = () => {
  const livraisonMontageBreadcrumbs = [
    { label: "ADM", link: "/" },
    { label: "services", link: "/services" },
    { label: "livraison & montage", link: "/livraison-montage" },
  ];

  const article = {
    title: "Trouvez l’inspiration !",
    body:
      "<p>Au travers des différentes inspirations que notre équipe a sélectionnées, inspirez vous et faites de votre projet le projet qui fera la différence.</p>" +
      "<p> Retrouvez nos dernières inspirations catalogues.</p>",
    imageURL: univers,
    link: null,
  };

  const inspirationBC = [
    { label: "Adm mobilier", url: "/" },
    { label: "Nos inspirations", url: "/nos-inspirations" },
  ];

  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;

  return (
    <GenericLayout
      title={`Les inspirations d’Adm Mobilier : Catalogue mobilier ${previousYear}-${previousYear}`}
      h1="Nos inspirations"
      breadCrumbs={inspirationBC}
    >
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection index={0} article={article} />
        </div>
      </div>

      <div className="mx-4">
        <h2 className="sm:mt-24 mt-12 font-freight sm:text-40 text-3xl sm:jusitfy-left justify-center tracking-tight">
          Retrouvez nos dernières inspirations catalogues
        </h2>
      </div>
      <InspirationItems />
    </GenericLayout>
  );
};

export default InspirationsPage;
