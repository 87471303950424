import React, { useEffect, useState } from "react";
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export default function SearchInput() {
  const isMobile = useMediaQuery(640);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.query_url) {
      setInputValue(params?.query_url);
    }
  }, [params?.query_url]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputValue?.length) navigate(`/recherche/${inputValue}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };

  return (
    <div>
      <div className="sm:mt-1 flex rounded-md shadow-sm ">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="search_input"
            id="search"
            onKeyDown={handleKeyDown}
            onChange={(event) => {
              setInputValue(event.currentTarget.value);
            }}
            value={inputValue}
            className="block w-full bg-transparent border border-gray-300 border-r-0 rounded py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm"
            placeholder="Rechercher un produit par son nom"
          />
        </div>
        {((isMobile && inputValue?.length) || !isMobile) && (
          <button
            type="button"
            onClick={handleSearch}
            className={classNames(
              "relative items-center  rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-kakhi-500 focus:outline-none focus:ring-1 focus:ring-kakhi-500"
            )}
          >
            {isMobile ? (
              <ArrowRightIcon className="h-4 w-4" />
            ) : (
              <span>Rechercher</span>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
