import classNames from "classnames";
import React from "react";
import { isLarge, isRegular } from "../../pages/CategoryPage";
import { formatPrice } from "../../_helpers/price";

const ProductCard = ({
  name,
  slug = "",
  price,
  thumb_type = "regular",
  cover_picture_url,
  reference,
  readOnly = false,
  isV2 = false,
}) => {
  return (
    <a
      href={`/mobiliers-accessoires/${slug}`}
      className={classNames(
        {
          "col-span-1": isRegular(thumb_type),
          "sm:col-span-2 col-span-1": isLarge(thumb_type),
          "cursor-pointer": !readOnly,
        },
        "bg-beige-500 flex flex-col rounded-xl"
      )}
    >
      {cover_picture_url && (
        <div
          className={classNames(
            {
              "aspect-h-9 aspect-w-8 bg-center bg-cover": isRegular(thumb_type),
              "aspect-w-16 aspect-h-9 bg-center bg-cover": isLarge(thumb_type),
            },
            "overflow-hidden rounded-t-xl h-full"
          )}
          style={{
            backgroundImage: `url(${cover_picture_url})`,
          }}
        />
      )}
      <div className={"p-4 text-center"}>
        <h2
          className={classNames("font-grotesque sm:text-20 text-16 ", {
            "hidden sm:block": !isV2,
          })}
        >
          {reference}
        </h2>
        {price && (
          <p className="inline-block pt-2 mt-2 border-t-[1px] border-kakhi-500">
            à partir de {formatPrice(price)}
          </p>
        )}
        {reference && !isV2 && (
          <p className="font-grotesque text-justify sm:text-center sm:text-base">
            {reference}
          </p>
        )}
      </div>
    </a>
  );
};

export default ProductCard;
