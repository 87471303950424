import slugify from "react-slugify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { DirectUpload } from "@rails/activestorage";
import classNames from "classnames";
import Loading from "../../../components/utils/Loading";
import Error from "../../../components/Error";
import {
  createUniverse,
  deleteUniverse,
  updateUniverse,
  useDeleteUniverse,
  useUniverse,
} from "../../../_services/universe-services";
import _ from "lodash";
import SelectRealisations from "../../../components/forms/SelectRealisations";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import ProductCard from "../../../components/products/ProductCard";
import SelectReferences from "../../../components/forms/SelectReferences";
import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";
import { ReferenceItem } from "../../ReferencePage";

const AdminUniversesAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [coverPictureBlobSignedID, setCoverPictureBlobSignedID] = useState({});
  const [coverPicture, setCoverPicture] = useState({});
  const [dataCoverPicture, setDataCoverPicture] = useState("");
  const [category, setCategory] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");

  const navigate = useNavigate();

  let { universe_slug } = useParams();
  let isEditMode = !!universe_slug;

  const [isLoaded, setIsLoaded] = useState(true);
  const [selectedRealisations, setSelectedRealisations] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState([]);

  const goBack = () => {
    navigate("/admin/univers");
  };

  const uploadFile = (input, file) => {
    setIsLoaded(false);
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        setCoverPictureBlobSignedID(blob.signed_id);
        setIsLoaded(true);
      }
    });
  };

  const handleFileUpload = (e) => {
    Array.from(e.target.files).forEach((file, index) => {
      setDataCoverPicture(URL.createObjectURL(file));
      uploadFile(e.target, file, index);
    });
    e.target.files.value = null;
  };

  const onSubmit = (data) => {
    let submitted_data = {
      universe: {
        ...data,
        realisation_ids: selectedRealisations.map((realisation) => {
          return realisation.id;
        }),
        reference_ids: selectedReferences.map((reference) => {
          return reference.id;
        }),
        cover_picture: coverPictureBlob,
      },
    };

    return isEditMode
      ? updateUniverse(universe_slug, submitted_data, goBack)
      : createUniverse(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteUniverse(universe_slug, goBack);
  };

  const { universe, isError, isLoading } = useUniverse(universe_slug);

  useEffect(() => {
    if (!!universe) {
      const fields = [
        "name",
        "description",
        "slug",
        "release_date",
        "reference",
      ];
      fields.forEach((field) =>
        setValue(field, universe.data.attributes[field])
      );
      setCoverPicture(universe.data.attributes.cover_picture_url);
      // setPreviewURL(universe.data.attributes.cover_picture_url);

      setSelectedReferences(
        getAllIncludedResourcesForAGivenType(universe, "reference")
      );
      setSelectedRealisations(
        _.filter(universe.included, (include) => {
          return include.type === "realisation";
        })
      );
    }
  }, [universe]);

  if (isLoading && !!universe_slug) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col">
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"text-3xl py-5"}>
              {isEditMode
                ? "Édition d'un univers"
                : "Création d'un nouvel univers "}
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Informations générales
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Ces informations seront visibles sur la page de description
                    de l&apos;univers.
                  </p>
                </div>

                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
                  {/*  <label*/}
                  {/*    htmlFor="reference"*/}
                  {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"*/}
                  {/*  >*/}
                  {/*    Référence*/}
                  {/*  </label>*/}
                  {/*  <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
                  {/*    <input*/}
                  {/*      {...register("reference")}*/}
                  {/*      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"*/}
                  {/*      autoComplete="name"*/}
                  {/*      type="text"*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Nom
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("name")}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        autoComplete="name"
                        type="text"
                      />
                    </div>
                  </div>
                  {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
                  {/*  <label*/}
                  {/*    htmlFor="slug"*/}
                  {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"*/}
                  {/*  >*/}
                  {/*    URL*/}
                  {/*  </label>*/}
                  {/*  <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
                  {/*    <div className="max-w-lg flex rounded shadow-sm">*/}
                  {/*      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">*/}
                  {/*        adm-mobilier.com/univers/*/}
                  {/*      </span>*/}
                  {/*      <input*/}
                  {/*        {...register("slug", { required: true, disabled: true })}*/}
                  {/*        type="text"*/}
                  {/*        id="slug"*/}
                  {/*        value={slugify(watch("name"))}*/}
                  {/*        className="flex-1 block w-full focus:ring-kakhi-500 focus:border-kakhi-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-50 "*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
                  {/*  <label*/}
                  {/*    htmlFor="release_date"*/}
                  {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"*/}
                  {/*  >*/}
                  {/*    Date de sortie*/}
                  {/*  </label>*/}
                  {/*  <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
                  {/*    <input*/}
                  {/*      {...register("release_date")}*/}
                  {/*      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"*/}
                  {/*      autoComplete="name"*/}
                  {/*      type="date"*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Description
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        {...register("description", { required: false })}
                        id="description"
                        name="description"
                        rows={5}
                        className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                        defaultValue={""}
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Description succinte du produit.
                      </p>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Photo de couverture
                    </label>
                    <CoverPictureManager
                      coverPicture={coverPicture}
                      setCoverPicture={setCoverPicture}
                      coverPictureBlob={coverPictureBlob}
                      setCoverPictureBlob={setCoverPictureBlob}
                      setPreviewURL={setPreviewURL}
                      previewURL={previewURL}
                      setIsLoaded={setIsLoaded}
                      // thumb_type={watch("thumb_type")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Réalisations
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <SelectRealisations
                  selectedOptions={selectedRealisations}
                  setSelectedOptions={setSelectedRealisations}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Références
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <SelectReferences
                  selectedOptions={selectedReferences}
                  setSelectedOptions={setSelectedReferences}
                />
              </div>
            </div>

            <div className="py-5 flex flex-row justify-between">
              <div>
                <span
                  onClick={handleDelete}
                  className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Supprimer le produit
                </span>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={goBack}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Annuler
                </button>
                <button
                  disabled={!isLoaded}
                  type="submit"
                  className={classNames(
                    !isLoaded
                      ? "bg-kakhi-400"
                      : "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                    "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
                  )}
                >
                  {isLoaded
                    ? isEditMode
                      ? "Sauvegarder les modifications"
                      : "Créer une nouvelle sous catégorie"
                    : "Chargememt de la photo en cours"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={"flex flex-col w-1/2"}>
        <div className={"sticky top-0 p-4 m-4 bg-beige-400 rounded"}>
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la présentation
          </h3>
          <div className={"grid grid-cols-2"}>
            <ReferenceItem
              title={watch("name")}
              text={watch("description")}
              img={previewURL.length > 0 ? previewURL : coverPicture}
              href={""}
            />

            {/* <div className={"grid grid-cols-2"}>
              <div
                className="bg-red-100 aspect-1 h-full w-full bg-center bg-cover"
                style={{
                  backgroundImage: `url('${previewURL.length > 0 ? previewURL : coverPicture
                    }')`,
                }}
              ></div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUniversesAddEditPage;
