import React, { useEffect, useState } from "react";
import GenericLayout from "../layouts/GenericLayout";
import { useParams } from "react-router-dom";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";
import SimilarProductsSlider from "../components/SimilarProductsSlider";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import ReactPlayer from "react-player";
import "../../packs/styles/productPage.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { uniqueId } from "lodash";
import FamilyProductsSlider from "../components/FamilyProductsSlider";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { formatPrice } from "../_helpers/price";
import ModalShowImage from "../components/modal-show-image/ModalShowImage";
import Api, { API_V1_BASE_URL } from "../_helpers/api";

const ProductPage = () => {
  const { product_slug } = useParams();
  const isMobile = useMediaQuery(640);

  const [openModal, setOpenModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const [product, setProduct] = useState(null);

  useEffect(() => {
    Api.get(`${API_V1_BASE_URL}/products/${product_slug}`)
      .then((res) => {
        setProduct(res.data);
      })
      // error handling
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  if (!product) return null;

  const {
    name,
    price,
    reference,
    structure,
    properties,
    width,
    height,
    depth,
    more_details,
    cover_picture_url,
    pictures_data,
    doc_pdf_data_list,
    video_url,
    file_sketchup_data,
    weight,
    family_id,
  } = product.data.attributes;
  const materials = getAllIncludedResourcesForAGivenType(product, "material");
  const categories = getAllIncludedResourcesForAGivenType(product, "category");

  const handleClickImage = (url) => (e) => {
    e.preventDefault();
    setOpenModal(true);
    setCurrentImage(url);
  };

  if (product.included[0])
    return (
      <GenericLayout title={`${reference} | Adm mobilier`} noHeader>
        {!isMobile && (
          <ModalShowImage
            currentImage={currentImage}
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}
        <div className="text-center uppercase sm:text-72 text-24 mt-8 sm:mb-20 mb-10 font-freight stroke-marron tracking-wider">
          FICHE PRODUIT
        </div>
        <div className="xl:mx-48 sm:mx-12 mx-4">
          <nav className="hidden mb-8 sm:flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center">
              <li>
                <div className="flex items-center">
                  <a
                    href="/"
                    className="text-base xl:text-24 whitespace-nowrap font-medium text-kakhi hover:text-black font-freight"
                  >
                    ADM MOBILIER
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href="/mobiliers-accessoires"
                    className="text-base xl:text-24 whitespace-nowrap uppercase font-medium text-kakhi hover:text-black font-freight"
                    aria-current="page"
                  >
                    mobiliers et accessoires
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href="#"
                    className="text-base xl:text-24 whitespace-nowrap uppercase font-medium text-kakhi hover:text-black font-freight"
                    aria-current="page"
                  >
                    {reference}
                  </a>
                </div>
              </li>
            </ol>
          </nav>

          <div className="flex flex-col md:flex-row justify-between items-start">
            <div className="w-full md:w-1/2 block sm:mr-8">
              <div className="sm:sticky sm:top-32 sm:mt-0 mt-8 flex w-full lg:w-1/2">
                <div className="sm:space-y-10 space-y-2">
                  <div className="md:hidden block">
                    <h1 className="font-freight font-bold lg:text-40 text-24 text-kakhi capitalize">
                      {reference}
                    </h1>
                    {price && <p>à partir de {formatPrice(price)}</p>}
                  </div>
                  <img
                    src={cover_picture_url}
                    className="md:hidden block md:w-full mb-8 rounded image-produit"
                  />
                </div>
              </div>
              <div className="hidden md:flex flex-col">
                {video_url && (
                  <div className={"w-full aspect-w-16 aspect-h-9"}>
                    <ReactPlayer
                      url={video_url}
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                )}
                <a
                  onClick={handleClickImage(cover_picture_url)}
                  href="#"
                  className="block"
                >
                  <img
                    src={cover_picture_url}
                    className="hidden md:block md:w-full mb-8 rounded image-produit"
                  />
                </a>
                {pictures_data?.map(({ url }) => (
                  <a
                    onClick={handleClickImage(url)}
                    href="#"
                    key={url}
                    className="inline-block"
                  >
                    <img
                      src={url}
                      className="w-full mb-8 rounded image-produit"
                    />
                  </a>
                ))}
              </div>
            </div>
            {/* )} */}
            <div className="sm:sticky sm:top-32 sm:mt-0 mt-4 flex w-full lg:w-1/2">
              <div className="sm:space-y-10 space-y-2">
                <div className="hidden md:block">
                  <h1 className="font-freight font-bold lg:text-40 text-24 text-kakhi capitalize">
                    {reference}
                  </h1>
                </div>
                {price && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi">
                      Prix
                    </p>
                    <p className="font-grotesque text-kakhi sm:text-20 text-16">
                      à partir de {formatPrice(price)}
                    </p>
                  </div>
                )}
                {structure && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi">
                      Structure:{" "}
                    </p>
                    <p className="font-grotesque text-kakhi sm:text-20 text-16">
                      {structure}
                    </p>
                  </div>
                )}
                {properties && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi">
                      Caractéristiques:
                    </p>
                    <p
                      className={"font-grotesque text-kakhi sm:text-20 text-16"}
                    >
                      {properties}
                    </p>
                  </div>
                )}
                {(width || height || depth) && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi">
                      Dimensions
                    </p>
                    <p className="font-grotesque text-kakhi sm:text-20 text-16">
                      {width || "n/a"} / {height || "n/a"} / {depth || "n/a"} mm
                    </p>
                  </div>
                )}
                {weight && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi">
                      Poids
                    </p>
                    <p className="font-grotesque text-kakhi sm:text-20 text-16">
                      {weight}&nbsp;g
                    </p>
                  </div>
                )}
                {categories && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi">
                      Categories
                    </p>
                    <p className="font-grotesque text-kakhi sm:text-20 text-16 space-x-2">
                      {categories.map(({ attributes }) => (
                        <a
                          key={attributes.id}
                          href={`/category/${attributes.slug}`}
                        >
                          <span className={"px-2 text-kakhi-400"}>
                            #{attributes.name}
                          </span>
                        </a>
                      ))}
                    </p>
                  </div>
                )}
                {(doc_pdf_data_list || file_sketchup_data) && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-lg text-kakhi ">
                      Documents:
                    </p>
                    <div className={"flex flex-col space-y-4"}>
                      {doc_pdf_data_list &&
                        doc_pdf_data_list.map((file) => {
                          return (
                            <div
                              key={uniqueId("file_pdf_")}
                              className={
                                "flex font-grotesque text-kakhi sm:text-lg text-16 w-full bg-beige-500 rounded px-2 "
                              }
                            >
                              <a
                                href={file.url}
                                target="_blank"
                                rel="noreferrer"
                                className={" hover:underline inline-flex  "}
                              >
                                <ArrowDownTrayIcon className={"w-7 h-7"} />{" "}
                                {file.file_name}
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                {materials.length !== 0 && (
                  <div>
                    <p className="font-freight font-bold lg:text-32 text-24 text-kakhi">
                      Matières
                    </p>
                    <ul className="list-disc pl-6 font-grotesque text-kakhi sm:text-20 text-16">
                      {materials.map((material, index) => {
                        return (
                          <li
                            key={`material__${index}`}
                            className={"capitalize"}
                          >
                            {material?.attributes.name}&nbsp;
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {more_details && more_details.trim().length !== 0 && (
                  <div>
                    <p className="font-freight font-bold lg:text-40 text-24 text-kakhi">
                      Informations complémentaires
                    </p>
                    <p className="font-grotesque text-kakhi sm:text-20 text-16">
                      {more_details}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="md:hidden flex flex-col mt-4 md:mt-0">
              {video_url && (
                <div className={"w-full aspect-w-16 aspect-h-9"}>
                  <ReactPlayer url={video_url} width={"100%"} height={"100%"} />
                </div>
              )}
              <a
                onClick={handleClickImage(cover_picture_url)}
                href="#"
                className="block"
              >
                <img
                  src={cover_picture_url}
                  className="hidden md:block md:w-full mb-8 rounded image-produit"
                />
              </a>
              {pictures_data?.map(({ url }) => (
                <a
                  onClick={handleClickImage(url)}
                  href="#"
                  key={url}
                  className="inline-block"
                >
                  <img
                    src={url}
                    className="w-full mb-8 rounded image-produit"
                  />
                </a>
              ))}
            </div>
          </div>

          <hr className="sm:hidden flex border-black border-1 mx-16 my-12" />
          {!family_id && <SimilarProductsSlider categories={categories} />}
          {family_id && <FamilyProductsSlider familyId={family_id} />}
        </div>
      </GenericLayout>
    );
};

export default ProductPage;
