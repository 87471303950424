import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import ValdeliaPicture from "../../../assets/images/valdelia.jpg";
import EcoResponsabilitePicture from "../../../assets/images/eco-responsabilite.jpg";
import EcoResponsabiliteElecPicture from "../../../assets/images/eco-responsabilite-elec.jpg";
import HeroSection from "../components/HeroSection";

const article = {
  title: "",
  body: `
    <div> Notre secteur d’activité est fortement concerné par les problématiques environnementales :
    <ul class="list-disc pl-4">
    <li>Collecte et recyclage des meubles usagés</li>
    <li>Matières premières utilisées</li>
    <li>Composants chimiques</li>
    <li>Gestion et recyclage des emballages et déchets</li>
    <li>Transports</li>
    <li>Nous nous voulons acteur et vecteur de bonnes pratiques. </li></ul>
    <p class="my-3">ADM Mobilier est membre de l’éco-organisme Valdelia, et collecte puis reverse les éco-contributions sur chaque meuble vendu. Ces contributions servent à financer le recyclage des anciens meubles, ou leur don à des organismes sociaux qui les réutiliseront, mais aussi à faciliter et rendre gratuite, ou quasiment, la collecte des anciens meubles de nos clients, avant leur recyclage.</p><p>Naturellement, nous sommes très vigilants sur les certifications des usines qui produisent pour nous. Que les bois qu’elles utilisent aient la certification FSC est un standard minimal, mais le bois n’est pas la seule matière concernée. Notre attention est particulièrement forte sur les matières plastiques utilisées, et nous privilégions et mettons en avant les meubles « Green » produits à partir de produits recyclés, ou étant eux-mêmes totalement recyclables.
</p>
    </div>
  `,
  imageURL: ValdeliaPicture,
  link: null,
};

const article2 = {
  title: "",
  body: `
  <div>
  <p class="my-2">Nous prenons le même soin en ce qui concerne les solvants, les colles, les vernis. Nous bannissons de nos collections tous les articles théoriquement interdits en Union Européenne mais qui sont malheureusement encore utilisés dans d’autres pays. Pour exemples, nous veillons à ce que les vernis appliqués sur les bois, comme les colles utilisées pour les assemblages des bois ou des mousses, soient à base aqueuse. Ou encore : nos revêtements de sièges sont certifiés sans phtalate et sans solvant, outre qu’ils aient également pour la plupart une garantie anti-microbes.
  </p>
  <p class="my-2">En ce qui concerne les déchets, nous remportons nous-mêmes 95% des emballages des meubles que nous livrons. Les plastiques sont ensuite apportés par nos soins dans un centre de tri spécialisé, et chaque semaine nous déposons des centaines de cartons d’emballage dans un centre de recyclage.
  </p>
  <p class="my-2">Nous travaillons aussi en permanence avec nos fabricants pour limiter le gaspillage d’emballages et minimiser les volumes transportés (mais aussi les poids à manipuler pour la pénibilité). Très souvent des optimisations sont possibles. Tout le monde est gagnant, et pas seulement financièrement !
  </p>
  </div> `,
  imageURL: EcoResponsabilitePicture,
};

const article3 = {
  title: "",
  body: `  
  <p class="my-2">En matière de transport, nos meubles ou pièces détachées sont transportés par ferroutage à chaque fois que c’est possible. Par exemple, nos importations d’Italie sont chargées dans des camions qui font la plus grande partie de leur trajet (frontière italienne – frontière belge) par voie ferroviaire.
  </p>
  <p class="my-2">Nos véhicules de livraison, régulièrement renouvelés, sont adaptés aux exigences actuelles de limitation de la pollution, et répondent aux critères des zones ZFE. Impatients que l’industrie automobile nous le permette, nous étudions déjà le passage de notre flotte de livraison au tout électrique. Dans un même esprit, la plupart de nos véhicules commerciaux sont déjà électrifiés.
  </p>
  <p class="my-2">Parler d’éco-responsabilité, c’est bien, et c’est utile. La pratiquer au quotidien, c’est encore mieux !</p>`,
  imageURL: EcoResponsabiliteElecPicture,
};
const presentationBreadCrumbs = [
  { label: "Accueil", url: "/" },
  { label: "Services" },
  { label: "Éco-responsabilité", url: "/services/eco-responsabilite" },
];

const EcoResponsabilite = () => {
  return (
    <GenericLayout
      title={"Nos Services | Eco-responsabilité | Adm mobilier"}
      h1="Eco-responsabilité"
      breadCrumbs={presentationBreadCrumbs}
    >
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection
            article={article}
            index={0}
            isH2
            breakpointFixBreadcrumb={1726}
            fixBreadcrumb
          />
          <div className="bg-skin-300 py-24 sm:py-32 px-[20px] grid grid-cols-12">
            <div className="mx-auto col-start-2 col-end-12">
              <div className="mx-auto  lg:mx-0">
                <h2 className="text-4xl font-bold font-playfair text-gray-900 sm:text-4xl">
                  « Nous n’héritons pas de la terre de nos ancêtres, nous
                  l’empruntons à nos enfants »
                </h2>
              </div>
            </div>
          </div>

          <HeroSection
            article={article2}
            index={1}
            isH2
            breakpointFixBreadcrumb={1726}
            fixBreadcrumb
          />
          <HeroSection
            article={article3}
            index={2}
            isH2
            breakpointFixBreadcrumb={1726}
            fixBreadcrumb
          />
          {/* <div className="flex justify-center">
            <div
              className="imganim aspect-w-2 aspect-h-1 bg-cover bg-center rounded"
              style={{
                backgroundImage: `url(${showroomWide})`,
              }}
            />
          </div> */}
        </div>
      </div>
    </GenericLayout>
  );
};

export default EcoResponsabilite;
