import React from "react";
import { useInspirations } from "../_services/inspiration-service";
import InspirationItem from "../components/inspirations/InspirationItem";
import Error from "../components/Error";
import Loading from "../components/utils/Loading";

const InspirationItems = () => {
  const { inspirations, isError, isLoading } = useInspirations();

  if (isError) {
    return <Error error={isError} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-4">
      <div className="mt-2 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
        {inspirations.data.map((inspiration, index) => {
          return (
            <InspirationItem
              key={`universe__${index}`}
              inspiration={inspiration}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InspirationItems;
