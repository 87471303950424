import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Error from "../Error";
import Loading from "../utils/Loading";
import { useMaterialsOptions } from "../../_services/material-services";
import { useFamily, useFamilyOptions } from "../../_services/family-services";
import { filter } from "lodash";

const SelectProducts = ({ selectedOption, setSelectedOption }) => {
  const { options, isError, isLoading } = useFamilyOptions();

  const selectRef = useRef(null);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error error={isError} />;
  }

  return (
    <div>
      <Select
        ref={selectRef}
        onChange={setSelectedOption}
        options={options.data}
        isLoading={isLoading}
        getOptionLabel={(option) => `${option.attributes.name}`}
        getOptionValue={(option) => `${option.attributes.id}`}
        value={selectedOption}
      />
    </div>
  );
};

export default SelectProducts;
