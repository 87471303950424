import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../../redux/slices/products";
import AdminSearchBar from "../../../components/molecules/search/AdminSearchBar";
import { DateTime } from "luxon";
const AdminProductsPage = () => {
  const { products, nbPage, loading } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  const handleChangePage = (page) => {
    setCurrentPage(page);
    dispatch(getProducts());
    window.scroll({ top: 0 });
  };

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>Produits</h3>
        <div className="flex flex-row items-center flex-grow justify-end">
          <div className="w-1/2">
            <AdminSearchBar />
          </div>
          <Link
            to={"/admin/products/new"}
            type="button"
            className="inline-flex items-center px-4 py-2 border-transparent shadow-sm text-sm font-medium rounded text-kakhi-700 border border-kakhi-500 hover:text-white bg-transparent hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
          >
            <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Ajouter un produit
          </Link>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Reference
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    sous-catégories
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Dernière mise à jour
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.map(({ attributes }, personIdx) => (
                    <tr
                      key={attributes.id}
                      className={
                        personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {attributes.product_reference}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {attributes.category_names}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {DateTime.fromISO(attributes.updated_at)
                          .setLocale("fr")
                          .toRelative()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/admin/products/${attributes.id}`}
                          className="text-kakhi-600 hover:text-kakhi-900"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {loading && (
            <div className="flex justify-center mt-12 mb-12 text-xl font-brandon">
              Chargement en cours...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminProductsPage;
