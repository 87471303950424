import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import Hero from "../components/hero/Hero";
import classNames from "classnames";
import ProductCard from "../components/products/ProductCard";
import { ImageInLeft } from "./Homepage";
import BLockContactV2 from "../components/block-contact-v2/BlockContactV2";

import heroImage from "../../../assets/images/selection-terrasses/hero.jpg";
// first block
import image1_1 from "../../../assets/images/selection-terrasses/image_1_1.jpg";
import image1_2 from "../../../assets/images/selection-terrasses/image_1_2.jpg";
import image1_3 from "../../../assets/images/selection-terrasses/image_1_3.jpg";

// seconde block
import image2_1 from "../../../assets/images/selection-terrasses/image_2_1.jpg";

// third block
import image3_1 from "../../../assets/images/selection-terrasses/image_3_1.jpg";

// block contact
import contact from "../../../assets/images/selection-terrasses/contact.jpg";

import MyButton from "../components/my-button/MyButton";

const testimonials = [
  {
    id: 1,
    name: "Greg Letienne",
    title: /* HTML */ `<span>LA CONNIVENCE</span> Lille /
      <span>LE SOYEUX</span> Lille / <span>LE BISTROT LILLOIS</span> Lille`,
    text: /* HTML */ `<p>
      Très satisfait de la société ADM depuis des années, mobilier intérieur et
      extérieur de qualité, toujours à l’écoute de nos besoins mêmes très
      spécifiques, ce sont des pros !
    </p>`,
  },
  {
    id: 2,
    name: "Fabien COTTEL",
    title: /* HTML */ `<span>CHEZ AMANDINE</span> /
      <span>LE VILLAGE DE L’AUTHIE</span> Auxi-Le-Château /
      <span>L’ATMOSPHERE</span> Doullens`,
    text: /* HTML */ `<p>
      Je travaille avec ADM depuis 5 ans, et 5 établissements plus tard, je suis
      toujours aussi satisfait de leurs services, le mobilier est top et ils
      sont réactifs, je travaillerai avec eux pour mes prochains établissements
      !
    </p>`,
  },
  {
    id: 3,
    name: "Damien HAREN",
    title: /* HTML */ `<span>THE FRIDGE</span> Lille /
      <span>LE POP</span> Lille`,
    text: /* HTML */ `<p>
      Je me fournis auprès d’ADM depuis 2011, et je continue ! Ils sont
      compétents, rigoureux et soucieux de leurs clients, une très belle
      collaboration depuis tant d’années.
    </p>`,
  },
  {
    id: 4,
    name: "Nicolas BONDUEL",
    title: "<span>TAO BENTO</span> Roncq",
    text: /* HTML */ `<p>
      Vraiment content d’avoir choisi ADM ! Conseils de pros, énorme choix, et
      super réactivité ! Cerise sur le gâteau : délais parfaitement respectés !
      Le mobilier est confortable et solide à l’intérieur comme en terrasse et
      nos clients adorent le décor !
    </p>`,
  },
  {
    id: 5,
    name: "Julien DELMARLE",
    title: "<span>LE CAPRICCIO</span> Châteuneuf-Grasse",
    text: /* HTML */ `<p>
      Nous avons fait appel à ADM pour meubler nos salles de restaurant. 5 ans
      après, nous leur avons commandé nos 200 places de terrasse sans hésitation
      ! Des experts, et une fiabilité comme on en voit rarement.
    </p>`,
  },
];

export const ProductGridV2 = ({ className, products = [], cta }) => {
  return (
    <>
      <div
        className={classNames(
          className,
          "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-8"
        )}
      >
        {!!products?.length &&
          products.map(({ attributes }) => {
            const {
              name,
              id,
              price,
              thumb_type,
              cover_picture_url,
              reference,
              slug,
            } = attributes;
            return (
              <ProductCard
                isV2
                key={`product_${id}`}
                name={name}
                id={id}
                thumb_type={thumb_type}
                cover_picture_url={cover_picture_url}
                reference={reference}
                slug={slug}
                price={price}
              />
            );
          })}
      </div>
      {cta && (
        <div className="mt-[32px] flex justify-center">
          <MyButton label={cta.label} href={cta.href} />
        </div>
      )}
    </>
  );
};

const SelectionTerrasses = () => {
  const products = [
    {
      attributes: {
        id: 1,
        price: null,
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731346/Remind_ymbcym.jpg",
        reference: "Remind",
        thumb_type: "regular",
        slug: "85863c08-8912-49c9-a93d-788b12e8045b",
      },
    },
    {
      attributes: {
        id: 2,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731340/Nolita_fgvvu4.jpg",
        reference: "Nolita",
        slug: "0a4a91a0-9e08-43ca-9df1-e67e88b92e22",
      },
    },
    {
      attributes: {
        id: 3,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731345/Concrete_fubpih.jpg",
        reference: "Concrete",
        slug: "7dbdae36-7ae1-46b3-97a1-60d41eca908d",
      },
    },
    {
      attributes: {
        id: 4,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731344/Dome_nlax6t.jpg",
        reference: "Dome",
        slug: "75c3832e-1691-432d-847d-0d5e41d57c08",
      },
    },
    {
      attributes: {
        id: 5,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1712087291/Lisa_Lounge_bzueew.jpg",
        reference: "Lisa Lounge",
        slug: "70949403-3981-48b9-91b7-e70702020d3a",
      },
    },
    {
      attributes: {
        id: 6,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731342/Net_Lounge_j6bv7h.jpg",
        reference: "Net Lounge",
        slug: "946ef873-221b-4293-9eb1-280d62f25e76",
      },
    },
    {
      attributes: {
        id: 7,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731341/Paris_9_lvhdfp.jpg",
        reference: "Paris 9",
        slug: "c18c2a5c-e72e-477a-9c50-0e98a0c7c60c",
      },
    },
    {
      attributes: {
        id: 8,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731339/Panarea_3675_blmvuw.jpg",
        reference: "Panarea 3675",
        slug: "7d0db46d-0989-4405-992f-1e77b0ed1666",
      },
    },
    {
      attributes: {
        id: 9,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731337/Tornado_wodepw.jpg",
        reference: "Tornado",
        slug: "6ff5761c-03d7-458b-a6a6-8275a5820e16",
      },
    },
    {
      attributes: {
        id: 10,
        price: null,
        thumb_type: "large",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731338/Nolita_Sofa_twiijz.jpg",
        reference: "Nolita Sofa",
        slug: "cf8df820-5b56-470a-9e25-210f578d1a42",
      },
    },
    {
      attributes: {
        id: 11,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731335/Bistro_s7drlf.jpg",
        reference: "Bistro",
        slug: "2fb52167-733b-402d-a4ed-22b16875af74",
      },
    },
    {
      attributes: {
        id: 12,
        price: null,
        thumb_type: "large",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731336/Komodo_oswefy.jpg",
        reference: "Komodo",
        slug: "0593f340-538e-4e34-83d1-ccc45e300189",
      },
    },
    {
      attributes: {
        id: 13,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731334/Hug_vgd3qv.jpg",
        reference: "Hug",
        slug: "a1f32100-12df-48bb-892b-f88db36f39ce",
      },
    },
    {
      attributes: {
        id: 14,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1712087292/Lutetia_rni1ak.jpg",
        reference: "Lutetia",
        slug: "a1f32100-12df-48bb-892b-f88db36f39ce",
      },
    },
    {
      attributes: {
        id: 15,
        price: null,
        thumb_type: "large",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731333/Arki_Sofa_h2j8bj.jpg",
        reference: "Arkis Sofa",
        slug: "178e3f2e-bde8-44c0-88a4-5c7ef5575f5f",
      },
    },
    {
      attributes: {
        id: 16,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731332/Si-si_Wood_a5jory.jpg",
        reference: "Si-si Wood",
        slug: "18236d62-50e3-46bf-974f-5707680ff72c",
      },
    },
    {
      attributes: {
        id: 17,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1712086861/Lutetia_Relax_l5msfo.jpg",
        reference: "Lutetia Relax",
        slug: "d284df2d-962f-42ee-9c24-2a11d60ddc29",
      },
    },
    {
      attributes: {
        id: 18,
        price: null,
        thumb_type: "large",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731330/RevaTwist_jiykfx.jpg",
        reference: "Reva Twist",
        slug: "11f29e64-b497-420b-9618-b854ab074959",
      },
    },
    {
      attributes: {
        id: 19,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731329/Tripe_dqy3mb.jpg",
        reference: "Tripe",
        slug: "b0d0e1a8-b389-4842-91b0-dd32d8900094",
      },
    },
    {
      attributes: {
        id: 20,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731328/Nolita_T_fivlw5.jpg",
        reference: "Nolita T",
        slug: "a1f32100-12df-48bb-892b-f88db36f39ce",
      },
    },
    {
      attributes: {
        id: 21,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731327/Ypsilon_T_rypsc9.jpg",
        reference: "Ypsilon T",
        slug: "153c73c3-44c4-4324-b17d-057bc20c8913",
      },
    },
    {
      attributes: {
        id: 22,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731326/Dress_Code_fzrhlx.jpg",
        reference: "Dress Code",
        slug: "9b789da5-fa9f-4596-8152-e4c67b55b876",
      },
    },
    {
      attributes: {
        id: 23,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731325/Guinea_xbzhqx.jpg",
        reference: "Guinea",
        slug: "ad652120-55f6-42c4-a794-7b4f33b6c819",
      },
    },
    {
      attributes: {
        id: 24,
        price: null,
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711731324/Lisa_Filo_febmz2.jpg",
        reference: "Lisa Filo",
        slug: "8d69cfe3-29b3-45a7-9b09-6f922ce1fee4",
      },
    },
  ];

  // useEffect(() => {
  //   const getFunc = async () => {
  //     const results = await getProductsByTag("Sélection terrasse 2024");
  //     setProducts(results);
  //   };
  //   getFunc();
  // }, []);

  return (
    <GenericLayout title="Adm Mobilier : Sélection terrasse 2024." noHeader>
      <Hero
        className="mt-[48px] mx-[20px]"
        titleH1={
          /* HTML */ `Sélection <br class="hidden md:inline-block" />
            terrasse 2024`
        }
        titleH2="Des ambiances uniques pour vos terrasses avec ADM Mobilier"
        texts={
          /* HTML */ ` <p>
              Transformez vos espaces extérieurs en lieux d'exception, où chaque
              mobilier est conçu pour ravir vos clients et créer des ambiances
              uniques.
            </p>
            <p>
              Découvrez notre gamme exclusive de
              <span>terrasses sur mesure</span>, alliant
              <span>qualité, durabilité</span> et <span>style</span>.
            </p>`
        }
        heroImage={heroImage}
        // cta={{
        //   label: "Découvrez nos créations",
        //   href: "/nos-inspirations",
        // }}
      />
      <div className="mx-[20px] grid grid-cols-12 mt-[80px] md:mt-[120px]">
        <h2 className="my-h2 col-span-full">
          Vos idées, votre ambiance, notre expertise
        </h2>
        <div className="paragraph col-span-full xl:col-span-8 mt-6">
          <p>
            Chez ADM, nous mettons en œuvre vos idées pour créer une{" "}
            <span>ambiance unique</span> pour votre terrasse. Notre expertise en
            design et fabrication garantit une réalisation professionnelle qui
            reflète parfaitement vos exigences.
          </p>
          <p className="!mt-[8px]">
            Faites confiance à notre équipe pour{" "}
            <span>donner vie à vos extérieurs</span>, renforçant ainsi
            l&apos;attrait de votre établissement.
          </p>
        </div>
        <div className="mt-6 grid-cols-1 md:grid-cols-3 grid gap-5 col-span-full">
          <img
            src={image1_1}
            className="w-full h-auto rounded-md"
            alt="image_1"
          />
          <img
            src={image1_2}
            className="w-full h-auto rounded-md"
            alt="image_2"
          />
          <img
            src={image1_3}
            className="w-full h-auto rounded-md"
            alt="image_3"
          />
        </div>
      </div>
      <div className="mt-[80px] md:mt-[120px] px-[20px]">
        <h2 className="my-h2">
          ADM vous présente sa sélection de produits pour votre terrasse
        </h2>
        {products && (
          <ProductGridV2
            className="mt-6"
            products={products}
            cta={{
              label: "Voir tous les produits",
              href: "/mobiliers-accessoires",
            }}
          />
        )}
      </div>
      <div className="mx-[20px] grid grid-cols-12 mt-[80px] md:mt-[120px]">
        <h2 className="my-h2 col-span-full">
          Créez une terrasse sur-mesure : votre espace, votre style
        </h2>
        <div className="paragraph col-span-full xl:col-span-8 mt-6">
          <p>
            Chez ADM, votre vision devient réalité. Nous mettons à votre
            disposition notre expertise pour concevoir une terrasse entièrement
            personnalisée, <span>répondant à chacune de vos exigences</span>. De
            la sélection des matériaux au choix du design et de la
            configuration, chaque détail est pensé pour refléter l&apos;identité
            unique de votre entreprise.
          </p>
          <p className="!mt-[8px]">
            Faites de votre terrasse un <span>espace à votre image</span>, où
            chaque élément contribue à créer une ambiance distinctive et
            accueillante.
          </p>
        </div>
        <div className="mt-6 col-span-full">
          <img
            src={image2_1}
            style={{ objectPosition: "50% 70%" }}
            className="w-full h-[200px] lg:h-[300px] xl:h-[400px] 2xl:h-[500px] rounded-xl object-cover "
            alt="image_2_1"
          />
        </div>
      </div>
      <ImageInLeft
        classNameImage="w-full h-[300px] md:h-[572px] object-cover object-center"
        image={image3_1}
        className="mt-[64px] md:mt-[120px] items-center"
      >
        <div className="w-full space-y-[64px] mt-[48px] md:mt-0">
          <div>
            <h2 className="my-h2">
              Votre entreprise, notre priorité : des solutions personnalisées
              qui font toute la différence
            </h2>
            <div className="paragraph space-y-2 2xl:space-y-4 mt-6 2xl:mt-8">
              <p>
                Chez ADM, chaque terrasse est conçue pour refléter l&apos;
                <span>identité unique</span> de nos clients.
              </p>
              <p>
                De la consultation initiale à la réalisation finale, notre
                équipe s&apos;engage à apporter une valeur{" "}
                <span>ajoutée à chaque projet</span>.
              </p>
              <p>
                Nos terrasses se démarquent par leur qualité exceptionnelle.
                Nous portons une attention particulière aux détails afin de
                créer des <span>espaces extérieurs uniques et mémorables</span>.
              </p>
            </div>
          </div>
          <div>
            <h2 className="my-h2">
              Terrasses d&apos;exception pour votre établissement de standing
            </h2>
            <div className="paragraph space-y-2 2xl:space-y-4 mt-6 2xl:mt-8">
              <p>
                La qualité et la durabilité sont au cœur de tout ce que nous
                faisons. Chacun de nos produits est fabriqué à partir de{" "}
                <span>matériaux de haute qualité</span>, soigneusement
                sélectionnés pour leur <span>résistance</span> et leur{" "}
                <span>esthétique</span>.
              </p>
              <p>
                Nous croyons fermement en la création de terrasses qui répondent
                à vos exigences, offrant ainsi à votre établissement la{" "}
                <span>tranquillité d&apos;esprit</span> et une{" "}
                <span>satisfaction sur le long terme</span>.
              </p>
            </div>
          </div>
        </div>
      </ImageInLeft>
      <div className="py-[64px] md:py-[120px] mx-[20px] grid-cols-12 grid">
        <div className="col-span-full xl:col-start-2 xl:col-end-12 grid grid-cols-10">
          <h2 className="my-h2 col-span-full">L&apos;écho de nos clients</h2>
          <div className="paragraph col-span-full xl:col-span-5 mt-6">
            <p>
              Leur confiance en notre expertise et leur satisfaction envers nos
              produits et services sont le reflet d’un engagement
              d&apos;excellence et de qualité.
            </p>
          </div>
          <div className="col-span-full grid grid-cols-10">
            {testimonials.map((item, index) => {
              const isOdd = (index + 1) % 2;

              return (
                <div
                  key={index}
                  className={classNames("mt-[48px]", {
                    "col-span-full md:col-start-7 md:col-end-11": !isOdd,
                    "col-span-full md:col-start-1 md:col-end-5": isOdd,
                  })}
                >
                  <div className="font-playfair font-medium text-[14] lg:text-[16px]">
                    <p>{item.name}</p>
                    <h3
                      className="testimony-title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    ></h3>
                  </div>
                  <div
                    className="paragraph mt-4"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <BLockContactV2
        contactImage={contact}
        titleH2="Contactez-nous pour concrétiser votre projet de terrasse sur mesure"
        texts={
          /* HTML */ `
            <p class="!text-white">
              Que vous ayez des questions, besoin de conseils ou que vous
              souhaitiez commencer votre projet de terrasse sur mesure, notre
              équipe est là pour vous aider.
            </p>
          `
        }
      />
    </GenericLayout>
  );
};

export default SelectionTerrasses;
