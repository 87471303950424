import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { searchProductsSlice } from "./products";
import Api from "../../_helpers/api";
import { fetchFacetsData } from "../../_helpers/alogolia-helper";
import { indexName } from "../../../packs";

const initialState = {
  navbarFacets: null,
  filters: [],
  category_id: null,
  materials: [],
  errors: null,
  value: {
    filters: [],
    materials: [],
    dimensions: [],
  },
  default_value_dimensions: [],
  dimensionsMinMax: null,
  tags_name_list: [],
  selected_categories_ids: [],
  selected_material_ids: [],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    set_navbar_facets(state, action) {
      state.navbarFacets = action.payload;
    },
    set_filters(state, action) {
      state.filters = action.payload;
    },
    set_errors(state, action) {
      state.errors = action.payload;
    },
    set_materials(state, action) {
      state.materials = action.payload;
    },
    set_dimensions_min_max(state, action) {
      state.dimensionsMinMax = action.payload;
    },
    set_value(state, action) {
      state.value = action.payload;
    },
    set_default_value_dimensions(state, action) {
      state.default_value_dimensions = action.payload;
    },
    // ADD SET_TAGS_LIST SLICE
    set_tags_name_list(state, action) {
      state.tags_name_list = action.payload;
    },
    set_selected_categories_ids(state, action) {
      state.selected_categories_ids = action.payload;
    },
    set_selected_material_ids(state, action) {
      state.selected_material_ids = action.payload;
    },
  },
});

export const resetFilter = createAsyncThunk(
  "reset",
  async (_, { dispatch }) => {
    dispatch(
      filterSlice.actions.set_value({
        filters: [],
        materials: [],
        dimensions: [],
      })
    );
  }
);

export const getFacets = createAsyncThunk(
  "getFacets",
  async (_, { dispatch, getState }) => {
    const { navbarFacets } = getState().filters;
    if (!navbarFacets) {
      fetchFacetsData(["categories.group"]).then(async (groupFacets) => {
        const data = await Promise.all(
          Object.entries(groupFacets && groupFacets["categories.group"]).map(
            async ([key]) => {
              const categoryNames = await fetchFacetsData(
                ["categories.name"],
                indexName,
                [`categories.group:${key}`]
              );
              return { key, categoryNames: categoryNames["categories.name"] };
            }
          )
        );
        dispatch(filterSlice.actions.set_navbar_facets(data));
      });
    }
  }
);

export const getFiltersForProductsPage = createAsyncThunk(
  "get_filters_by_category",
  async (_, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(`/api/v1/filters`);
    dispatch(filterSlice.actions.set_filters(data));
  }
);

export const getFilterByCategory = createAsyncThunk(
  "get_filter_by_category",
  async (category_id, { dispatch }) => {
    const {
      data: { data },
      errors,
    } = await Api.get(`/api/v1/category/${category_id}/filters`);
    if (errors) {
      dispatch(filterSlice.actions.set_errors(errors));
    } else {
      dispatch(filterSlice.actions.set_filters(data));
    }
    return data;
  }
);

export const getFilterByCategories = createAsyncThunk(
  "getFilterByCategories",
  async (categories_ids, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await Api.post(`/api/v1/categories/filters`, {
        categories_ids,
      });
      dispatch(filterSlice.actions.set_filters(data));
    } catch (e) {
      console.log(e);
    }
  }
);

// /group/:group_id/filters
export const getFilterByGroup = createAsyncThunk(
  "get_filter_by_group",
  async (group_id, { dispatch }) => {
    const {
      data: { data },
      errors,
    } = await Api.get(`/api/v1/group/${group_id}/filters`);
    if (errors) {
      dispatch(filterSlice.actions.set_errors(errors));
    } else {
      dispatch(filterSlice.actions.set_filters(data));
    }
    return data;
  }
);

export const getMaterials = createAsyncThunk(
  "get_materials",
  async (_, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(`/api/v1/materials`);

    dispatch(
      filterSlice.actions.set_materials(data.map((item) => item.attributes))
    );
  }
);

export const getDimensionsMinMax = createAsyncThunk(
  "get_dimensions_min_max",
  async (category_id, { dispatch }) => {
    const { data } = await Api.get(`/api/v1/${category_id}/dimensions_min_max`);
    dispatch(filterSlice.actions.set_dimensions_min_max(data));
  }
);

// FILTERING PRODUCTS

export const getProductsByFilterIdList = createAsyncThunk(
  "get_product_by_filter_id",
  async ({ category_id_list, filter_id_list }, { dispatch }) => {
    const { data } = await Api.post(`/api/v1/categories/filters/products`, {
      category_id_list: category_id_list,
      filter_id_list: filter_id_list,
    });

    dispatch(searchProductsSlice.actions.SET_FILTERED_PRODUCTS(data));
  }
);

export const getProductsGroupFiltered = createAsyncThunk(
  "get_product_group_filtered",
  async (
    {
      group_enum_id,
      category_ids,
      tags_name_list,
      material_ids,
      paginationRequestedPageNumber = 0,
    },
    { dispatch }
  ) => {
    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
      },
    } = await Api.post(
      `/api/v1/groups/${group_enum_id}/products/tags?page=${
        paginationRequestedPageNumber + 1
      }`,
      {
        tags: tags_name_list,
        category_ids: category_ids,
        material_ids: material_ids,
      }
    );

    // dispatch(searchProductsSlice.actions.SET_FILTERED_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_IS_FILTERED_MODE(true));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
  }
);
export const getProductsFiltered = createAsyncThunk(
  "get_products_filtered",
  async (
    {
      category_ids,
      tags_name_list,
      material_ids,
      paginationRequestedPageNumber = 0,
    },
    { dispatch }
  ) => {
    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
        pagy,
      },
    } = await Api.post(
      `/api/v1/products/tags?page=${paginationRequestedPageNumber + 1}`,
      {
        tags: tags_name_list,
        category_ids: category_ids,
        material_ids: material_ids,
      }
    );

    console.log(pagy, rest);

    // dispatch(searchProductsSlice.actions.SET_FILTERED_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_IS_FILTERED_MODE(true));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
  }
);

export const getProductsByCategoryFiltered = createAsyncThunk(
  "get_products_by_category_filtered",
  async (
    {
      category_id,
      tags_name_list,
      material_ids,
      paginationRequestedPageNumber = 0,
    },
    { dispatch }
  ) => {
    dispatch(searchProductsSlice.actions.SET_LOADING(true));
    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
      },
    } = await Api.post(
      `/api/v1/categories/${category_id}/products/tags?page=${
        paginationRequestedPageNumber + 1
      }`,
      {
        tags: tags_name_list,
        material_ids: material_ids,
      }
    );
    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_IS_FILTERED_MODE(true));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
  }
);

export const getProductsByTag = async (tag, per_page = 20) => {
  try {
    const {
      data: {
        products: { data },
        // pagy: { pages, ...rest },
      },
    } = await Api.post(`/api/v1/products/tags?page=1&per_page=${per_page}`, {
      tags: [tag],
    });
    return data;
  } catch (e) {
    throw new Error(`Failed to fetch products by tag, tag: ${tag}`);
  }
};

export default filterSlice.reducer;
