import React from "react";
import { useSwiper } from "swiper/react";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";

const SlideNavigation = () => {
  const swiper = useSwiper();

  if (!swiper) return null;

  return (
    <div className="flex flex-row">
      <div className="w-16">
        <ChevronLeftIcon onClick={() => swiper.slidePrev()} />
      </div>
      <div className="w-16">
        <ChevronRightIcon onClick={() => swiper.slideNext()} />
      </div>
    </div>
  );
};

export default SlideNavigation;
