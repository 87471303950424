import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import entrepot from "../../../assets/images/services-atelier.jpg";
import entrepotHero from "../../../assets/images/emtrepot-0.jpg";
import entrepot2 from "../../../assets/images/atelier-service.jpg";
import HeroSection from "../components/HeroSection";

const Showroom = () => {
  const article = {
    title: "Entrepôt / Stockage / Atelier",
    body: `
      <p>Avec plus de 5 000 m3 de capacité, notre entrepôt nous permet de disposer d’un stock permanent. Nous pouvons ainsi livrer très rapidement, lors de la saison des terrasses par exemple. Cela nous permet aussi de raccourcir les délais des commandes contenant des composants standards. </p>
      <p>Grâce à ce grand volume, nous sommes en mesure de regrouper et stocker en sécurité la totalité des produits de votre commande au fur et à mesure de l’arrivée des marchandises de nos différentes usines, et nous pouvons assumer sereinement la place de stockage nécessaire aux quantités de chantiers importants (400 - 500 places), avant leur livraison.</p>
      <p>Certains de nos clients (chaînes de restaurants, ou réseaux de franchisés), ont des besoins récurrents ou des impératifs de réapprovisionnement rapide. Nous pouvons stocker en permanence plusieurs centaines de leurs modèles.</p>
      <p>Nous disposons enfin de nombreuses pièces détachées en cas de besoin, et notre atelier de préparation nous procure la possibilité de prémonter certains meubles, avant la livraison. Un gain de temps précieux pour nous comme pour nos clients !</p>
    `,
    imageURL: entrepotHero,
    link: "/references",
  };

  const presentationBreadCrumbs = [
    { label: "Accueil" },
    { label: "Services" },
    {
      label: "Entrepôt - Stockage - Atelier",
      url: "/services/entrepot-stockage-atelier",
    },
  ];

  return (
    <GenericLayout
      title={"Nos Services | Entrepôt - Stockage - Atelier | Adm mobilier"}
      h1="Entrepôt - Stockage - Atelier"
      breadCrumbs={presentationBreadCrumbs}
    >
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection
            article={article}
            index={0}
            isH2
            maxWidth={55}
            fixBreadcrumb
            breakpointFixBreadcrumb={1813}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
            <div>
              <img
                src={entrepot2}
                className="w-full h-full object-cover object-center rounded"
                alt={"Photo du stock de visserie"}
              />
            </div>
            <div>
              <img
                src={entrepot}
                className="w-full h-full object-cover object-center rounded"
                alt={"Photo entrepot"}
              />
            </div>
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default Showroom;
