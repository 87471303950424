import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { uniqueId } from "lodash";
import Api, { API_V1_BASE_URL } from "../_helpers/api";
import classNames from "classnames";
import { isLarge, isRegular } from "../pages/CategoryPage";
import "../../packs/styles/productPage.css";
import "swiper/css";
import "swiper/css/scrollbar";
import SlideNavigation from "./utils/Swiper";

const SimilarProductsSlider = ({ categories }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Api.get(
      `${API_V1_BASE_URL}/similar_products?category_ids[]=${_.join(
        categories.map((category) => category.id),
        "&category_ids[]="
      )}`
    ).then(({ data }) => {
      setProducts(data.data);
    });
  }, [categories]);

  if (products.length === 0) return null;

  return (
    <div>
      <h2 className="mt-12 sm:mb-0 font-freight sm:text-40 text-lg leading-none font-bold">
        Sélection d&apos;autres produits
      </h2>
      <p className="text-kakhi font-grotesque text-sm lg:text-20 mb-4 lg:mb-8">
        Découvrez d&apos;autres produits qui pourraient vous intéresser
      </p>
      <div className="w-full">
        <Swiper
          breakpoints={{
            240: {
              slidesPerView: "auto",
              spaceBetween: 16,
            },
            981: {
              slidesPerView: "auto",
              spaceBetween: 24,
            },
            1777: {
              slidesPerView: "auto",
              spaceBetween: 32,
            },
          }}
        >
          {products.length &&
            products.map(
              ({
                attributes: {
                  id,
                  cover_picture_url,
                  reference,
                  slug,
                  thumb_type,
                },
              }) => (
                <SwiperSlide
                  key={uniqueId(`${id}`)}
                  className={classNames(
                    { "w-24": isRegular(thumb_type) },
                    { "w-52": isLarge(thumb_type) },
                    "rounded sm:leading-normal leading-tight sm:m-0 mb-8 h-auto hover:underline w-fit"
                  )}
                  as={"a"}
                >
                  <a
                    href={`/mobiliers-accessoires/${slug}`}
                    className="image-similar-produit-container"
                  >
                    <img
                      className="rounded image-similar-produit h-52 md:h-52 lg:h-80"
                      src={cover_picture_url}
                      alt={reference}
                    />
                    <p className="font-freight text-base md:text-lg sm:text-2xl">
                      {reference}
                    </p>
                  </a>
                </SwiperSlide>
              )
            )}
          {products.length > 4 && (
            <div className="flex justify-end">
              <SlideNavigation />
            </div>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default SimilarProductsSlider;
