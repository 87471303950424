import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import FilterList from "../filter-category/CategoriesFilterList";
import {
  useInstantSearch,
  usePagination,
  useSearchBox,
} from "react-instantsearch";
import { useLocation } from "react-router-dom";
import useFilterStateStore from "../../stores/useFilterStateStore";
import _ from "lodash";

const filtersData = [
  {
    name: "categories.name",
    label: "Catégories",
  },

  {
    name: "materials.name",
    label: "Matériaux",
  },
  {
    name: "tags.name",
    label: "SPÉCIFICITÉS",
  },
];

const translateGroup = (group) => {
  // Add your translation logic here
  // For example, you can use a switch statement or an object mapping
  switch (group) {
    case "seats":
      return "Sièges";
    case "tables":
      return "tables";
    case "complements":
      return "Compléments";
    default:
      return group;
  }
};

const FilterProduit = ({
  isSearchMode = false,
  facets = [],
  onChangeFilters,
  isOtherPage = false,
  filters: filterProps,
}) => {
  const { isFilterPanelOpen, setIsFilterPanelOpen } = useFilterStateStore(
    (state) => state
  );
  const { nbHits, currentRefinement } = usePagination();
  const {
    results: { hitsPerPage, hits },
  } = useInstantSearch();
  const { isSearchStalled } = useSearchBox();
  const localtion = useLocation();

  const [filters, setFilters] = useState({});
  const filterContainerRef = useRef(null);
  const [groupInFacet, setGroupInFacet] = useState(null);

  const { first, last } = useMemo(() => {
    return {
      first: hitsPerPage * currentRefinement + 1,
      last: hitsPerPage * (currentRefinement + 1),
    };
  }, [currentRefinement, hitsPerPage, hits]);

  useEffect(() => {
    const filters = {};
    if (filterProps && filterProps.length > 0) {
      filterProps.forEach((item) => {
        const [key, value] = item.split(":");
        if (filters[key]) {
          filters[key] = [
            ...(_.isArray(filters[key]) ? filters[key] : [filters[key]]),
            value,
          ];
        } else {
          filters[key] = value;
        }
      });
      setFilters(filters);
    }
  }, [filterProps]);

  useEffect(() => {
    if (facets?.length && !groupInFacet) {
      const facet = facets.find((item) => item.name === "categories.group");
      setGroupInFacet(facet?.data);
    }
  }, [facets]);

  useLayoutEffect(() => {
    setFilters({});
  }, [localtion.pathname]);

  useEffect(() => {
    const exec = () => {
      const navbar = document.getElementById("navbar");
      if (navbar && filterContainerRef.current) {
        filterContainerRef.current.style.top = `${navbar.clientHeight}px`;
      }
    };
    exec();
    window.addEventListener("resize", exec);
    return () => {
      window.removeEventListener("resize", exec);
    };
  }, []);

  const otherFacets = useMemo(() => {
    if (facets.length) {
      const otherFacets = filtersData
        .map(({ name, label }) => {
          const facet = facets.find((item) => item.name === name);
          return facet ? { name, facet: facet.data, label } : null;
        })
        .filter((item) => item);
      return otherFacets;
    }
    return [];
  }, [facets]);

  const handleClickInGroup = (groupFilter) => {
    if (filters["categories.group"] === groupFilter) {
      onChangeFilters && onChangeFilters({});
      setFilters({});
    } else {
      onChangeFilters && onChangeFilters({ "categories.group": groupFilter });
      setFilters({ "categories.group": groupFilter });
    }
  };

  const handleChangeFilter = (e) => {
    const currentValue = { ...filters, [e.target.name]: e.target.value };
    onChangeFilters && onChangeFilters(currentValue);
    setFilters(currentValue);
  };

  const handleClickFilterPar = () => {
    setTimeout(() => {
      setIsFilterPanelOpen(!isFilterPanelOpen);
    }, 100);
  };

  useEffect(() => {
    const closePanel = () => {
      setIsFilterPanelOpen(false);
    };
    window.addEventListener("click", closePanel);
    return () => {
      window.removeEventListener("click", closePanel);
    };
  }, []);

  return (
    <div
      ref={filterContainerRef}
      onClick={(e) => {
        e.stopPropagation();
        handleClickFilterPar();
      }}
      className="lg:container mx-auto sticky z-20 bg-white border-b-brown-500 rounded-t-none rounded-b-lg border-b-8 px-4 md:px-5"
    >
      <div className="flex sm:items-center flex-col-reverse sm:flex-row sm:justify-between">
        {!isSearchMode && (
          <div className="inline-block">
            <button
              className="text-18 flex items-center order-3 sm:order-1 py-2 sm:py-5"
              onClick={(e) => {
                e.stopPropagation();
                handleClickFilterPar();
              }}
            >
              <span className="text-14 md:text-16">Filtrer par</span>
              {isFilterPanelOpen ? (
                <ChevronUpIcon className="h-4 w-4 md:h-8 mx-2" />
              ) : (
                <ChevronDownIcon className="h-4 w-4 md:h-8 mx-2" />
              )}
            </button>
          </div>
        )}
        <div className="flex justify-between lg:justify-around sm:gap-6 flex-nowrap flew-row sm:px-2 py-2 lg:py-2 order-1 sm:order-2 col-start-1 col-end-5 sm:col-start-5 sm:col-end-9">
          {!isOtherPage &&
            groupInFacet &&
            Object.entries(groupInFacet).map(([key]) => (
              <button
                className={classNames(
                  "text-12 px-3 lg:px-4 sm:px-5 py-1 sm:py-2 sm:text-10 lg:text-sm bg-white hover:bg-brown-400 uppercase rounded border border-brown-500",
                  {
                    "!bg-brown-500": filters["categories.group"] === key,
                  }
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickInGroup(key);
                }}
                key={key}
              >
                {translateGroup(key)}
              </button>
            ))}
        </div>
        <div className="hidden whitespace-nowrap sm:flex text-12 md:text-12 order-3 flex-col justify-center col-start-11 col-end-13  text-right">
          <span>
            de {first} à {nbHits <= last ? nbHits : last} sur {nbHits} produits{" "}
          </span>
        </div>
      </div>
      <div
        style={{ maxHeight: 500, top: "calc(100% - 4px)" }}
        className={classNames(
          "block bg-white shadow-lg overflow-auto justify-between border-b-brown-500  rounded-t-none rounded-b-lg border-b-8 px-4 pt-8 absolute left-0 w-full",
          {
            "!hidden": !isFilterPanelOpen,
          }
        )}
      >
        <div
          className={classNames("w-full relative", {
            "flex flex-wrap lg:grid grid-cols-1 xl:grid-cols-5 flex-row gap-x-[48px] xl:gap-x-[64px] 2xl:gap-x-[120px]":
              otherFacets.length === 3,
            "!gap-x-[32px]": !filters["categories.group"] && !isOtherPage,
            "flex flex-wrap flex-row lg:grid grid-cols-5 xl:grid-cols-5 gap-x-[32px]":
              otherFacets.length === 2,
          })}
        >
          {isSearchStalled && (
            <div className="w-full h-full top-0 left-0 absolute z-10"></div>
          )}
          {otherFacets.map((item) => (
            <div
              className={classNames(
                {
                  "col-span-2 grid grid-cols-1 lg:grid-cols-2":
                    item.label === "SPÉCIFICITÉS" ||
                    item.label === "Catégories",
                  "col-span-1": item.label !== "SPÉCIFICITÉS",
                },
                "first:mt-0 relative z-0 mb-[48px]"
              )}
              key={item.name}
            >
              <FilterList
                {...item}
                filters={filters}
                onChange={handleChangeFilter}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterProduit;
