import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { XCircleIcon } from "@heroicons/react/24/outline";

export default function ModalShowImage({ open, onClose, currentImage }) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center  text-center">
            <Transition.Child
              as={Fragment}
              // enter="ease-out duration-300"
              // enterFrom="opacity-0 scale-95"
              // enterTo="opacity-100 scale-100"
              // leave="ease-in duration-200"
              // leaveFrom="opacity-100 scale-100"
              // leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex justify-center items-center w-full h-screen  transform overflow-hidden  bg-black/25 p-6 text-left align-middle transition-all relative">
                <button
                  className="absolute right-4 top-4"
                  onClick={() => onClose && onClose()}
                >
                  <XCircleIcon className="h-[40px] sm:h-[64px] w-[40px] sm:w-[64px] text-white font-thin" />
                </button>
                <div className="w-4/5 flex justify-center items-center h-[80vh]">
                  <img
                    src={currentImage}
                    alt="icon"
                    className="h-full w-auto bg-skin-500 "
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
