import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import GenericLayout from "../layouts/GenericLayout";
import ProductGrid from "../components/products/ProductGrid";
import PaginatedItems from "../components/pagination/Pagination";
import {
  useInstantSearch,
  Configure,
  useSearchBox,
  InstantSearch,
} from "react-instantsearch";
import FilterProduit from "../components/filter-produit";
import { isArray } from "lodash";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { indexName, searchClient } from "../../packs";
import { translateGroupName } from "../_helpers/group";
import useFilterStateStore from "../stores/useFilterStateStore";

const ProductsPage = ({ isGroupPage = false, isCategorypage = false }) => {
  const {
    results: { facets },
    results: { hits },
  } = useInstantSearch();
  const [filters, setFilters] = useState([]);
  const { isSearchStalled } = useSearchBox();
  const paginationRef = useRef(null);
  const params = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  const { slug, group_name } = params;

  const name = isGroupPage
    ? translateGroupName(group_name)
    : slug?.split("--")?.[1].replace(/-/g, " ");

  const filterState = useMemo(
    () => [
      ...(isGroupPage
        ? [`categories.group:${group_name}`]
        : isCategorypage
        ? [`categories.name:${name}`]
        : []),
      ...filters,
    ],
    [filters, name, isGroupPage, isCategorypage]
  );

  useEffect(() => {
    window.onpopstate = (e) => {
      const { filters, page } = e.state;
      if (filters) {
        setFilters(filters);
        paginationRef.current?.setPage(page);
      }
    };
  }, []);

  const { setIsFilterPanelOpen } = useFilterStateStore((state) => state);

  const { breadCrumbs, title, facetsToGet, h1 } = useMemo(() => {
    if (isGroupPage) {
      return {
        breadCrumbs: [],
        title: `${name} | ADM Mobilier`,
        facetsToGet: ["categories.name", "materials.name", "tags.name"],
        h1: name,
      };
    } else if (isCategorypage) {
      return {
        breadCrumbs: [],
        title: `${name} | ADM Mobilier`,
        facetsToGet: ["materials.name", "tags.name"],
        h1: name,
      };
    } else {
      return {
        breadCrumbs: [
          { label: "Accueil", url: "/" },
          {
            label: "Mobilier et Accessoires",
          },
        ],
        h1: "Mobilier de restaurant – Bar – Brasserie",
        title:
          "Mobilier et Accessoires pour restaurants, bars, brasserie...| Adm Mobilier",
        facetsToGet: [
          "categories.group",
          "categories.name",
          "materials.name",
          "tags.name",
        ],
      };
    }
  }, [isGroupPage, isCategorypage, params]);

  const handleChangeFilter = useCallback((filters) => {
    const currentFilters = [];
    paginationRef.current?.setPageZero();
    Object.entries(filters).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((el) => {
          currentFilters.push(`${key}:${el}`);
        });
      } else {
        currentFilters.push(`${key}:${value}`);
      }
    });
    setFilters(currentFilters);
    window.history.pushState(
      { filters: currentFilters, page: 0 },
      "",
      window.location.href
    );
  }, []);

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
      window.history.pushState({ filters, page }, "", window.location.href);
    },
    [filters]
  );

  useLayoutEffect(() => {
    window.history.pushState(
      { filters, page: currentPage },
      "",
      window.location.href
    );
    setFilters([]);
    setCurrentPage(0);
    paginationRef.current?.setPageZero();
  }, [location.pathname]);

  return (
    <GenericLayout
      title={title}
      h1={h1}
      breadCrumbs={breadCrumbs}
      onClick={() => {
        setIsFilterPanelOpen(false);
      }}
      isUseFilter
    >
      <Configure
        hitsPerPage={20}
        facetFilters={filterState}
        facets={facetsToGet}
      />
      <div id="container--" className="px-3">
        <FilterProduit
          filters={filters}
          isOtherPage={isCategorypage || isGroupPage}
          allProductsPages
          isSearchMode={false}
          facets={facets}
          onChangeFilters={handleChangeFilter}
        />
        <ProductGrid
          loading={isSearchStalled || !hits?.length}
          products={hits || []}
        />
        <div
          className={classNames("flex justify-center mt-12 mb-8", {
            "!hidden": isSearchStalled || !hits?.length,
          })}
        >
          <PaginatedItems onChangePage={handleChangePage} ref={paginationRef} />
        </div>
      </div>
    </GenericLayout>
  );
};

const ProductsPageWithInstantSearch = (props) => {
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <ProductsPage {...props} />
    </InstantSearch>
  );
};

export default ProductsPageWithInstantSearch;
