import React from "react";
import GenericLayout from "../layouts/GenericLayout";

const MentionsLegales = () => {
  return (
    <GenericLayout
      title={"Mentions légales | Adm mobilier"}
      h1="mentions légales"
    >
      <div className="relative bg-skin-500">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-kakhi-600 font-semibold tracking-wide uppercase">
                Mentions légales
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-kakhi-900 sm:text-4xl">
                Raison sociale et éditeur
              </h3>
              <p className="mt-8 text-lg text-gray-500">
                <ul>
                  <li>ADM, sièges et tables pour la restauration.</li>
                  <li>Rue Norbert Ségard 59223 RONCQ</li>
                  <li>tel: 03 20 03 88 88</li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </p>
              <div className="mt-5 prose prose-indigo text-gray-500">
                <h2 className="leading-6 text-kakhi-600 font-semibold tracking-wide uppercase">
                  Clauses de non-responsabilité quant aux contenus
                </h2>
                <p>
                  Les informations et éléments de ce site sont fournis à titre
                  indicatif uniquement. Tout ou partie des informations et
                  éléments diffusés sur le site, sont fournis en l'état, sans
                  aucune garantie d'aucune sorte, expresse ou implicite, et
                  notamment sans que ceci soit limitatif, de disponibilité,
                  d'exactitude ou de caractère complet. ADM - Mobilier ne
                  saurait être tenu pour responsable d'un préjudice direct ou
                  indirect résultant de l'utilisation d'informations contenues
                  sur ce site.
                </p>

                <p>
                  Le site peut notamment présenter des erreurs techniques et
                  typographiques ou autres inexactitudes, ce que vous
                  reconnaissez et acceptez en utilisant le site. ADM - Mobilier
                  ne saurait être tenu responsable des erreurs ou omissions
                  présentées par et/ou contenues dans le site ou par tout
                  document référencé. Les informations contenues dans le site
                  sont non-contractuelles et sujettes à modification à tout
                  moment. Les informations présentées sur le site font
                  régulièrement l'objet de mises à jour.
                </p>

                <p>
                  {" "}
                  ADM - Mobilier ne garantit en aucune manière que les fonctions
                  contenues dans le site ou l'accès au site seront assurés sans
                  interruption et sans erreur, ni que le site ou le serveur qui
                  les met à disposition sont exempts de tout virus et autres
                  composants dangereux. En aucune circonstance, ADM - Mobilier
                  ne sera responsable des préjudices fortuits, directs ou
                  indirects, résultant de l'utilisation de tout ou partie des
                  éléments du site.
                </p>

                <div>
                  Tout lien brisé, toute omission ou erreur doivent être
                  signalés par notre formulaire de contact. La responsabilité de
                  ADM - Mobilier ne saurait être directement ou indirectement
                  retenue, à quelque titre et pour quelque cause que ce soit :
                </div>
                <div className={"p-3"}>
                  <ul className={"list-disc"}>
                    <li>
                      en cas d'interruption de service du site motivée par des
                      opérations de maintenance ou par les comportements des
                      utilisateurs du site,
                    </li>
                    <li>
                      en cas d'inaccessibilité et/ou en cas d'incapacité à
                      utiliser le site par tout utilisateur du site,
                    </li>
                    <li>
                      en cas de dommages d'équipements de toute forme que ce
                      soit, de pertes de données, lors d'une connexion au site.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default MentionsLegales;
