/* eslint-disable no-undef */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";
import {
  createRealisation,
  deleteRealisation,
  updateRealisation,
  useRealisation,
} from "../../../_services/realisation-service";
import SelectProducts from "../../../components/forms/SelectProducts";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import ProductCard from "../../../components/products/ProductCard";
import PicturesManager from "../../../components/utils/PicturesManager";

const AdminRealisationAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    //getValues,
    //formState,
  } = useForm();
  const [realisationProducts, setRealisationProducts] = useState([]);

  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");

  const [previewURLs, setPreviewURLs] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [picturesBlobs, setPicturesBlobs] = useState([]);

  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();

  let { realisation_id } = useParams();
  let isEditMode = !!realisation_id;

  const goBack = () => {
    navigate("/admin/realisations");
  };

  const onSubmit = (data) => {
    let submitted_data = {
      realisation: {
        ...data,
        product_ids: realisationProducts.map((product) => {
          return product.id;
        }),
        cover_picture: coverPictureBlob,
        pictures: picturesBlobs,
      },
    };

    return isEditMode
      ? updateRealisation(realisation_id, submitted_data, goBack)
      : createRealisation(submitted_data, goBack);
  };

  const handleDelete = () => {
    console.log("DELETE ");
    deleteRealisation(realisation_id);
  };

  const { realisation, isLoading, isError } = useRealisation(realisation_id);

  const fields = ["name", "place", "description", "release_date"];

  useEffect(() => {
    if (!!realisation) {
      fields.forEach((field) =>
        setValue(field, realisation.data.attributes[field])
      );
      setRealisationProducts(
        getAllIncludedResourcesForAGivenType(realisation, "product")
      );
      setCoverPicture(realisation.data.attributes.cover_picture_url);
      // setPreviewURL(realisation.data.attributes.cover_picture_url);
      setPictures(realisation.data.attributes.pictures_data);
    }
  }, [realisation]);

  if (isLoading && !!realisation_id) {
    return <div>Product loading...</div>;
  }

  if (isError) {
    return <div> loading failed ...</div>;
  }

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col">
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"text-3xl py-5"}>
              {isEditMode ? "Edition" : "Création"} d&apos;une réalisation
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Nom
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("name")}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        autoComplete="name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Ville
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("place")}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        autoComplete="name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Description
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        {...register("description", { required: false })}
                        id="description"
                        name="description"
                        rows={5}
                        className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                        defaultValue={""}
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Description succinte du produit.
                      </p>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Date de le Réalisation
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("release_date")}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        autoComplete="name"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Photos
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Utilisez des photos présentant uniquement la réalisation en
                    question.
                  </p>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Photo de couverture
                  </label>

                  <CoverPictureManager
                    coverPicture={coverPicture}
                    setCoverPicture={setCoverPicture}
                    coverPictureBlob={coverPictureBlob}
                    setCoverPictureBlob={setCoverPictureBlob}
                    setPreviewURL={setPreviewURL}
                    previewURL={previewURL}
                    setIsLoaded={setIsLoaded}
                    // thumb_type={watch("thumb_type")}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Photo(s) de présentation
                </label>
                <PicturesManager
                  pictures={pictures}
                  setPictures={setPictures}
                  previewURLs={previewURLs}
                  picturesBlob={picturesBlobs}
                  setPicturesBlob={setPicturesBlobs}
                  setPreviewURLs={setPreviewURLs}
                  setIsLoaded={setIsLoaded}
                  isLoaded={isLoaded}
                  resource={{
                    name: "realisations",
                    id: realisation_id,
                    slug: realisation?.slug,
                  }}
                />
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
              <div className="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Produits composant la réalisation :
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <div className="mt-4 space-y-4">
                          <div className="space-y-6">
                            <SelectProducts
                              selectedOptions={realisationProducts}
                              setSelectedOptions={setRealisationProducts}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-5 flex flex-row justify-between">
              <div>
                <span
                  onClick={handleDelete}
                  className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Supprimer le produit
                </span>
              </div>
              <div className="flex justify-end">
                <Link
                  to={"/admin/"}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Annuler
                </Link>
                <button
                  disabled={!isLoaded}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  {isLoaded
                    ? isEditMode
                      ? "Sauvegarder les modifications"
                      : "Créer un nouveau produit"
                    : "Chargement de la photo en cours"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={"flex flex-col w-1/2"}>
        <div className={"sticky top-0 p-4 m-4 bg-beige-400 rounded"}>
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la présentation
          </h3>
          <div className={"grid grid-cols-1"}>
            <div className={"grid grid-cols-1"}>
              <div
                className="bg-red-100 aspect-1 h-full w-full bg-center bg-cover"
                style={{
                  backgroundImage: `url('${
                    previewURL.length > 0 ? previewURL : coverPicture
                  }')`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminRealisationAddEditPage;
