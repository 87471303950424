import React, { Fragment, useEffect, useMemo, useRef } from "react";
import GenericLayout from "../layouts/GenericLayout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUniver, getUnivers } from "../redux/slices/realisation";
import classNames from "classnames";
import { Swiper as SwiperComp, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css/scrollbar";
import "swiper/css";

Swiper.use([Scrollbar]);

const Texts = ({ name, place, description }) => {
  return (
    <div>
      <div className="flex items-baseline">
        <h2 className="font-playfair text-[16px] md:text-[20px] font-medium">
          {name}
        </h2>
        <p className="font-montserrat text-[12px] md:text-[14px]">
          &nbsp;- {place}
        </p>
      </div>
      <p className="font-montserrat text-[14px] mt-[8px] md:mt-[24px]">
        {description}
      </p>
    </div>
  );
};

const Block1 = ({
  i,
  item: {
    attributes: { name, description, cover_picture_url, pictures_data, place },
  },
}) => {
  const left = useRef(null);
  const right = useRef(null);

  useEffect(() => {
    const exec = () => {
      if (left.current && right.current && window.innerWidth >= 768) {
        left.current.style.height = `0px`;
        setTimeout(() => {
          left.current.style.height = `${right.current.clientHeight}px`;
        }, 10);
      } else if (left.current && window.innerWidth < 768) {
        left.current.style.height = `231px`;
      }
    };
    exec();
    setTimeout(() => {
      exec();
    }, 500);
    window.addEventListener("resize", exec);
    return () => {
      window.removeEventListener("resize", exec);
    };
  }, []);

  return (
    <div
      className={classNames(
        "grid-cols-12 xl:grid-cols-10 grid gap-[16px] xl:gap-[20px] items-stretch col-span-full",
        {
          "mt-8": i === 0,
        }
      )}
    >
      <div
        ref={left}
        className="col-span-full md:col-start-1 md:col-end-7 row-start-2 row-end-3 md:row-start-1 md:row-end-2"
      >
        <img
          className="w-full h-full object-center object-cover rounded-xl"
          src={cover_picture_url}
          alt={name}
        />
      </div>
      <div
        ref={right}
        className="col-span-full md:col-start-7 md:col-end-13 xl:col-end-11 flex flex-col  justify-between row-start-1 row-end-2"
      >
        <Texts place={place} description={description} name={name} />
        {pictures_data[0]?.url && (
          <div className="w-full grid-cols-4 md:grid mt-[40px] hidden">
            <img
              className="col-start-1 col-end-4 w-full h-[129px] 2xl:h-[281px] rounded-xl object-center object-cover"
              src={pictures_data[0]?.url}
              alt={name}
            />
          </div>
        )}
      </div>
      {pictures_data[1]?.url && (
        <div className="col-start-4 col-end-9 xl:col-start-5 xl:col-end-8 hidden md:block">
          <img
            className=" w-full h-[236px] 2xl:h-[281px] rounded-xl object-center object-cover"
            src={pictures_data[1]?.url}
            alt={name}
          />
        </div>
      )}
      <div className="col-span-full grid grid-cols-2 gap-x-4 md:hidden row-start-3 row-end-4">
        <img
          src={pictures_data[0]?.url}
          className="w-full h-[118px] object-center object-cover rounded-xl"
          alt={name}
        />
        <img
          src={pictures_data[1]?.url}
          className="w-full h-[118px] object-center object-cover rounded-xl"
          alt={name}
        />
      </div>
    </div>
  );
};

const Block2 = ({
  item: {
    attributes: { name, description, cover_picture_url, place },
  },
}) => {
  return (
    <div className="grid-cols-12 xl:grid-cols-10 grid mt-[32px] sm:mt-[64px] xl:mt-[120px]">
      <div className="col-span-full md:col-start-1 md:col-end-8 xl:col-end-6">
        <Texts name={name} place={place} description={description} />
      </div>
      <div className="col-span-full md:col-start-1 md:col-end-10 xl:col-end-8 mt-[16px] md:mt-[24px]">
        <img
          src={cover_picture_url}
          alt={name}
          className="w-full h-[231px] sm:h-[300px] md:h-[340px] xl:h-[420px] 2xl:h-[447px] object-center object-cover rounded-xl"
        />
      </div>
    </div>
  );
};

const Block3 = ({
  item: {
    attributes: { name, description, cover_picture_url, place },
  },
}) => {
  return (
    <div className="grid grid-cols-12 xl:grid-cols-10 gap-x-[20px] mt-[32px] sm:mt-[64px] xl:mt-[120px]">
      <div className="col-span-full md:col-start-1 xl:col-start-2 md:col-end-6">
        <Texts name={name} place={place} description={description} />
      </div>
      <div className="col-span-full md:col-start-6 md:col-end-13 xl:col-end-11 mt-[16px] md:mt-0">
        <img
          src={cover_picture_url}
          alt={name}
          className="w-full h-[231px] sm:h-[300px] md:h-[338px] 2xl:h-[514px] object-center object-cover rounded-xl"
        />
      </div>
    </div>
  );
};

const Block4 = ({
  item: {
    attributes: { name, description, cover_picture_url, pictures_data, place },
  },
}) => {
  return (
    <div className="grid grid-cols-12 xl:grid-cols-10 gap-x-[20px] mt-[32px] sm:mt-[64px] xl:mt-[120px]">
      <div className="col-span-full md:col-start-1 md:col-end-7 lg:col-end-6 xl:col-end-5 grid grid-cols-4">
        <div className="col-span-full">
          <Texts name={name} place={place} description={description} />
        </div>
        {pictures_data[0]?.url && (
          <div className="col-start-2 col-end-5 mt-[24px] 2xl:mt-[112px] hidden md:block">
            <img
              className="h-[218px] 2xl:h-[281px] w-full object-center object-cover rounded-xl"
              src={pictures_data[0]?.url}
              alt={name}
            />
          </div>
        )}
      </div>
      <div className="col-span-full grid grid-cols-2 gap-x-[16px] md:block md:col-start-7 lg:col-start-6 xl:col-start-5 sm:col-end-13 xl:col-end-9 mt-[16px] md:mt-0">
        <img
          src={cover_picture_url}
          alt={name}
          className="h-[231px] md:h-[440px]  w-full 2xl:h-[586px] object-center object-cover rounded-xl"
        />
        {pictures_data[0]?.url && (
          <img
            className="h-[231px] w-full block md:hidden object-center object-cover rounded-xl"
            src={pictures_data[0]?.url}
            alt={name}
          />
        )}
      </div>
    </div>
  );
};

export const Block5 = ({ item, children, images }) => {
  const { attributes } = item || {};
  const {
    name = "",
    description = "",
    cover_picture_url = "",
    pictures_data = [],
    place = "",
  } = attributes || {};

  const right = useRef(null);
  const left = useRef(null);

  useEffect(() => {
    const exec = () => {
      if (right.current && left.current && window.innerWidth >= 768) {
        right.current.style.height = "0px";
        setTimeout(() => {
          right.current.style.height = `${left.current.clientHeight}px`;
        }, 10);
      } else {
        if (right.current) right.current.style.height = "231px";
      }
    };

    exec();
    setTimeout(() => {
      exec();
    }, 500);

    window.addEventListener("resize", exec);
    return () => {
      window.removeEventListener("resize", exec);
    };
  }, []);

  return (
    <div className="grid grid-cols-12 xl:grid-cols-10 gap-[16px] md:gap-[20px] mt-[32px] sm:mt-[64px] xl:mt-[120px] items-start">
      <div
        ref={left}
        className="col-span-full md:col-start-1 md:col-end-6 xl:col-end-5 grid-cols-5 xl:grid-cols-4 grid"
      >
        <div className="col-span-full">
          {children ? (
            children
          ) : (
            <Texts name={name} place={place} description={description} />
          )}
        </div>
        <div className="mt-[32px] 2xl:mt-[40px] col-start-3 xl:col-start-2 col-end-6 xl:col-end-5 hidden md:block">
          {(pictures_data[0]?.url || images) && (
            <img
              src={children ? images[1] : pictures_data[0]?.url}
              className="w-full h-[129px] 2xl:h-[281px] object-cover object-center rounded-xl"
              alt={name}
            />
          )}
        </div>
      </div>
      <div
        ref={right}
        className="col-span-full md:col-start-6 xl:col-start-5 md:col-end-13 xl:col-end-12"
      >
        <img
          src={images ? images[0] : cover_picture_url}
          alt={name}
          className="w-full h-full object-center object-cover rounded-xl"
        />
      </div>
      {(images || pictures_data[1]?.url) && (
        <div className="col-start-5 col-end-8 xl:col-start-4 xl:col-end-7 hidden md:block">
          <img
            src={children ? images[2] : pictures_data[1]?.url}
            className="w-full h-[231px] md:h-[236px] 2xl:h-[281px] object-cover object-center rounded-xl"
            alt={name}
          />
        </div>
      )}
      {(images || (pictures_data[1]?.url && pictures_data[0]?.url)) && (
        <div className="col-span-full md:hidden grid-cols-2 grid gap-x-[16px]">
          <img
            src={children ? images[1] : pictures_data[0]?.url}
            className="w-full h-[118px] 2xl:h-[281px] object-cover object-center rounded-xl"
            alt={name}
          />
          <img
            src={children ? images[2] : pictures_data[1]?.url}
            className="w-full h-[118px] object-cover object-center rounded-xl"
            alt={name}
          />
        </div>
      )}
    </div>
  );
};

const Block6 = ({
  item: {
    attributes: { name, description, cover_picture_url, pictures_data, place },
  },
}) => {
  return (
    <div className="grid grid-cols-12 xl:grid-cols-10 md:gap-x-[20px] mt-[32px] sm:mt-[64px] xl:mt-[120px]">
      <div className="mt-[16px] md:mt-0 col-span-full md:col-start-1 lg:col-start-3 md:col-end-7 lg:col-end-8 xl:col-end-7 grid grid-cols-2 gap-x-[16px] md:block row-start-2 row-end-3 md:row-start-1 md:row-end-2">
        <img
          src={cover_picture_url}
          className="w-full h-[231px] md:h-[440px] 2xl:h-[596px] object-cover object-center rounded-xl"
          alt={name}
        />
        {pictures_data[0]?.url && (
          <img
            src={pictures_data[0]?.url}
            className="w-full h-[231px] object-cover object-center rounded-xl block md:hidden"
            alt={name}
          />
        )}
      </div>
      <div className="col-span-full md:col-start-7 lg:col-start-8 md:col-end-13 xl:col-start-7 xl:col-end-11 grid-cols-4 grid">
        <div className="col-span-full">
          <Texts name={name} place={place} description={description} />
        </div>
        {pictures_data[0]?.url && (
          <div className="col-start-1 col-end-4 mt-[32px] 2xl:mt-[112px] hidden md:block">
            <img
              src={pictures_data[0]?.url}
              className="w-full h-[218px] 2xl:h-[281px] object-cover object-center rounded-xl"
              alt={name}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const SwiperUnivers = ({ univers = [] }) => {
  return (
    <div className="swiper-univers mt-[8px] md:mt-[16px]">
      <SwiperComp
        slidesPerView={1.3}
        spaceBetween={32}
        breakpoints={{
          768: {
            slidesPerView: "auto",
            spaceBetween: 48,
          },
        }}
        scrollbar={{ dragClass: ".scroll-bar", dragSize: 100 }}
        modules={[Scrollbar]}
      >
        {univers?.map((item, index) => (
          <SwiperSlide key={index}>
            <a href={`/nos-realisations/${item.attributes.slug}`}>
              <div className="w-full md:w-[298px] 2xl:w-[400px]">
                <img
                  src={item.attributes?.cover_picture_url}
                  alt={item.attributes?.name}
                  className="w-full h-[278px] md:h-[409px] 2xl:w-[500px] object-cover object-center"
                />
                <h3 className="font-playfair text-[16px] md:text-[20px] font-medium mt-[8px] md:mt-[16px]">
                  {item.attributes?.name}
                </h3>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </SwiperComp>
      <div className="scroll-bar mt-[24px]"></div>
    </div>
  );
};

const UniversDetailsPage = () => {
  const dispatch = useDispatch();
  const { univers, univer } = useSelector((state) => state.realizations);

  const { slug } = useParams();

  const realizations = useMemo(() => {
    if (!univer) {
      return null;
    }

    const realisations = univer.included.filter(
      (item) => item.type === "realisation"
    );
    let i = 0;
    let k = 0;
    const len = realisations.length;

    while (i < len) {
      realisations[i] = { ...realisations[i], tydeDesign: k };
      k++;
      if (k > 5) {
        k = 0;
      }
      i++;
    }

    return realisations;
  }, [univer]);

  const universArray = useMemo(() => {
    if (univer && univers) {
      return univers.filter((item) => item.id !== univer.data.id);
    }
    return null;
  }, [univers, univer]);

  useEffect(() => {
    dispatch(getUnivers());
    dispatch(getUniver(slug));
  }, []);

  return (
    <GenericLayout
      title={`${univer?.data.attributes.name} | Adm mobilier`}
      h1={univer?.data.attributes.name}
      breadCrumbs={[
        { label: "Adm mobilier", url: "/" },
        { label: "Réferences", url: "/references" },
        {
          label: univer?.data.attributes.name,
          url: `/nos-realisations/${slug}`,
        },
      ]}
    >
      <div className="grid-cols-12 grid px-5 xl:px-0">
        <h2 className="font-playfair  font-bold text-20 lg:text-24 2xl:text-32 col-span-full xl:col-start-2 xl:col-end-12">
          Nos Réalisations
        </h2>
        <div className="col-span-full xl:col-start-2 xl:col-end-12">
          {realizations?.map((item, i) => (
            <Fragment key={item.id}>
              {item.tydeDesign === 0 && <Block1 i={i} item={item} />}
              {item.tydeDesign === 1 && <Block2 item={item} />}
              {item.tydeDesign === 2 && <Block3 item={item} />}
              {item.tydeDesign === 3 && <Block4 item={item} />}
              {item.tydeDesign === 4 && <Block5 item={item} />}
              {item.tydeDesign === 5 && <Block6 item={item} />}
            </Fragment>
          ))}
        </div>
        <div className="col-span-full mt-[64px] md:mt-[120px] px-0 md:px-5">
          <h2 className="font-playfair  font-bold text-20 lg:text-24 2xl:text-32">
            Nos autres réalisations
          </h2>
          <SwiperUnivers univers={universArray} />
        </div>
      </div>
    </GenericLayout>
  );
};

export default UniversDetailsPage;
