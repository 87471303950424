import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const createFilter = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/filters`,
    { filter: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    // .then(category => uploadFile(blobSignedID, category))
    .then(callback);
};
export const updateFilter = (id, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/filters/${id}`,
    { filter: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    // .then(category => uploadFile(blobSignedID, category))
    .then(callback);
};
export const deleteFilter = (id, callback) => {
  Api.delete(`${API_V1_BASE_URL}/filters/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(callback);
};
export const useFiltersOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_filters_options`,
    getFetcher
  );
  return {
    filters: data,
    isLoading: !error && !data,
    isError: error,
  };
};
