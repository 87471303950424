import React from "react";
import { createRoot } from "react-dom/client";
import App from "../src/App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "../src/providers/authProvider";
import ScrollToTop from "../src/components/utils/ScrollToTop";
import * as Sentry from "@sentry/react";
import algoliasearch from "algoliasearch/lite";

Sentry.init({
  dsn: "https://510c298c622231de0747c4f2e055ae38@o474542.ingest.sentry.io/4506241686175744",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// algolia config
export const projectId = "IF42V5PKCO";
export const apiKeyAlogolia = "11ce90102e881e562f4ecb188e5f58ae";
export const indexName = process.env.ALGOLIA_INDEX_NAME;
export const searchClient = algoliasearch(projectId, apiKeyAlogolia);

document.addEventListener("turbolinks:load", () => {
  const rootEl = document.getElementById("root");
  const root = createRoot(rootEl);

  console.log(indexName);

  root.render(
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <App />
      </AuthProvider>
    </BrowserRouter>
  );
});
