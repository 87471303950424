/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import { useDropzone } from "react-dropzone";
import Api from "../../_helpers/api";
import { useParams } from "react-router-dom";

export const deleteFile = (signed_id, callback) => {
  Api.delete(`file/${signed_id}`).then(() => {
    callback && callback();
  });
};

const DocumentManager = ({
  filesPDF,
  setFilesBlob,
  setIsLoaded,
  triggerRefreshComponent,
}) => {
  const inputRef = useRef(null);
  let { product_id } = useParams();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState({ state: false, message: "" });

  const uploadFile = (_input, file) => {
    setIsLoaded(false);
    setLoading({ state: true, message: "Téléchargement en cours..." });
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        setFilesBlob((filesBlob) => [blob.signed_id].concat(filesBlob));
        setIsLoaded(true);
        setFiles((curr) => (curr?.length ? [blob].concat(curr) : [blob]));
        setLoading({ state: false, message: "" });
      }
    });
  };
  const handleFileUpload = (files) => {
    if (!!files) {
      files.forEach((file) => {
        uploadFile(inputRef, file);
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileUpload(acceptedFiles);
    },
    maxFiles: 10,
    accept: "application/pdf",
  });

  const handleDeleteFileAttachment = (file) => {
    setLoading({ state: true, message: "Suppression en cours..." });
    if (file.file_name) {
      deleteFile(file.signed_id, () => {
        Api.delete(`api/v1/products/${product_id}/attachment/${file.id}`).then(
          () => {
            triggerRefreshComponent();
            setFiles((curr) => curr.filter((item) => item.id !== file.id));
            setLoading({ state: false, message: "" });
          }
        );
      });
    }
    if (file.filename) {
      deleteFile(file.signed_id, () => {
        setFiles((curr) => curr.filter((item) => item.id !== file.id));
        setLoading({ state: false, message: "" });
        setFilesBlob((curr) => curr.filter((item) => item !== file.signed_id));
      });
    }
  };

  useEffect(() => {
    if (filesPDF?.length) {
      setFiles(filesPDF);
    }
  }, [filesPDF]);

  return (
    <div className="space-y-1 text-center">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Glisser le fichier PDF ou cliquer pour le selectionner</p>
        ) : (
          <p>Glisser le fichier PDF ou cliquer pour le selectionner</p>
        )}
      </div>
      {loading.state && (
        <div className="!my-[24px] text-center font-semibold text-xs">
          {loading.message}
        </div>
      )}
      <div className="mt-4">
        {files?.map((file, index) => (
          <div
            key={index}
            className="p-2 border border-gray-400 rounded-md first:mt-0 mt-2"
          >
            <p className="text-xs mb-2">{file.filename || file.file_name}</p>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteFileAttachment(file);
              }}
              className="bg-red-500 rounded-md hover:bg-red-700 text-center text-xs text-white px-[24px] py-[8px]"
            >
              Suprimer ce fichier
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentManager;
