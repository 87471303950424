import useSWR from "swr";
import Api, { API_V1_BASE_URL, post } from "../_helpers/api";
import {
  deleteFetcher,
  getFetcher,
  patchFetcher,
  postFetcher,
} from "../_helpers/fetch-wrapper";

export const useFamilies = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/families`, getFetcher);
  return {
    families: data,
    isError: error,
    isLoading: !data && !error,
  };
};
export const useFamily = (id) => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/families/${id}`,
    getFetcher
  );

  return {
    family: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useFamilyOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_families_options`,
    getFetcher
  );
  return {
    options: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const createFamily = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/families`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const deleteFamily = (id, callback) => {
  Api.delete(`${API_V1_BASE_URL}/families/${id}`)
    .then((res) => res.data)
    .then(callback);
};

export const updateFamily = (id, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/families/${id}`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};
