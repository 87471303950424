export function formatHumanReadableDate(dateString) {
  const date = new Date(dateString);

  // Extract components from the Date object
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensure two digits

  // Example format: "September 23, 2023 at 22:20"
  return `${day} ${month} ${year} à ${hours}:${minutes}`;
}
