import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import showroom from "../../../assets/images/showroom-2.jpg";
import showroomWide from "../../../assets/images/showroom-5.jpg";
import HeroSection from "../components/HeroSection";

const Showroom = () => {
  const article = {
    title: "Show-room",
    body: `
      <p>Sur rdv avec un de nos commerciaux, du lundi au vendredi, nos deux salles d’exposition (mobilier d’intérieur / mobilier de terrasse) vous permettent de tester et comparer plus de 300 modèles.<p/>
      <p>Notre matériauthèque dispose de centaines de nuanciers et échantillons. Revêtements de sièges, teintes de bois, coloris de structures, gammes de stratifiés : tout ce qu’il faut pour personnaliser et harmoniser les matières et les coloris de vos meubles.</p>
    `,
    imageURL: showroom,
    link: null,
  };

  const presentationBreadCrumbs = [
    { label: "Accueil", url: "/" },
    { label: "Services" },
    { label: "Showroom", url: "/services/showroom" },
  ];

  return (
    <GenericLayout
      title={"Nos Services | Show-room | Adm mobilier"}
      h1="Show-room"
      breadCrumbs={presentationBreadCrumbs}
    >
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection
            article={article}
            index={0}
            isH2
            breakpointFixBreadcrumb={1445}
            fixBreadcrumb
          />
          <div className="flex justify-center">
            <div
              className="imganim aspect-w-2 aspect-h-1 bg-cover bg-center rounded"
              style={{
                backgroundImage: `url(${showroomWide})`,
              }}
            />
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default Showroom;
