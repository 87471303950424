import React from "react";
import useSWR from "swr";
import Api, { API_V1_BASE_URL } from "../../../_helpers/api";
import Loading from "../../../components/utils/Loading";
import Error from "../../../components/Error";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { uniqueId } from "lodash";
import { filterTypes, columns } from "./AdminFiltersAddEditPage";

const AdminFiltersPage = () => {
  const fetcher = (url) => Api.get(url).then((res) => res.data);
  const { data, error } = useSWR(`${API_V1_BASE_URL}/filters`, fetcher);

  if (!data) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>Liste des filtres </h3>
        <Link
          to={"/admin/filters/new"}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded text-kakhi-700 border border-kakhi-500 hover:text-white bg-transparent hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
        >
          <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Ajouter un filtre
        </Link>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nom
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Appliqué sur
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.data.map(({ attributes }, idx) => (
                  <tr
                    key={uniqueId("filter__")}
                    className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {attributes.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {
                        filterTypes.find(
                          (item) => item.metaValue == attributes.filter_type
                        )?.label
                      }
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {columns.find(
                        (item) => item.columnName == attributes.column_name
                      )?.label || ""}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link
                        to={`/admin/filters/${attributes.id}`}
                        className="text-kakhi-600 hover:text-kakhi-900"
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFiltersPage;
