import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryServices } from "../../../_services/category-services";
import Api from "../../../_helpers/api";
import useSWR from "swr";
import classNames from "classnames";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import ProductCard from "../../../components/products/ProductCard";
import SelectProducts from "../../../components/forms/SelectProducts";
import SelectFilters from "../../../components/forms/SelectFilters";
import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";

const AdminCategoriesAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [categoryFilters, setCategoryFilters] = useState([]);

  const [category, setCategory] = useState({});
  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");
  const navigate = useNavigate();

  let { category_slug } = useParams();
  let isEditMode = !!category_slug;

  const thumbTypeOptions = [
    { value: "large", label: "Horizontale - 16/9" },
    { value: "regular", label: "verticale - 8/9" },
  ];

  const [isLoaded, setIsLoaded] = useState(true);

  const goBack = () => {
    navigate("/admin/categories");
  };

  const onSubmit = (data) => {
    let submitted_data = {
      ...data,
      cover_picture: coverPictureBlob,
      filter_ids: categoryFilters.map((filter) => {
        return filter.id;
      }),
    };

    return isEditMode
      ? CategoryServices.updateCategory(category_slug, submitted_data, goBack)
      : CategoryServices.createCategory(submitted_data, goBack);
  };

  const handleDelete = () => {
    CategoryServices.deleteCategory(category_slug, goBack);
  };

  const fetcher = (url) => Api.get(url).then((response) => response.data);
  const { data: categoryData, error } = useSWR(
    () => (category_slug ? `/api/v1/categories/${category_slug}` : null),
    fetcher
  );

  useEffect(() => {
    if (!!categoryData) {
      const fields = ["name", "description", "slug", "group"];
      fields.forEach((field) =>
        setValue(field, categoryData.data.attributes[field])
      );
      setCoverPicture(categoryData.data.attributes.cover_picture_url);
      setCategory(categoryData.data.attributes);
      setCategoryFilters(
        getAllIncludedResourcesForAGivenType(categoryData, "filter")
      );
    }
  }, [categoryData]);

  if (!categoryData && category_slug) {
    return <div>Chargement de la categorie...</div>;
  }

  if (error) {
    console.error(error);
    return <div> Erreur dans le chargement ...</div>;
  }

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col">
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"text-3xl py-5"}>
              {isEditMode ? "Edition d'une" : "Création d'une nouvelle "} sous
              catégorie
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Informations générales
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Ces informations seront visibles sur la page de description
                    du produit.
                  </p>
                </div>
                <div>
                  <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Catégorie
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("group")}
                          className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        >
                          <>
                            <option value={0} default>
                              Sélectionner le groupe
                            </option>
                            <option value={"tables"}>Tables</option>
                            <option value={"seats"}>Sièges</option>
                            <option value={"complements"}>Compléments</option>
                          </>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("name")}
                        required
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        autoComplete="name"
                        type="text"
                      />
                    </div>
                  </div>
                  {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="slug"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      URL
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                          adm-mobilier.com/categories/
                        </span>
                        <input
                          {...register("slug", {
                            required: false,
                            disabled: true,
                          })}
                          type="text"
                          id="slug"
                          value={slugify(watch("name"))}
                          className="flex-1 block w-full focus:ring-kakhi-500 focus:border-kakhi-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-50 "
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Description
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        {...register("description", { required: false })}
                        id="description"
                        name="description"
                        rows={5}
                        className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                        defaultValue={""}
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Facultatif, pourra servir en case de mise en place d'une
                        page dédié à la sous-catégorie.
                      </p>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Photo de couverture
                    </label>
                    <CoverPictureManager
                      coverPicture={coverPicture}
                      setCoverPicture={setCoverPicture}
                      coverPictureBlob={coverPictureBlob}
                      setCoverPictureBlob={setCoverPictureBlob}
                      setPreviewURL={setPreviewURL}
                      previewURL={previewURL}
                      setIsLoaded={setIsLoaded}
                      // thumb_type={watch("thumb_type")}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Type de vignette
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("thumb_type")}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    autoComplete="name"
                  >
                    {thumbTypeOptions.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="py-5 flex flex-row justify-between">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200 col-span-3">
                  <div className="pt-6 sm:pt-5">
                    <div role="group" aria-labelledby="label-notifications">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Filtres de sous-catégories
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="mt-4 space-y-4">
                              <div className="space-y-6">
                                <SelectFilters
                                  selectedOptions={categoryFilters}
                                  setSelectedOptions={setCategoryFilters}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-5 flex flex-row justify-between">
              <div>
                <span
                  onClick={handleDelete}
                  className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Supprimer
                </span>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={goBack}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Annuler
                </button>
                {/* <button
                  type={"button"}
                  onClick={() =>
                    navigate(
                      `/admin/categories/${categoryData.data.id}/products/order`
                    )
                  }
                  className={classNames(
                    "bg-transparent border border-kakhi-700 text-kakhi-700 hover:text-skin-500 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                    "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
                  )}
                >
                  Ordonner les produits
                </button> */}
                <button
                  disabled={!isLoaded}
                  type="submit"
                  className={classNames(
                    !isLoaded
                      ? "bg-kakhi-400"
                      : "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                    "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
                  )}
                >
                  {isLoaded
                    ? isEditMode
                      ? "Sauvegarder les modifications"
                      : "Créer une nouvelle sous-catégorie"
                    : "Chargememt de la photo en cours"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={"flex flex-col w-full w-1/2"}>
        <div className={"sticky top-0 p-4 m-4 bg-beige-400 rounded"}>
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la vignette de présentation
          </h3>
          <div className={"grid grid-cols-2"}>
            <ProductCard
              reference={watch("reference")}
              cover_picture_url={previewURL || coverPicture}
              thumb_type={watch("thumb_type")}
              name={watch("name")}
              readOnly={true}
              id={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCategoriesAddEditPage;
