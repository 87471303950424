import React, { useRef } from "react";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "react-dropzone";

const CoverPictureManager = ({
  coverPicture,
  setCoverPictureBlob,
  setPreviewURL,
  previewURL,
  setIsLoaded,
}) => {
  const inputRef = useRef(null);

  const uploadFile = (input, file) => {
    setIsLoaded(false);
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        setCoverPictureBlob(blob.signed_id);
        setIsLoaded(true);
      }
    });
  };
  const handleFileUpload = (files) => {
    if (!!files) {
      files.forEach((file, index) => {
        const dataURL = URL.createObjectURL(file);
        setPreviewURL(dataURL);
        uploadFile(inputRef, file, index);
      });
    }
  };

  if (previewURL) {
    return (
      <>
        <div className="flex text-sm text-gray-600">
          <Dropzone
            onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
            maxFiles={1}
          >
            {({ getRootProps, getInputProps }) => (
              <label
                htmlFor="cover_picture--upload"
                className="relative cursor-pointer bg-white rounded font-medium text-kakhi-600 hover:text-kakhi-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-kakhi-500"
              >
                <div {...getRootProps()}>
                  <input ref={inputRef} {...getInputProps()} />
                  {!previewURL && (
                    <p>Glisser la photo, ou cliquez pour la selectionner</p>
                  )}
                  <img
                    id={"cover-picture-preview"}
                    src={previewURL}
                    alt={"Cover picture"}
                    style={{ minWidth: "100px" }}
                    className={"cursor-pointer"}
                  />
                </div>
              </label>
            )}
          </Dropzone>
        </div>
      </>
    );
  }

  return (
    <div className="space-y-1 text-center">
      <Dropzone
        onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <label
            htmlFor="cover_picture--upload"
            className="relative cursor-pointer bg-white rounded font-medium text-kakhi-600 hover:text-kakhi-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-kakhi-500"
          >
            <div {...getRootProps()}>
              <input ref={inputRef} {...getInputProps()} />
              <p>Glisser votre fichier ou clicquer pour le selectionner</p>
              <em>(1 fichier maxi )</em>
              {coverPicture?.length > 0 && (
                <img
                  id={"cover-picture-preview"}
                  src={coverPicture}
                  alt={"Cover picture"}
                />
              )}
            </div>
          </label>
        )}
      </Dropzone>
    </div>
  );
};

export default CoverPictureManager;
