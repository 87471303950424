import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api, { API_V1_BASE_URL } from "../../_helpers/api";

const initialState = {
  tags: [],
  tag: null,
};

export const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    set_tags(state, action) {
      state.tags = action.payload;
    },
    set_tag(state, action) {
      state.tag = action.payload;
    },
  },
});

export const getTags = createAsyncThunk("get_tags", async (_, { dispatch }) => {
  const {
    data: { data },
  } = await Api.get("/api/v1/tags");
  dispatch(tagSlice.actions.set_tags(data));
  return data;
});

export const getTag = createAsyncThunk(
  "get_tag",
  async (tag_id, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(`/api/v1/tags/${tag_id}`);
    dispatch(tagSlice.actions.set_tag(data[0]));
    return data[0];
  }
);

export const createTag = createAsyncThunk("create_tag", async (name) => {
  const { data: data } = await Api.post("/api/v1/tags", { name });
  dispatch(tagSlice.actions.set_tag(data));
  return data;
});

export const editTag = createAsyncThunk(
  "edit_tag",
  async ({ name, tag_id }, { dispatch }) => {
    const { data: data } = await Api.patch(`/api/v1/tags/${tag_id}`, { name });
    dispatch(tagSlice.actions.set_tag(data));
    return data;
  }
);

export const deleteTag = (id, callback) => {
  Api.delete(`${API_V1_BASE_URL}/tags/${id}`)
    .then((res) => res.data)
    .then(callback);
};

export default tagSlice.reducer;
