import useSWR from "swr";
import Api, { API_V1_BASE_URL } from "../_helpers/api";
import {
  // deleteFetcher,
  getFetcher,
  // patchFetcher,
  // postFetcher,
} from "../_helpers/fetch-wrapper";

export const useUniverses = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/universes`, getFetcher);
  return {
    universes: data,
    isError: error,
    isLoading: !data && !error,
  };
};
export const useUniverse = (slug) => {
  const { data, error } = useSWR(
    slug ? `${API_V1_BASE_URL}/universes/${slug}` : null,
    getFetcher
  );
  return {
    universe: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useUniversesOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_universes_options`,
    getFetcher
  );
  return {
    options: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const createUniverse = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/universes`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const deleteUniverse = (slug, callback) => {
  Api.delete(`${API_V1_BASE_URL}/universes/${slug}`)
    .then((res) => res.data)
    .then(callback);
};

export const updateUniverse = (slug, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/universes/${slug}`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};
