import React from "react";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
// import { getSpecificIncludedRelationship } from "../../../_helpers/jsonapi-deserializer";
import { useUniverses } from "../../../_services/universe-services";
import Error from "../../../components/Error";
import Loading from "../../../components/utils/Loading";

const AdminUniversesPage = () => {
  const { universes, isError, isLoading } = useUniverses();

  if (isError) {
    return <Error error={isError} />;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>Univers</h3>
        <Link
          to={"/admin/universes/new"}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded text-kakhi-700 border border-kakhi-500 hover:text-white bg-transparent hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
        >
          <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Ajouter un univers
        </Link>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nom
                  </th>
                  {/*<th*/}
                  {/*  scope="col"*/}
                  {/*  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"*/}
                  {/*>*/}
                  {/*  */}
                  {/*</th>*/}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    date de sortie
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {universes.data.map(({ attributes }, personIdx) => (
                  <tr
                    key={`${attributes.reference}__${personIdx}`}
                    className={personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {attributes.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {/*{getSpecificIncludedRelationship(*/}
                      {/*  products,*/}
                      {/*  "category",*/}
                      {/*  relationships.categories?.data*/}
                      {/*)}*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {attributes.release_date}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link
                        to={`/admin/universes/${attributes.slug}`}
                        className="text-kakhi-600 hover:text-kakhi-900"
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUniversesPage;
