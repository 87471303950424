import Api, { API_V1_BASE_URL } from "../_helpers/api";

export class CategoryServices {
  static createCategory = (data, callback) => {
    Api.post(
      `${API_V1_BASE_URL}/categories`,
      { category: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      // .then(category => uploadFile(blobSignedID, category))
      .then(callback);
  };
  static updateCategory = (id, data, callback) => {
    Api.put(
      `${API_V1_BASE_URL}/categories/${id}`,
      { category: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      .then(callback);
  };
  static deleteCategory = (id, data, callback) => {
    Api.delete(`${API_V1_BASE_URL}/categories/${id}`).then(callback);
  };
}

export const bulkUpdateProductsByCategory = (category_id, records) => {
  Api.put(`${API_V1_BASE_URL}/category-products/${category_id}/products`, {
    products_by_category: [...records],
  }).then((res) => {
    if (res.status === 200) {
      console.log("ordre updated OK");
    }
  });
};
