import React, { useRef } from "react";
import GenericLayout from "../layouts/GenericLayout";
import { gsap } from "gsap";
import _ from "lodash";
import HeroSection from "../components/HeroSection";
import depot from "../../../assets/images/depot_presentation.jpg";
import admOutside from "../../../assets/images/adm-outside.jpg";
import logo from "../../../assets/images/logo_presentation.jpg";
import showroom from "../../../assets/images/showroom_presentation.jpg";

const Presentation = () => {
  const content = [
    {
      title: "L'entreprise est créée",
      body: `
      <p>En 1992, l’entreprise ADM Mobilier est fondée par Xavier Descamps, fort d’une longue expérience du mobilier pour espaces de restauration, acquise chez un industriel fabricant de sièges et tables.</p><p>L’entreprise dispose d’une salle d’exposition à Roncq (59). Elle meuble les intérieurs et les terrasses des cafés et restaurants, ainsi que des restaurants scolaires et d’entreprises, dans la région Nord-Pas de Calais. A partir de 1995, elle obtient plusieurs référencement auprès de chaînes de restaurants et de réseaux de franchisés. La structure d’ADM Mobilier s’agrandit d’un entrepôt et d’un atelier.</p>`,
      imageURL: logo,
      link: null,
    },
    {
      title: "ADM s'agrandit",
      body: `
        <p>Au début des années 2000, le développement de la clientèle des collectivités (entreprises, enseignement, établissements de santé, prestataires de restauration hors foyer), amène ADM Mobilier à créer son service interne de montage et installation, équipé de véhicules de livraison. Cet essor se réalise dans un nouveau bâtiment, à Halluin (59), qui permet de doubler la surface de stockage, et la création d’un grand show-room.</p>
      `,
      imageURL: depot,
      link: null,
    },
    {
      title: "Une nouvelle adresse",
      body: `
        <p>En 2021, ADM s’implante de nouveau à Roncq, au sein d’un bâtiment en façade et accès direct de l’autoroute Lille-Gand, optimisant la logistique d’approvisionnement, de stockage et de livraison, et proposant la meilleure accessibilité pour sa clientèle à la double salle d’exposition. Avec 12 à 35 ans d’expérience, l’expertise de nos responsables commerciaux nous procure aujourd’hui la fierté de compter, parmi nos références, les restaurants de nombre de fleurons des entreprises en France, d’écoles réputées, et d’administrations prestigieuses, sans oublier les établissements d’accueil et de soins.</p>
      `,
      imageURL: admOutside,
      link: null,
    },
    {
      title: "Nos partenaires",
      body: `
        <p>La majorité des entreprises prestataires de la restauration collective sont nos clients réguliers, comme la plupart des franchises et enseignes nationales ou internationales de la restauration. Les prescripteurs, architectes, décorateurs et agenceurs, sont nos partenaires quotidiens, de même que les fabricants et distributeurs de boissons ou de matériel de cuisine. S’y ajoutent les quelques 3 000 bars et restaurants livrés dans les Hauts-de-France et en Belgique.<p>
        <p>A 30 ans et après avoir équipé plus de 15 000 espaces de restauration, ADM Mobilier peut ainsi s’enorgueillir d’avoir fourni à ses clients près d’un million de sièges et tables.</p>
      `,
      imageURL: showroom,
      link: "/references",
    },
  ];

  const presentationBreadCrumbs = [
    { label: "Adm mobilier", link: "/" },
    { label: "Présentation", link: "/presentation" },
  ];

  const presentationPageRef = useRef(null);

  const handleScroll = () => {
    const q = gsap.utils.selector(presentationPageRef);
    gsap.to(q(".imganim"), {
      scale: 1.5,
      duration: 1,
      scrollTrigger: {
        trigger: "imganim",
        scrub: true,
        start: "top bottom",
      },
    });
  };

  return (
    <GenericLayout
      title="Adm Mobilier : Qui sommes nous ?"
      h1="Qui sommes nous ?"
    >
      <div
        ref={presentationPageRef}
        onScroll={handleScroll}
        className={" xl:mx-24 md:mx-12 mx-4"}
      >
        <div className="sm:space-y-20 space-y-12">
          {content.map((article, index) => {
            return (
              <HeroSection
                key={_.uniqueId("content__")}
                index={index}
                article={article}
                breadcrumbs={presentationBreadCrumbs}
                isH2
              />
            );
          })}
        </div>
      </div>
    </GenericLayout>
  );
};

export default Presentation;
