import React from "react";

const UniverseItem = ({ universe }) => {
  // TODO make the realisation live.

  const { id, name, cover_picture_url, description, slug } =
    universe.attributes;

  return (
    // <Link to={`/univers/${id}`} className="flex flex-col sm:leading-normal leading-tight sm:w-1/3 sm:px-4">
    //   <div
    //     className="overflow-hidden sm:rounded-desktop rounded-mobile aspect-w-1 aspect-h-1 bg-center bg-cover rounded"
    //     style={{
    //       backgroundImage: `url(${cover_picture_url})`
    //     }}
    //   />

    //   <p
    //     className="font-grotesque text-justify xl:text-left sm:block hidden text-20 xl:mr-32 sm:mb-16">{description}</p>
    // </Link>

    <a href={`/nos-realisations/${slug}`} className="group relative poi">
      <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded overflow-hidden group-hover:opacity-75 lg:h-80">
        <img
          src={cover_picture_url}
          alt={name}
          className="w-full h-full object-center object-cover lg:w-full lg:h-full"
        />
      </div>
      <div className="flex justify-between">
        <div>
          <h2 className="font-freight xl:text-40 sm:text-3xl text-20 font-bold first-letter:capitalize">
            {name}
          </h2>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </a>
  );
};

export default UniverseItem;
