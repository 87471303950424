import slugify from "react-slugify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { deleteMaterial } from "../../../_services/material-services";
import Api from "../../../_helpers/api";
import useSWR from "swr";
import classNames from "classnames";
import {
  createMaterial,
  updateMaterial,
} from "../../../_services/material-services";

const AdminMaterialAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  let { material_id } = useParams();
  let isEditMode = !!material_id;

  const [isLoaded, setIsLoaded] = useState(true);
  const [material, setMaterial] = useState([]);
  const goBack = () => {
    navigate("/admin/materials");
  };

  const onSubmit = (data) => {
    let submitted_data = {
      ...data,
    };

    return isEditMode
      ? updateMaterial(material_id, submitted_data, goBack)
      : createMaterial(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteMaterial(material_id, goBack);
  };

  const fetcher = (url) =>
    Api.get(url).then((response) => response.data.data.attributes);
  const { data: materialData, error } = useSWR(
    () => (material_id ? `/api/v1/materials/${material_id}` : null),
    fetcher
  );

  useEffect(() => {
    if (!!materialData) {
      const fields = ["name"];
      fields.forEach((field) => setValue(field, materialData[field]));
      setMaterial(materialData);
    }
  }, [materialData]);

  if (!materialData && material_id) {
    return <div>Chargement du materiau...</div>;
  }

  if (error) {
    console.error(error);
    return <div> Erreur dans le chargement ...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>
          {isEditMode ? "Edition d'un" : "Création d'un nouveau "} Matériau
        </h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Informations générales
              </h3>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("name")}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    autoComplete="name"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 flex flex-row justify-between">
          <div>
            <span
              onClick={handleDelete}
              className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
            >
              Supprimer le matériau
            </span>
          </div>
          <div className="flex justify-end">
            <button
              onClick={goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
            >
              Annuler
            </button>
            <button
              disabled={!isLoaded}
              type="submit"
              className={classNames(
                !isLoaded
                  ? "bg-kakhi-400"
                  : "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
              )}
            >
              {isLoaded
                ? isEditMode
                  ? "Sauvegarder les modifications"
                  : "Créer un nouveau matériau"
                : "Chargememt de la photo en cours"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminMaterialAddEditPage;
