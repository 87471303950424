import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import Hero from "../components/hero/Hero";
import heroImagee from "../../../assets/images/hero_promo_terrasse.jpg";
import { Block5 } from "./DetailRealisationPage";
import NewsletterInput from "../components/NewsletterInput";
import { ProductGridV2 } from "./SelectionTerrasse";

import image1 from "../../../assets/images/promo-terrasse/image_1_1.jpg";
import image2 from "../../../assets/images/promo-terrasse/image_1_2.jpg";
import image3 from "../../../assets/images/promo-terrasse/image_1_3.jpg";

import BLockContactV2 from "../components/block-contact-v2/BlockContactV2";
import contact from "../../../assets/images/selection-terrasses/contact.jpg";
// import { getProductsByTag } from "../redux/slices/filter";

export default function PromoTerrasses() {
  // const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   const getFunc = async () => {
  //     const results = await getProductsByTag("Promos Terrasse 2024");
  //     setProducts(results);
  //   };
  //   getFunc();
  // }, []);

  const products = [
    {
      attributes: {
        id: 1,
        price: "36.58 €",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736293/Monna_izsyzv.jpg",
        reference: "Monna",
        thumb_type: "regular",
        slug: "320325d4-1597-4511-8b72-bf7709adf507",
      },
    },
    {
      attributes: {
        id: 2,
        price: "39.63 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736297/Loft_tkl2kb.jpg",
        reference: "Loft",
        slug: "8db4bea1-5200-4f92-9495-ef8820ccb79f",
      },
    },
    {
      attributes: {
        id: 3,
        price: "121.21 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736295/Domino_sqqwam.jpg",
        reference: "Domino",
        slug: "ca1983e1-7604-4a14-b724-d086ece77d8f",
      },
    },
    {
      attributes: {
        id: 4,
        price: "23.51 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736304/Pano_wjosgt.jpg",
        reference: "Pano",
        slug: "5295c34a-7565-4ab0-91db-2ee36faebf1f",
      },
    },
    {
      attributes: {
        id: 5,
        price: "32.55 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736302/Maya_ozsm9x.jpg",
        reference: "Maya",
        slug: "970b61f6-aeed-4035-9a06-fa1f3082dc99",
      },
    },
    {
      attributes: {
        id: 6,
        price: "45.85 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736300/Helen_F_mcjja1.jpg",
        reference: "Helen F",
        slug: "c18c6dfb-299d-4b4c-9101-52c59c844077",
      },
    },
    {
      attributes: {
        id: 7,
        price: "43.71 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736291/Helen_C_hof0rz.jpg",
        reference: "Helen C",
        slug: "60661f44-90ec-45cd-b53d-c80b5e180696",
      },
    },
    {
      attributes: {
        id: 8,
        price: "39.85 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736294/Park_jcyqty.jpg",
        reference: "Park",
        slug: "d88471e8-37b0-4b0f-b303-c9808a52a86f",
      },
    },
    {
      attributes: {
        id: 9,
        price: "37.56 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736298/Lucy_c42fab.jpg",
        reference: "Lucy",
        slug: "32f63135-284b-4a6d-9a87-6d171e1d3b0f",
      },
    },
    {
      attributes: {
        id: 10,
        price: "30.60 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736301/Oyo_rbqq6e.jpg",
        reference: "Oyo",
        slug: "67344eac-2cc7-4ab9-9b42-4ac83152bf86",
      },
    },
    {
      attributes: {
        id: 11,
        price: "54.73 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736290/Lorraine_c3nqeb.jpg",
        reference: "Lorraine",
        slug: "cc310411-efb8-47f0-b9e7-b3473acae733",
      },
    },
    {
      attributes: {
        id: 11,
        price: "85.39 €",
        thumb_type: "regular",
        cover_picture_url:
          "https://res.cloudinary.com/dlnlpzitl/image/upload/v1711736289/Aveline_ik7bt9.jpg",
        reference: "Aveline",
        slug: "ef61b433-109d-47c2-ad03-d97da098a460",
      },
    },
  ];
  return (
    <GenericLayout title="Adm Mobilier : Promotions Terrasse" noHeader>
      <Hero
        className="mt-[48px] mx-[20px]"
        titleH1={/* HTML */ `Promotion TERRASSE`}
        titleH2="Offre spéciale : Mobilier de terrasse à prix réduit pour votre établissement"
        texts={
          /* HTML */ `
            <p>Équipez votre terrasse avec style sans dépasser votre budget.</p>
            <p>
              Profitez de nos <span>tarifs exceptionnels</span> sur une
              sélection de mobilier, idéale pour les restaurateurs, cafetiers et
              hôteliers souhaitant offrir un espace extérieur accueillant à
              leurs clients.
            </p>
            <p>
              Les avantages de cette offre ? Des
              <span>produits à prix réduits</span> avec une
              <span>disponibilité immédiate</span> !
            </p>
          `
        }
        heroImage={heroImagee}
        // cta={{
        //   label: "Découvrir les produits",
        //   href: "#promotion-terrasse",
        // }}
      />
      <div className="mt-[64px] md:mt-[120px] px-[20px] grid grid-cols-12">
        <div className="col-span-full xl:col-start-2 xl:col-end-12">
          <Block5 images={[image1, image2, image3]}>
            <h2 className="my-h2">
              Créez votre terrasse idéale en mixant nos offres exceptionnelles
            </h2>
            <div className="mt-6 paragraph">
              <p>
                Découvrez nos offres sur une <span>sélection exclusive</span> de
                produits pour terrasse. Avec des <span>stocks limités</span>,
                chaque produit en promotion est une occasion à ne pas manquer.
              </p>
              <p>
                Avec notre large gamme, vous pouvez <span>mixer</span> et{" "}
                <span>assortir</span> différents éléments pour créer une
                terrasse unique qui correspond parfaitement à vos besoins et à
                votre style.
              </p>
              <p>
                Explorez nos offres dès maintenant et laissez libre cours à
                votre créativité pour{" "}
                <span>transformer votre espace extérieur</span>.
              </p>
            </div>
          </Block5>
        </div>
      </div>
      <div className="mx-[20px] px-[20px] mt-[64px] md:mt-[120px] bg-[#4B4F40] py-[64px] md:py-[88px] grid grid-cols-12 rounded-xl">
        <h2 className="col-start-2 xl:col-start-3 col-end-12 xl:col-end-11 font-playfair font-bold text-[24px] md:text-[32px] text-center text-white">
          Découvrez toutes nos promotions terrasse en téléchargeant notre
          brochure.
        </h2>
        <div className="col-span-full flex justify-center mt-8">
          <NewsletterInput isV2 isInPromoTerrase />
        </div>
      </div>
      <div
        id="promotion-terrasse"
        className="mt-[80px] md:mt-[120px] px-[20px] grid-cols-12 grid"
      >
        <div className="col-span-full xl:col-start-2 xl:col-end-12">
          <h2 className="my-h2">
            ADM vous présente ses offres spéciales pour <br />{" "}
            l&apos;aménagement de votre terrasse
          </h2>
          {products && products.length > 0 && (
            <ProductGridV2
              className="mt-6"
              products={products || []}
              cta={{
                label: "Voir tous les produits",
                href: "/mobiliers-accessoires",
              }}
            />
          )}
        </div>
      </div>
      <div className="mt-[64px] md:mt-[120px]">
        <BLockContactV2
          contactImage={contact}
          titleH2="Envie d'en savoir plus sur nos offres de terrasse ?"
          texts={
            /* HTML */ `<p class="!text-white">
                Contactez-nous dès maintenant pour obtenir plus d'informations
                sur notre sélection de mobilier pour terrasses.
              </p>
              <p class="!text-white">
                Que vous cherchiez à renouveler votre mobilier extérieur ou à
                équiper une nouvelle terrasse, notre équipe est là pour vous
                conseiller et vous aider à trouver les meilleures options pour
                vos besoins.
              </p>`
          }
        />
      </div>
    </GenericLayout>
  );
}
