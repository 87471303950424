import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";

const Header = ({ h1, breadCrumbs = [] }) => {
  const location = useLocation();
  return (
    <div className="flex flex-col items-center pt-[32px] pb-[32px] lg:py-[64px]">
      {h1 && (
        <h1 className="my-h1 font-playfair font-normal text-xl sm:text-5xl tracking-[2.56px] uppercase text-center my-4">
          {h1}
        </h1>
      )}
      <div className="flex">
        {breadCrumbs.map((item, index) => (
          <span
            key={index}
            className="flex items-center font-avenir-text text-12"
          >
            <a
              href={item.url}
              className={classNames("hover:underline", {
                underline: location.pathname === item.url,
              })}
            >
              {item.label}
            </a>
            {index < breadCrumbs.length - 1 && (
              <span className="mx-[4px]">/</span>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Header;
