export const translateGroupName = (group_name) => {
  switch (group_name) {
    case "tables":
      return "tables";
    case "seats":
      return "Sièges";
    case "complements":
      return "Compléments";
    default:
      return "Catégory inconnue";
  }
};
