/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import GenericLayout from "../layouts/GenericLayout";
import ProductGrid from "../components/products/ProductGrid";
import FilterProduit from "../components/filter-produit";
import {
  InstantSearch,
  useInstantSearch,
  useSearchBox,
} from "react-instantsearch";
import { useParams } from "react-router-dom";
import PaginatedItems from "../components/pagination/Pagination";
import classNames from "classnames";
import { indexName, searchClient } from "../../packs";

const ResultsSearchPage = () => {
  const params = useParams();
  const { refine, isSearchStalled } = useSearchBox();
  const instantsearch = useInstantSearch();

  useEffect(() => {
    if (params?.query_url) {
      refine(params?.query_url?.trim());
    }
  }, [params?.query_url]);

  return (
    <GenericLayout
      title="Mobilier et Accessoires pour restaurants, bars, brasserie...|Adm Mobilier"
      h1="Mobilier de restaurant – Bar – Brasserie "
      breadCrumbs={[
        { label: "Adm-mobilier", link: "/" },
        {
          label: "Mobilier et Accessoires",
          link: "/mobiliers-accessoires",
        },
      ]}
    >
      <div className="sm:mx-4">
        <FilterProduit isSearchMode isV2 />
        <div className="lg:container mx-auto my-3">
          {instantsearch.results?.query === undefined && (
            <div>Veuillez renseigner une requête.</div>
          )}
          {instantsearch.results?.query && (
            <div>
              Le résultat de la recherche :{" "}
              <i>&apos;{instantsearch.results.query}&apos;</i>{" "}
            </div>
          )}
        </div>
        <ProductGrid
          products={isSearchStalled ? [] : instantsearch.results?.hits || []}
          loading={isSearchStalled}
        />
        <div
          className={classNames("flex justify-center mt-12 mb-8", {
            "!hidden": !instantsearch.results?.hits?.length,
          })}
        >
          <PaginatedItems />
        </div>
      </div>
    </GenericLayout>
  );
};

const ResultsSearchPageWithInstantSearch = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <ResultsSearchPage />
    </InstantSearch>
  );
};

export default ResultsSearchPageWithInstantSearch;
