import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import image_404 from "../../../assets/images/404.svg";
import MyButton from "../components/my-button/MyButton";

const NotFoundPage = () => {
  return (
    <GenericLayout title="404 Not Found" noHeader>
      <div className="flex justify-center px-5">
        <div className="py-[92px] 2xl:py-[121px] flex flex-col items-center gap-y-10 md:gap-y-12 2xl:gap-y-16">
          <div className="flex-col flex gap-y-2 items-center">
            <h3 className="font-playfair text-[16px] md:text-[20px]">erreur</h3>
            <img
              src={image_404}
              alt="404 ADM"
              className="w-[250px] md:w-[318px] h-auto"
            />
          </div>
          <div className="flex flex-col items-center gap-y-6 md:gap-y-8">
            <p className="font-montserrat text-[14px] md:text-[16px] text-center">
              La page que vous recherchez n’existe pas ou est indisponible.
            </p>
            <MyButton href="/" label="Retour à l'accueil" />
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default NotFoundPage;
