import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { uniqueId } from "lodash";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { clearSearch } from "../../../../javascript/src/redux/slices/products";

const menus = [
  {
    name: "Show-room",
    url: "/services/showroom",
  },
  {
    name: "Entrepôt / Stockage / Atelier",
    url: "/services/entrepot-stockage-atelier",
  },
  {
    name: "Livraisons - Montage",
    url: "/services/livraison-montage",
  },
  {
    name: "Eco-responsabilité",
    url: "/services/eco-responsabilite",
  },
];

const SousMenuService = ({ label }) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const dispatch = useDispatch();

  const clearSearchFunction = () => {
    dispatch(clearSearch());
  };

  if (!isMobile) {
    return (
      <>
        <Popover className="hidden lg:block p-2 relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? "text-kakhi-400" : "text-kakhi-500",
                  "group rounded inline-flex items-center text-base hover:text-kakhi-600 px-2 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-kakhi-500 font-brandon"
                )}
              >
                <span>{label}</span>
                {open ? (
                  <ChevronUpIcon
                    className={classNames(
                      open ? "text-kakhi-600" : "text-kakhi-400",
                      "ml-2 h-5 w-5 group-hover:text-kakhi-500 transition ease-in-out duration-150 font-brandon"
                    )}
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-kakhi-600" : "text-kakhi-400",
                      "ml-2 h-5 w-5 group-hover:text-kakhi-500 transition ease-in-out duration-150 font-brandon"
                    )}
                    aria-hidden="true"
                  />
                )}
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 mt-3 max-w-sm  left-1">
                  <div className="flex flex-col rounded-md bg-white p-2 shadow-md">
                    {menus.map(({ name, url }) => (
                      <a
                        onClick={() => clearSearchFunction()}
                        className="whitespace-nowrap block text-sm hover:bg-gray-50 py-2 px-4 rounded-md"
                        key={uniqueId("menu_item")}
                        href={url}
                      >
                        {name}
                      </a>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </>
    );
  }
  return (
    <div className="block lg:hidden mt-3 px-2 space-y-1">
      {menus.map(({ name, url }) => {
        return (
          <a
            onClick={() => {
              clearSearchFunction();
            }}
            key={uniqueId("service_mobile")}
            href={url}
            className={classNames(
              url === pathname
                ? "bg-kakhi-100 text-kakhi-500"
                : "text-kakhi-500 hover:bg-kakhi-50 hover:text-kakhi-500",
              "block rounded py-2 px-3 text-base font-medium font-grotesque"
            )}
          >
            {name}
          </a>
        );
      })}
    </div>
  );
};

export default SousMenuService;
