import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Api, { API_V1_BASE_URL } from "../_helpers/api";
import { Link } from "react-router-dom";

const PopoverLink = ({ title, path }) => {
  const [categories, setCategories] = useState({
    seats: [],
    tables: [],
    complements: [],
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const responses = await Promise.all([
          Api.get(
            `${API_V1_BASE_URL}/categories/get-category-name-list-by-seats-group`
          ),
          Api.get(
            `${API_V1_BASE_URL}/categories/get-category-name-list-by-tables-group`
          ),
          Api.get(
            `${API_V1_BASE_URL}/categories/get-category-name-list-by-complements-group`
          ),
        ]);
        setCategories({
          seats: responses[0].data.data.map((item) => item.attributes),
          tables: responses[1].data.data.map((item) => item.attributes),
          complements: responses[2].data.data.map((item) => item.attributes),
        });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    };

    fetchCategories();
  }, []);

  const categoryPanel = (groupData, categoryData, close) => (
    <div>
      <Disclosure.Button
        onClick={() => close()}
        to={groupData.url}
        as={Link}
        href={"/group/seats"}
        className="block rounded text-base px-4 pb-3 pt-6 sm:p-0 font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
      >
        {groupData.title}
      </Disclosure.Button>
      {categoryData.map(({ id, name, slug }) => (
        <Disclosure.Button
          key={id}
          as={Link}
          onClick={close}
          to={`/category/${slug}`}
          className="px-4 py-3 sm:p-2 flex items-start rounded hover:bg-gray-50 transition ease-in-out duration-150"
        >
          <span className="text-sm text-kakhi-500 first-letter:capitalize">
            {name}
          </span>
        </Disclosure.Button>
      ))}
    </div>
  );

  return (
    <>
      <Popover className="hidden lg:block p-2 relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? "text-kakhi-600" : "text-kakhi-500",
                "group rounded inline-flex items-center text-base hover:text-kakhi-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-kakhi-500 font-brandon"
              )}
            >
              <span className="capitalize">{title}</span>
              {open ? (
                <ChevronUpIcon className="ml-2 h-5 w-5" />
              ) : (
                <ChevronDownIcon className="ml-2 h-5 w-5" />
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 mt-3 px-2 w-max xl:w-screen max-w-md sm:px-0 lg:max-w-3xl left-1">
                {() => (
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-3">
                      {categoryPanel(
                        { title: "Tables", url: "/group/tables" },
                        categories.tables,
                        close
                      )}
                      {categoryPanel(
                        { title: "Sièges", url: "/group/seats" },
                        categories.seats,
                        close
                      )}
                      {categoryPanel(
                        { title: "Compléments", url: "/group/complements" },
                        categories.complements,
                        close
                      )}
                    </div>
                    <div className="p-5 bg-gray-50 sm:p-8">
                      <a
                        href={"/mobiliers-accessoires"}
                        className="-m-3 p-3 flow-root rounded hover:bg-gray-100 transition ease-in-out duration-150"
                      >
                        <div className="flex items-center">
                          <span className="text-base font-medium text-gray-900">
                            Tous les produits mobiliers et accessoires
                          </span>
                        </div>
                        <span className="mt-1 block text-sm text-gray-500">
                          Decouvez tous nos produits
                        </span>
                      </a>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <div className="block lg:hidden mt-3 px-2 space-y-1">
        <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-3">
              {categoryPanel(
                { title: "Tables", url: "/group/tables" },
                categories.tables,
                close
              )}
              {categoryPanel(
                { title: "Sièges", url: "/group/seats" },
                categories.seats,
                close
              )}
              {categoryPanel(
                { title: "Compléments", url: "/group/complements" },
                categories.complements,
                close
              )}
            </div>
            <div className="p-5 bg-gray-50 sm:p-8">
              <a
                href={"/mobiliers-accessoires"}
                className="-m-3 p-3 flow-root rounded hover:bg-gray-100 transition ease-in-out duration-150"
              >
                <div className="flex items-center">
                  <span className="text-base font-medium text-gray-900">
                    Tous les produits mobiliers et accessoires
                  </span>
                </div>
                <span className="mt-1 block text-sm text-gray-500">
                  Decouvez tous nos produits
                </span>
              </a>
            </div>
          </div>
        </Disclosure.Panel>
      </div>
    </>
  );
};

export default PopoverLink;
