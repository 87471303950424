import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "../../packs/styles/productPage.css";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

import Api, { API_V1_BASE_URL } from "../_helpers/api";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { isLarge, isRegular } from "../pages/CategoryPage";
import SlideNavigation from "./utils/Swiper";

const FamilyProductsSlider = ({ familyId }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch products from API and set them in state. Handle correctly the unmounting of the component to avoid memory leaks.
    // handle No catch method for promise. This may result in an unhandled promise rejection.
    let isMounted = true;

    Api.get(`${API_V1_BASE_URL}/families/${familyId}/products`)
      .then(({ data }) => {
        if (isMounted) {
          setProducts(data.data);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.log(error);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [familyId]);

  if (products.length === 0) return null;

  return (
    <div>
      <div>
        <div>
          <h2 className="mt-12 sm:mb-0 font-freight sm:text-40 text-lg leading-none font-bold">
            Pour aller plus loin
          </h2>
          <p className="text-kakhi font-grotesque text-sm lg:text-20 mb-4 lg:mb-8">
            Nous vous proposons d&apos;autres produits de la même famille.
          </p>
        </div>
      </div>
      <div className="w-full">
        <Swiper
          breakpoints={{
            240: {
              slidesPerView: "auto",
              spaceBetween: 16,
            },
            981: {
              slidesPerView: "auto",
              spaceBetween: 24,
            },
            1777: {
              slidesPerView: "auto",
              spaceBetween: 32,
            },
          }}
          modules={[Navigation]}
        >
          {!!products.length &&
            products.map(
              ({
                attributes: {
                  id,
                  cover_picture_url,
                  reference,
                  slug,
                  thumb_type,
                },
              }) => (
                <SwiperSlide
                  key={uniqueId(`${id}`)}
                  className={classNames(
                    { "w-24": isRegular(thumb_type) },
                    { "w-52": isLarge(thumb_type) },
                    "rounded sm:leading-normal leading-tight sm:m-0 mb-8 h-auto hover:underline w-fit"
                  )}
                  as={"a"}
                >
                  <a
                    href={`/mobiliers-accessoires/${slug}`}
                    className="image-similar-produit-container"
                  >
                    <img
                      className="rounded image-similar-produit h-52 md:h-52 lg:h-80"
                      src={cover_picture_url}
                      alt={reference}
                    />
                    <p className="font-freight text-base md:text-lg sm:text-2xl break-words">
                      {reference}
                    </p>
                  </a>
                </SwiperSlide>
              )
            )}
          {products.length > 4 && (
            <div className="flex justify-end">
              <SlideNavigation />
            </div>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default FamilyProductsSlider;
