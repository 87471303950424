import { combineReducers } from "@reduxjs/toolkit";
import productsReducer from "./slices/products";
import filterReducer from "./slices/filter";
import tagsReducer from "./slices/tag";
import categoryReducer from "./slices/category";
import realisationReducer from "./slices/realisation";

// import storage from "redux-persist/lib/storage";
// import { persistReducer } from "redux-persist";

// export const persistConfigAuth = {
//   key: "products",
//   storage: storage,
// };

export const combinedReducer = combineReducers({
  products: productsReducer,
  filters: filterReducer,
  tags: tagsReducer,
  categories: categoryReducer,
  realizations: realisationReducer,
});
