import classNames from "classnames";
import ProductCard from "./ProductCard";
import _ from "lodash";
import React from "react";

const ProductGrid = ({ products, loading }) => {
  return (
    <>
      {loading && (
        <div className="my-6 flex w-full justify-center text-lg sm:text-xl font-brandon">
          Chargement des produits en cours...
        </div>
      )}
      <div
        className={classNames(
          "my-10 gap-8 grid sm:grid-cols-4 mx-auto max-w-screen-2xl grid-cols-1",
          { "!hidden": loading }
        )}
      >
        {products.map(
          ({
            name,
            id,
            thumb_type,
            cover_picture_url,
            reference,
            slug,
            price,
          }) => {
            return (
              <ProductCard
                key={_.uniqueId(`product_${id}`)}
                name={name}
                id={id}
                price={price}
                thumb_type={thumb_type}
                cover_picture_url={cover_picture_url}
                reference={reference}
                slug={slug}
              />
            );
          }
        )}
      </div>
    </>
  );
};

export default ProductGrid;
