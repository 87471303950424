import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../_helpers/api";
import { searchProductsSlice } from "./products";

const initialState = {
  category_id: null,
  category_name: "",
  categories: [],
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    SET_CATEGORY_NAME(state, action) {
      state.category_name = action.payload;
    },
    SET_CATEGORIES(state, action) {
      state.categories = action.payload;
    },
    SET_IS_FILTERED_MODE(state, action) {
      state.isFilteredMode = action.payload;
    },
  },
});

export const categorySliceActions = categorySlice.actions;

export const getCategoryName = createAsyncThunk(
  "get_category_name",
  async (category_id, { dispatch }) => {
    const {
      data: {
        data: {
          attributes: { name, group_id },
        },
      },
    } = await Api.get(`/api/v1/categories/${category_id}/name`);
    dispatch(categorySlice.actions.SET_CATEGORY_NAME(name));
    dispatch(searchProductsSlice.actions.SET_GROUP_ID(group_id));
    return name;
  }
);

export const getCategoriesByGroupId = createAsyncThunk(
  "get_categories_by_group_id",
  async (group_id, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(`/api/v1/group/${group_id}/categories`);
    dispatch(
      categorySlice.actions.SET_CATEGORIES(data.map((item) => item.attributes))
    );
  }
);
export const getCategories = createAsyncThunk(
  "get_categories",
  async (_, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(`/api/v1/categories/names`);
    dispatch(
      categorySlice.actions.SET_CATEGORIES(data.map((item) => item.attributes))
    );
  }
);
export const getProductsByCategoryIds = createAsyncThunk(
  "get_products_by_category_ids",
  async (category_ids, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(
      `/api/v1/categories?category_ids=${category_ids}/products`
    );
    dispatch(
      categorySlice.actions.SET_CATEGORIES(data.map((item) => item.attributes))
    );
  }
);

export default categorySlice.reducer;
