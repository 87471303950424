import React from "react";
import { ContactForm } from "../../pages/Contact";

export default function BLockContactV2({ titleH2, texts, contactImage }) {
  return (
    <div className="mx-5 grid grid-cols-12 overflow-hidden rounded-md bg-[#4B4F40] items-start">
      <div className="h-[300px] col-span-full md:col-start-1 md:col-end-6 md:h-full">
        <img
          src={contactImage}
          className="w-full h-full object-center object-cover"
          alt="contactV2Img.jpg"
        />
      </div>
      <div className="v2-form col-span-full md:col-start-7 md:col-end-12 py-[48px] 2xl:pt-[64px] px-[20px] md:px-0">
        <h2 className="my-h2 !text-white">{titleH2}</h2>
        <div
          className="paragraph mt-[16px] 2xl:mt-[32px]"
          dangerouslySetInnerHTML={{ __html: texts }}
        ></div>
        <div className="mt-[24px] 2xl:mt-[32px]">
          <ContactForm isV2 />
        </div>
      </div>
    </div>
  );
}
