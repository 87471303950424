import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import MyButton from "../components/my-button/MyButton";
import inspirationImg from "../../../assets/images/home/inspiration.png";
import admImg from "../../../assets/images/home/adm.png";
import arrow from "../../../assets/images/arrow.png";
import produitImg from "../../../assets/images/home/produit.png";
import refimg from "../../../assets/images/home/reference.png";
import { HeroHomepage } from "../components/hero-homepage/HeroHomepage";

// services images
import img1 from "../../../assets/images/home/services/img1.jpg";
import img2 from "../../../assets/images/home/services/img2.jpg";
import img3 from "../../../assets/images/home/services/img3.jpg";
import img4 from "../../../assets/images/home/services/img4.jpg";

// logos
import logo1 from "../../../assets/images/home/logos/img1.svg";
import logo2 from "../../../assets/images/home/logos/img2.svg";
import logo3 from "../../../assets/images/home/logos/img3.svg";
import logo4 from "../../../assets/images/home/logos/img4.svg";
import logo5 from "../../../assets/images/home/logos/img5.svg";
import logo6 from "../../../assets/images/home/logos/img6.svg";
import logo7 from "../../../assets/images/home/logos/img7.png";
import logo8 from "../../../assets/images/home/logos/img8.svg";
import { uniqueId } from "lodash";
import classNames from "classnames";

export const ImageInLeft = ({
  image,
  title1,
  title2,
  paragraph,
  btnLabel,
  href,
  children,
  className,
  classNameImage = "w-full h-auto",
}) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-12 bg-my-beige py-[32px] sm:py-[80px] xl:py-[112px] 2xl:py-[120px] px-[20px] lg:px-0",
        className
      )}
    >
      <div className="col-span-full lg:col-start-2 lg:col-end-7">
        <div className="w-full h-auto overflow-hidden rounded-xl">
          <img className={classNameImage} src={image} alt={title2} />
        </div>
      </div>
      <div className="col-span-full lg:col-start-8 lg:col-end-12">
        {children ? (
          children
        ) : (
          <>
            <div className="flex mt-[32px] sm:mt-[40px] xl:mt-[64px] 2xl:mt-[80px]">
              <ol role="list" className="flex items-center">
                <li>
                  <div className="flex items-center">
                    <p className="text-14 2xl:text-20 font-medium text-kakhi font-playfair uppercase">
                      {title1}
                    </p>
                  </div>
                </li>
              </ol>
            </div>
            <h2 className="font-kakhi font-bold font-playfair text-20 2xl:text-32 mt-[32px] sm:mt-[40px] xl:mt-[64px] 2xl:mt-[80px]">
              {title2}
            </h2>
            <p className="font-avenir-text text-kakhi-500 text-avenir-14 2xl:text-avenir-16 mt-[16px] sm:mt-[24px] xl:mt-[32px] 2xl:mt-[40px]">
              {paragraph}
            </p>
            <div className="mt-[16px] sm:mt-[24px] xl:mt-[32px] 2xl:mt-[40px] flex">
              <MyButton href={href} label={btnLabel} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ArrowHover = () => {
  return (
    <div className="arrow-animation flex justify-start overflow-hidden w-[42px] arrow-container">
      <img
        className="min-w-[42px] lg:min-w-[80px] h-auto"
        src={arrow}
        alt="Arrow"
      />
    </div>
  );
};

export const ServiceItem = ({ title, img, text, href }) => {
  return (
    <a href={href} className="block service-item-home mt-[32px] 2xl:mt-[50px]">
      <div className="h-auto w-full service-item-image-home overflow-hidden rounded-xl">
        <img src={img} className="w-full h-auto" />
      </div>
      <div className="w-full mt-[8px]">
        <h3 className="font-playfair font-medium text-14 lg:text-16 2xl:text-20 flex items-center gap-x-[8px] lg:gap-x-[16px]">
          <span className="block">{title}</span>
          <span className="flex overflow-hidden line-container w-[42px]">
            <img
              src={arrow}
              className="min-w-[42px] lg:min-w-[64px] h-auto"
              alt="arrow"
            />
          </span>
        </h3>
        <p className="font-avenir-text text-avenir-14 2xl:text-avenir-16 mt-[4px] lg:mt-[8px]">
          {text}
        </p>
      </div>
    </a>
  );
};

export const BlockServices = () => {
  return (
    <div className="py-[64px] lg:py-[88px] 2xl:py-[120px] px-[20px]">
      <h2 className="font-playfair  font-bold text-20 lg:text-24 2xl:text-32 lg:mb-[-32px] 2xl:mb-[-50px]">
        Nos services
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-x-[16px] lg:mt-[8px]">
        {[
          {
            title: "Show-room",
            text: "Découvrez notre Showroom, un espace où vous pouvez recevoir des conseils experts et visualiser nos produits de manière concrète.",
            img: img1,
            href: "/services/showroom",
          },
          {
            title: "Entrepôt - Stockage - Atelier",
            text: "Espace de stockage stratégique pour livraisons rapides, répondant à vos projets d'aménagement avec efficacité.",
            img: img2,
            href: "/services/entrepot-stockage-atelier",
          },
          {
            title: "Livraisons - Montage",
            text: "Livraison experte, délais fiables, montage efficace : votre satisfaction, notre priorité pour chaque projet.",
            img: img3,
            href: "/services/livraison-montage",
          },
          {
            title: "Écoresponsabilité",
            text: "Engagement éco-responsable : choix de matériaux durables, recyclage, partenaires respectueux de l'environnement.",
            img: img4,
            href: "/services/eco-responsabilite",
          },
        ].map(({ title, img, text, href }, i) => (
          <ServiceItem
            title={title}
            img={img}
            text={text}
            key={`services_${i}`}
            href={href}
          />
        ))}
      </div>
    </div>
  );
};

export const ProduitAndReference = () => {
  return (
    <div className="grid grid-cols-12 gap-x-[16px] py-[64px] lg:py-[120px]">
      {[
        {
          title: "Nos produits",
          img: produitImg,
          href: "/mobiliers-accessoires",
        },
        { title: "Nos références", img: refimg, href: "/references" },
      ].map(({ title, img, href }, i) => (
        <a
          href={href}
          key={i}
          className="col-span-full lg:col-span-6 block arrow-el-wrapper first:mt-0 mt-[64px] lg:mt-0"
        >
          <h2 className="font-playfair  font-bold text-20 lg:text-24 2xl:text-32 flex items-center gap-x-[8px] lg:gap-x-[16px]">
            <span>{title}</span>
            <ArrowHover />
          </h2>
          <div className="w-full h-auto mt-[8px] lg:mt-[12px] overflow-hidden rounded-xl">
            <img
              src={img}
              alt={title}
              className="w-full image-reference-produit h-auto object-center object-cover"
            />
          </div>
        </a>
      ))}
    </div>
  );
};

export const LogosContainer = () => {
  return (
    <div className="container mx-auto py-[64px] lg:py-[120px] px-[20px]">
      <h2 className="font-playfair font-bold text-20 lg:text-24 2xl:text-32">
        Ils nous font confiance
      </h2>
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-y-[40px] lg:gap-y-[64px] xl:gap-y-[80px] mt-[40px] 2xl:mt-[56px] items-center">
        {[logo7, logo1, logo2, logo3, logo4, logo8, logo5, logo6].map(
          (img, i) => (
            <div key={uniqueId(i)} className="col-span-1">
              <img src={img} className="" alt="logo" />
            </div>
          )
        )}
      </div>
    </div>
  );
};

const Homepage = () => {
  return (
    <GenericLayout
      title="Adm Mobilier : Spécialiste du mobilier de restaurants, bars, brasserie..."
      noHeader
    >
      <HeroHomepage />
      <div className="px-5 home-produit-reference">
        <ProduitAndReference />
      </div>
      <div className="home-inspiration lg:px-5">
        <ImageInLeft
          image={inspirationImg}
          title1="Inspiration"
          title2="Explorez notre univers et ses possibilités"
          paragraph="Découvrez notre sélection de plaquettes, mettant en avant notre mobilier de qualité, les créations de nos partenaires fournisseurs, et nos nuanciers pour la personnalisation de vos projets. Explorez l'élégance et l'authenticité à travers notre collection, offrant un aperçu de ce que propose ADM Mobilier."
          href={"/nos-inspirations"}
          btnLabel="Découvrir les possibilités offertes par ADM"
        />
      </div>
      <BlockServices />
      <div className="home-adm lg:px-5">
        <ImageInLeft
          image={admImg}
          title1="Depuis 1992"
          title2="ADM"
          btnLabel="Découvrir notre histoire"
          href={"/presentation"}
          paragraph="Depuis 1992, ADM Mobilier est votre partenaire privilégié pour l'aménagement d'espaces de restauration à Roncq (59) et dans la région Nord-Pas de Calais. Notre engagement envers la qualité et l'innovation guide notre entreprise depuis ses débuts. Au fil des années, nous avons élargi nos horizons pour répondre aux besoins de nos clients."
        />
      </div>
      <LogosContainer />
    </GenericLayout>
  );
};

export default Homepage;
