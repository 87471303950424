import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const createMaterial = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/materials`,
    { material: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    // .then(category => uploadFile(blobSignedID, category))
    .then(callback);
};

export const updateMaterial = (id, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/materials/${id}`,
    { material: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const deleteMaterial = (id, callback) => {
  Api.delete(`${API_V1_BASE_URL}/materials/${id}`).then(callback);
};

export const useMaterials = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/materials`, getFetcher);

  return {
    materials: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useMaterialsOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_materials_options`,
    getFetcher
  );
  return {
    materials: data,
    isLoading: !error && !data,
    isError: error,
  };
};
