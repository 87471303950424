import axios from "axios";

export const API_V1_BASE_URL = "/api/v1";
let config = {
  baseURL: process.env.BASE_URL,
  headers: {
    Accept: "application/json",
  },
};

let Api = axios.create(config);

export default Api;
