import React from "react";
import { useParams } from "react-router-dom";
import { useContact } from "../../../_services/contact-services";

const AdminContactShowPage = () => {
  // const navigate = useNavigate();
  let { contact_id } = useParams();
  const { contact, isLoading, isError } = useContact(contact_id);

  if (isLoading) return <div>chargement... </div>;
  if (isError) return <div>Erreur dans le chargement de la page </div>;
  const {
    firstname,
    lastname,
    company,
    phone_number,
    email,
    message,
    address,
  } = contact.data.attributes;
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded mt-12">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Fiche de contact
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Information issue du formulaire de contact
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Nom complet</dt>
            <dd className="mt-1 text-sm text-gray-900">{`${firstname} ${lastname}`}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Entreprise</dt>
            <dd className="mt-1 text-sm text-gray-900">{company}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900">{email}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Numero de telephone
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{phone_number}</dd>
          </div>
          {address && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Adresse</dt>
              <dd className="mt-1 text-sm text-gray-900">{address}</dd>
            </div>
          )}
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Comment pouvons-nous vous aider ?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{message}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default AdminContactShowPage;
