import React, { useEffect, useState } from "react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { searchProductAdmin } from "../../../redux/slices/products";
import { useDispatch } from "react-redux";

export default function AdminSearchBar() {
  const [query, setQuery] = useState("");

  const dispatch = useDispatch();

  // Utilisez lodash pour créer une fonction de recherche debounce
  const debouncedSearch = _.debounce((searchQuery) => {
    dispatch(searchProductAdmin(searchQuery));
  }, 400);

  const handleOnChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);

    // Appelez la fonction de recherche debounce avec la nouvelle valeur de requête
    debouncedSearch(newQuery);
  };

  // useEffect(() => {
  //     // La recherche sera effectuée après que l'utilisateur ait arrêté de taper pendant 400 ms
  // }, [query]);
  return (
    <div className="relative rounded-md shadow-sm w-full">
      <input
        type="text"
        name="searchbar-admin"
        onChange={(e) => handleOnChange(e)}
        id="searchbar-admin"
        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="rechercher par reference"
      />
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <MagnifyingGlassCircleIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
