import React, { useRef } from "react";
import Select from "react-select";
import Error from "../Error";
import Loading from "../utils/Loading";
import { useUniversesOptions } from "../../_services/universe-services";
import {
  useReferences,
  useReferencesOptions,
} from "../../_services/reference-services";

const SelectReferences = ({ selectedOptions, setSelectedOptions }) => {
  const { options, isError, isLoading } = useReferencesOptions();

  const selectRef = useRef(null);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error error={isError} />;
  }
  return (
    <div>
      <Select
        ref={selectRef}
        onChange={setSelectedOptions}
        options={options.data}
        isMulti
        getOptionLabel={(option) => `${option.attributes.name}`}
        getOptionValue={(option) => `${option.attributes.id}`}
        value={selectedOptions}
      />
    </div>
  );
};

export default SelectReferences;
