/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../_helpers/api";
import { categorySlice } from "./category";

const initialState = {
  products: [],
  loading: null,
  error: null,
  category: null,
  category_slug: null,
  nbPage: 0,
  isSearch: false,
  query: null,
  paginationRequestedPageNumber: 0,
  category_name: null,
  group_id: null,
  isFilteredMode: false,
};

export const searchProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    SET_PRODUCTS(state, action) {
      state.products = action.payload;
    },
    SET_PRODUCTS_TOTAL_NUMBER(state, action) {
      state.products_total_number = action.payload;
    },
    SET_LOADING(state, action) {
      state.loading = action.payload;
    },
    SET_ERROR(state, action) {
      state.error = action.payload;
    },
    SET_CATEGORY(state, action) {
      state.category = action.payload;
    },
    SET_CATEGORIES(state, action) {
      state.categories = action.payload;
    },
    SET_CATEGORY_SLUG(state, action) {
      state.category_slug = action.payload;
    },
    SET_CATEGORY_ID(state, action) {
      state.category_id = action.payload;
    },
    SET_CATEGORY_NAME(state, action) {
      state.category_name = action.payload;
    },
    SET_GROUP_ID(state, action) {
      state.group_id = action.payload;
    },
    SET_NB_PAGE(state, action) {
      state.nbPage = action.payload;
    },
    SET_PAGINATION_DATA(state, action) {
      state.pagination_data = action.payload;
    },
    SET_IS_SEARCH(state, action) {
      state.isSearch = action.payload;
    },
    SET_QUERY(state, action) {
      state.query = action.payload;
    },
    SET_IS_FILTERED_MODE(state, action) {
      state.isFilteredMode = action.payload;
    },
    SET_PAGINATION_REQUESTED_PAGE_NUMBER(state, action) {
      state.paginationRequestedPageNumber = action.payload;
    },
  },
});

export const searchProductsSliceAction = searchProductsSlice.actions;

export const clearSearch = createAsyncThunk(
  "clear_search",
  async (_, { dispatch }) => {
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
    dispatch(searchProductsSlice.actions.SET_QUERY(""));
  }
);

export const getProductNumber = createAsyncThunk(
  "products_total_number",
  async (_, { dispatch }) => {
    const {
      data: { count },
    } = await Api.get("/api/v1/count-products");
    dispatch(searchProductsSlice.actions.SET_PRODUCTS_TOTAL_NUMBER(count));
  }
);

export const searchProducts = createAsyncThunk(
  "search_produit",
  async ({ query, paginationRequestedPageNumber = 1 }, { dispatch }) => {
    dispatch(searchProductsSlice.actions.SET_LOADING(true));
    dispatch(searchProductsSlice.actions.SET_QUERY(query));

    if (query && query.length) {
      const {
        data: {
          products: { data },
          category_ids: { data: categoriesData },
          pagy: { pages, ...rest },
        },
      } = await Api.post("/api/v1/searches/search_by_products", {
        query,
        page: paginationRequestedPageNumber,
        per_page: 20,
      });

      // console.log(categoriesData)
      // dispatch(categorySlice.actions.SET_CATEGORIES(categoriesData.map(item => item.attributes)))
      dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
      dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
      dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
      dispatch(searchProductsSlice.actions.SET_IS_SEARCH(true));
      return data;
    }
    return [];
  }
);

export const getAdminProductList = createAsyncThunk(
  "getProducts",
  async ({ page = 0, withIncluded = false }, { dispatch }) => {
    dispatch(searchProductsSlice.actions.SET_LOADING(true));
    const {
      data: { data, links, included },
      errors,
    } = await Api.get(
      `/api/v1/products?page=${page + 1}&with_included=${withIncluded}`
    );

    let _data = data;
    if (included.length > 0) {
      _data = _data.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          categories: included.filter(
            (el) =>
              el.type == "category" &&
              (item?.relationships?.categories?.data || [])
                .map((el2) => el2.id)
                .includes(el.id)
          ),
        },
      }));
    }
    if (errors) {
      dispatch(searchProductsSlice.actions.SET_ERROR(errors));
    }
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(_data));
    dispatch(searchProductsSlice.actions.SET_NB_PAGE(links.pages));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
  }
);

export const getProducts = createAsyncThunk(
  "getProducts",
  async (_, { dispatch }) => {
    dispatch(searchProductsSlice.actions.SET_LOADING(true));
    const {
      data: { data, links, included },
      errors,
    } = await Api.get(`/api/v1/products/listing`);

    let _data = data;
    if (errors) {
      dispatch(searchProductsSlice.actions.SET_ERROR(errors));
    }
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(_data));
    // dispatch(searchProductsSlice.actions.SET_NB_PAGE(links.pages));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
  }
);

export const searchProductAdmin = createAsyncThunk(
  "getProducts",
  async (query, { dispatch }) => {
    dispatch(searchProductsSlice.actions.SET_LOADING(true));
    console.log(query);

    const {
      data: { data, links, included },
      errors,
    } = await Api.post(`/api/v1/admin/products/search/`, {
      query: query,
    });

    let _data = data;
    if (errors) {
      dispatch(searchProductsSlice.actions.SET_ERROR(errors));
    }
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(_data));
    // dispatch(searchProductsSlice.actions.SET_NB_PAGE(links.pages));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
  }
);

export const getProductsList = createAsyncThunk(
  "getProductsList",
  async (paginationRequestedPageNumber = 1, { dispatch }) => {
    dispatch(searchProductsSlice.actions.SET_LOADING(true));
    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
      },
    } = await Api.get(
      `/api/v1/product-list?page=${paginationRequestedPageNumber}&per_page=20`
    );
    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
  }
);

export const getProductsByCategory = createAsyncThunk(
  "getProductsByCategory",
  async ({ category_id, paginationRequestedPageNumber = 1 }, { dispatch }) => {
    // dispatch(searchProductsSlice.actions.SET_LOADING(true));
    dispatch(searchProductsSlice.actions.SET_CATEGORY_ID(category_id));
    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
      },
    } = await Api.get(
      `/api/v1/category/${category_id}/products?page=${paginationRequestedPageNumber}&per_page=20`
    );

    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
  }
);
export const getProductsByGroupSlug = createAsyncThunk(
  "getProductsByGroupSlug",
  async ({ group_slug, paginationRequestedPageNumber = 1 }, { dispatch }) => {
    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
      },
    } = await Api.get(
      `/api/v1/groups/${group_slug}/products?page=${paginationRequestedPageNumber}&per_page=20`
    );

    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
  }
);

export const getProductsByGroup = createAsyncThunk(
  "getProductsByGroup",
  async ({ group_id, paginationRequestedPageNumber = 1 }, { dispatch }) => {
    // dispatch(searchProductsSlice.actions.SET_LOADING(true));
    // dispatch(searchProductsSlice.actions.SET_GROUP_ID(group_id));

    const {
      data: {
        products: { data },
        pagy: { pages, ...rest },
      },
    } = await Api.get(
      `/api/v1/category/${group_id}/product-list?page=${paginationRequestedPageNumber}&per_page=20`
    );

    dispatch(searchProductsSlice.actions.SET_NB_PAGE(pages));
    dispatch(searchProductsSlice.actions.SET_IS_FILTERED_MODE(true));
    dispatch(searchProductsSlice.actions.SET_LOADING(false));
    dispatch(searchProductsSlice.actions.SET_PAGINATION_DATA(rest));
    dispatch(searchProductsSlice.actions.SET_PRODUCTS(data));
    dispatch(searchProductsSlice.actions.SET_IS_SEARCH(false));
  }
);

export const getCategory = createAsyncThunk(
  "getCategoryBySlug",
  async (slug, { dispatch }) => {
    const {
      data: { data },
    } = await Api.get(`api/v1/categories/${slug}`);
    dispatch(searchProductsSlice.actions.SET_CATEGORY(data.attributes));
  }
);

// /group/:group_id/categories
// export const getCategoriesByGroup = createAsyncThunk(
//   "getCategoriesByGroup",
//   async (group_id, { dispatch }) => {
//     const {
//       data: { data },
//     } = await Api.get(`api/v1/group/${group_id}/categories`);

//     dispatch(searchProductsSlice.actions.SET_CATEGORIES(data));
//   }
// );

export const setGroupIdForGroupName = createAsyncThunk(
  "set_group_id_for_for_group_name",
  async ({ group_name }, { dispatch }) => {
    const {
      data: { group_id },
    } = await Api.get(`api/v1/groups/${group_name}`);
    dispatch(searchProductsSlice.actions.SET_GROUP_ID(group_id));
  }
);

// UNUSED
// export const setGroupIdForCategoryId = createAsyncThunk(
//   "set_group_id_for_category_id",
//   async ({ category_id }, { dispatch }) => {
//     const {
//       data: { group_id },
//     } = await Api.get(`api/v1/categories/${category_id}/group`);
//     dispatch(searchProductsSlice.actions.SET_GROUP_ID(group_id));
//   }
// );

export default searchProductsSlice.reducer;
