/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-undef */
import React, { useState } from "react";
import GenericLayout from "../layouts/GenericLayout";
import contact from "../../../assets/images/contact-hero.jpg";
import { useForm } from "react-hook-form";
import { createContact } from "../_services/contact-services";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import iconAdm from "../../../assets/images/adm-pin.svg";
import classNames from "classnames";
import { MyButtonNative } from "../components/my-button/MyButton";
import "../assets/styles/contact.css";

const ContactFormNoVerification = ({ isV2 = false }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    executeRecaptcha &&
      executeRecaptcha("submit")
        .then(() => {
          setLoading(true);
          createContact(data, () => {
            setLoading(false);
            setSuccess(true);
          });
        })
        .catch((e) => {
          console.log(e);
        });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames({ "space-y-8 divide-y divide-marron": !isV2 })}
      >
        <div className={classNames({ "xl:pt-16 md:pt-8": !isV2, "": isV2 })}>
          <div
            className={classNames("grid grid-cols-1 gap-x-8 sm:grid-cols-6", {
              "mt-6 gap-y-4": !isV2,
              "gap-y-6": isV2,
            })}
          >
            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Nom*
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  required
                  autoComplete="family-name"
                  {...register("lastname")}
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500 h-12"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Prénom*
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  required
                  autoComplete="given-name"
                  {...register("firstname")}
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500 h-12"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                E-mail*
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  required
                  type="email"
                  autoComplete="email"
                  {...register("email")}
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500 h-12"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="phone"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Téléphone
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="phone"
                  {...register("phone_number")}
                  id="phone"
                  autoComplete="tel"
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500 h-12"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="address"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Adresse*
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="address"
                  {...register("address")}
                  id="address"
                  autoComplete="address"
                  required
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500 h-12"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="nom-entreprise"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Nom de l&apos;entreprise*
              </label>
              <div className="mt-1">
                <input
                  id="nom-entreprise"
                  name="nom-entreprise"
                  type="text"
                  autoComplete=""
                  {...register("company")}
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500 h-12"
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="help"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Comment pouvons-nous vous aider ?
              </label>
              <div className="mt-1">
                <textarea
                  id="help"
                  name="help"
                  rows="5"
                  {...register("message")}
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 border-brown-200 rounded bg-skin-500"
                  placeholder=" 500 caractères"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="aboutus"
                className="block sm:text-24 text-14 font-grotesque text-kakhi"
              >
                Comment avez-vous entendu parler de nous ?
              </label>
              <div className="mt-1">
                <select
                  id="aboutus"
                  name="aboutus"
                  defaultValue={null}
                  {...register("how_did_you_know")}
                  className="my-input focus:ring-kakhi-500 focus:border-brown-500 block w-full sm:text-sm border-2 rounded border-brown-200 h-12"
                >
                  <option disabled className="sm:text-20 text-12">
                    Selectionnez dans la liste
                  </option>
                  <option className="text-16 !text-black" value={"salon"}>
                    Salon
                  </option>
                  <option className="text-16" value={"bouche_a_oreille"}>
                    Bouche à oreille
                  </option>
                  <option className="text-16" value={"publicite"}>
                    Publicité
                  </option>
                  <option className="text-16" value={"publicite"}>
                    Autre
                  </option>
                </select>
              </div>
            </div>

            <fieldset className="sm:col-span-6">
              <div className="flex">
                <div className="flex items-center h-5">
                  <input
                    id="consentement"
                    {...register("consent")}
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="focus:ring-kakhi h-4 w-4 text-kakhi border-brown rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="consentement"
                    className="sm:text-16 text-14 text-kakhi"
                  >
                    Oui, je donne mon consentement au traitement des données. *
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {!success ? (
          <div className={classNames({ "mt-6": isV2 })}>
            {isV2 ? (
              <MyButtonNative
                dark
                disabled={loading}
                label={loading ? "Envoi en cours..." : "Envoyer"}
                type="submit"
              />
            ) : (
              <button
                disabled={loading}
                type="submit"
                className="rounded contact-submit-button sm:mx-auto float-right text-right flex sm:py-4 py-3 sm:px-4 px-6 mt-6 sm:text-xl hover:text-white hover:bg-kakhi-600 focus:outline-none focus:ring-kakhi-600 focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed"
              >
                {loading && <div className="loader" />}
                <div>ENVOYER</div>
              </button>
            )}
          </div>
        ) : (
          <div
            id="success"
            className={classNames({
              "contact-alert-success": !isV2,
              "text-[14px] font-avenir-text text-green-400 mt-[16px]": isV2,
            })}
          >
            Votre demande est bien enregistrée.
          </div>
        )}
      </form>
    </>
  );
};

export const ContactForm = (props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
      <ContactFormNoVerification {...props} />
    </GoogleReCaptchaProvider>
  );
};

export const AdmIcon = new L.icon({
  iconUrl: iconAdm,
  iconSize: [40, 55],
});

export default function ContactPage() {
  return (
    <GenericLayout
      title="Contact | Adm mobilier"
      h1="contact"
      breadCrumbs={[
        {
          label: "Accueil",
          url: "/",
        },
        {
          label: "Contact",
          url: "/contact",
        },
      ]}
    >
      <div className="w-full relative pb-16 sm:pb-24 lg:pb-32 grid-cols-6 md:grid-cols-12 grid ">
        <div className="flex px-4 md:px-0 mt-[32px] md:mt-0 flex-col col-span-full md:col-start-2 md:col-end-8 row-start-2 row-end-3 md:row-start-1 md:row-end-2">
          <div>
            <h2 className="hidden md:block font-freight sm:text-5xl text-4xl text-left">
              Travaillons ensemble
            </h2>
            <p className="font-grotesque sm:text-lg text-16 text-left">
              Nous aimerions être en contat avec vous ! Utilisez ce formulaire
              pour communiquer avec nous sans vous déplacer, ou envoyez nous un
              e-email, nous vous répondrons le plus vite possible :)
            </p>
          </div>
          <ContactForm />
        </div>
        <div className="col-span-full px-4 md:px-0 md:col-start-9 md:col-end-13 md:mr-[-16px] row-start-1 row-end-2">
          <h2 className="block md:hidden font-freight sm:text-5xl text-4xl text-left">
            Travaillons ensemble
          </h2>
          <img
            className="w-full mt-4 h-[300px] md:h-[500px] xl:h-[799px] object-center object-cover"
            src={contact}
            alt="Contactez-nous"
          />
        </div>
      </div>
      <div className="w-full sm:w-1/2 sm:left-40 relative pt-16 px-4 sm:pt-8 lg:max-w-8xl lg:pt-12 lg:grid lg:grid-cols-1">
        <div className={"flex flex-col col-span-2"}>
          <hr className="my-4 border-black w-full" />
          <div className="text-left">
            <h2 className="underline font-bold font-grotesque sm:text-24 text-base">
              Les interlocuteurs à votre service :
            </h2>
            <div className="sm:my-8 my-4 font-grotesque sm:text-lg text-14">
              <p className="font-bold">
                Administration des ventes & Compatibilité :
              </p>
              <p>Bénédicte Guyot</p>
              <p>03 20 03 88 88</p>
            </div>
            <div className="sm:mt-8 mt-4">
              <div className="sm:my-8 my-4 font-grotesque sm:text-lg text-14">
                <p className="font-bold">Livraisons & Logistique</p>
                <p>Bastien Deforge</p>
                <p>07 67 01 84 54</p>
              </div>
            </div>
            <div className="sm:mt-8 mt-4">
              <div className="sm:my-8 my-4 font-grotesque sm:text-lg text-14">
                <p className="font-bold">
                  Responsable commercial seceur Restauration Collective :
                </p>
                <p>Laurent Boudewyn</p>
                <p>06 86 45 82 17</p>
              </div>
            </div>

            <div className="sm:mt-8 mt-4">
              <div className="sm:my-8 my-4 font-grotesque sm:text-lg text-14">
                <p className="font-bold">
                  Responsable commercial secteur Cafés - Restaurants :
                </p>
                <p>Alexis Descamps</p>
                <p>06 29 05 20 06</p>
              </div>
            </div>
            <div className="sm:mt-8 mt-4">
              <div className="sm:my-8 font-grotesque sm:text-lg text-14">
                <p className="font-bold">
                  Direction & Responsable commercial Enseignes de Restauration
                </p>
                <p>Xavier Descamps</p>
                <p>03 20 03 88 88</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4 border-black col-span-3 " />
      </div>
      <div
        className="w-full relative pb-16 px-4 sm:pb-12 lg:max-w-8xl lg:pb-12 grid
     grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4"
      >
        <div className="sm:left-40 flex flex-row col-span-1 sm:col-span-2 relative">
          <div className="flex-1 text-left sm:mx-auto">
            <h2 className="underline font-bold font-grotesque sm:text-24 text-lg">
              Contact :
            </h2>
            <div className="my-8 font-grotesque sm:text-base text-lg">
              <p>ADM Mobilier</p>
              <p>Rue Norbert Segard</p>
              <p>ZA des Champs</p>
              <p>59 223 Roncq</p>
              <p>adm@adm-mobilier.com</p>
              <p>www.adm-mobilier.com</p>
            </div>

            <h2 className="underline font-grotesque font-bold sm:text-24 text-lg">
              Accès :
            </h2>
            <div className="my-8 font-grotesque font-thin sm:text-24 text-lg">
              <p className="font-bold text-base">Depuis Lille :</p>
              <p className={"text-base"}>
                Autoroute A22 Lille-Gand, sortie n°16 (direction Roncq), au feu
                à gauche
                <br />
                Au feu (Volvo) à droite, puis à 200m : 1ère à droite (ZA des
                Champs, rue Norbert Segard)
                <br />
                Dans le virage : en face, entrée Parc Campion, bâtiment n°3
              </p>
            </div>

            <div className="my-5 font-grotesque sm:text-24 text-lg">
              <p className="font-bold text-base">Depuis Belgique :</p>
              <p className={"text-base"}>
                Autoroute E17 Gent-Lille, après la frontière A22 sortie n°17 A.
                <br />
                Au 3ème feu à droite jusqu’au rond-point, poursuivre tout droit.
                Au 2nd feu (Volvo) à droite <br />
                puis à 200m : 1ère à droite (ZA des Champs, rue Norbert Segard).
                <br /> Dans le virage : en face, entrée Parc Campion, bâtiment
                n°3
              </p>
              <p className="flex sm:hidden text-kakhi text-32 justify-center font-thin font-freight leading-relaxed my-4">
                Venez nous rencontrer !
              </p>
            </div>
          </div>
        </div>
        {/*WIP MAP */}

        <div className="relative col-span-1 sm:col-span-2 flex flex-col h-full z-10">
          <MapContainer
            className={"h-full"}
            style={{ minHeight: "400px" }}
            center={[50.74616718429216, 3.1407205684543986]}
            zoom={15}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[50.74616718429216, 3.1407205684543986]}
              icon={AdmIcon}
            >
              <Popup>
                ADM Mobilier <br />
                Rue Norbert Segard <br />
                ZA des Champs <br />
                59 223 Roncq
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </GenericLayout>
  );
}
