import React, { useState, useEffect } from "react";
import Select from "react-select";
import Api, { API_V1_BASE_URL } from "../../_helpers/api";

const SelectCategories = ({ productCategories, setProductCategories }) => {
  // define categories state
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  useEffect(() => {
    // fetch all categoriesOptions
    Api.get(`${API_V1_BASE_URL}/select_categories_options`)
      .then((response) => {
        console.log("response", response);
        setCategoriesOptions(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div>
      <Select
        onChange={setProductCategories}
        options={categoriesOptions}
        isMulti
        getOptionLabel={(option) => `${option.attributes.name}`}
        getOptionValue={(option) => `${option.attributes.id}`}
        value={productCategories}
      />
    </div>
  );
};

export default SelectCategories;
