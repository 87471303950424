import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { DateTime } from "luxon";
import Api from "../../../_helpers/api";
import { useAuth } from "../../../hooks/useAuth";

const AdminCategoriesPage = () => {
  const { sessionAuthHeader } = useAuth();

  const [categories, setCategories] = useState(null);

  useEffect(() => {
    Api.get("/api/v1/categories/list").then(({ data }) => {
      setCategories(data.data);
    });
  }, []);

  if (!categories) return <div>Chargement en cours...</div>;

  if (categories && categories.length > 0) {
    return (
      <div className="flex flex-col">
        <div className={"flex flex-row justify-between items-center"}>
          <h3 className={"text-3xl py-5"}>Sous-catégories</h3>
          <Link
            to={"/admin/categories/new"}
            type="button"
            className="inline-flex items-center px-4 py-2 border-transparent shadow-sm text-sm font-medium rounded text-kakhi-700 border border-kakhi-500 hover:text-white bg-transparent hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
          >
            <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Ajouter une sous-catégorie
          </Link>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      mise à jour
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map(({ attributes }, personIdx) => (
                    <tr
                      key={`${attributes.reference}__${personIdx}`}
                      className={
                        personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {attributes.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {DateTime.fromISO(attributes.updated_at)
                          .setLocale("fr")
                          .toRelative()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/admin/categories/${attributes.id}`}
                          className="text-kakhi-600 hover:text-kakhi-900"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminCategoriesPage;
