import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const createUser = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/users`,
    { user: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

// export const deleteMaterial = (id, callback) => {
//   Api.delete(`${API_V1_BASE_URL}/contact/${id}`).then(callback);
// };

export const useUsers = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/users`, getFetcher);

  return {
    users: data,
    isLoading: !error && !data,
    isError: error,
  };
};
