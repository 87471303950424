import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getTags } from "../../redux/slices/tag";

const SelectTags = ({ value, onChange }) => {
  const { tags } = useSelector((state) => state.tags);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTags());
  }, []);

  return (
    <div>
      <Select
        onChange={onChange}
        options={tags}
        isMulti
        getOptionLabel={(option) => `${option.attributes.name}`}
        getOptionValue={(option) => `${option.attributes.id}`}
        value={value}
      />
    </div>
  );
};

export default SelectTags;
