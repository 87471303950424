import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import {
  createReference,
  deleteReference,
  updateReference,
  useReference,
} from "../../../_services/reference-services";
import SelectUniverses from "../../../components/forms/SelectUniverses";
import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";

const AdminReferencesAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  // const [realisationProducts, setRealisationProducts] = useState([]);

  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");
  const [referenceUniverse, setReferenceUniverse] = useState([]);

  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();

  let { reference_id } = useParams();
  let isEditMode = !!reference_id;

  const goBack = () => {
    navigate("/admin/references");
  };
  const onSubmit = (data) => {
    let submitted_data = {
      reference: {
        ...data,
        universe_ids: referenceUniverse.map((universe) => {
          return universe.id;
        }),
        cover_picture: coverPictureBlob,
      },
    };

    return isEditMode
      ? updateReference(reference_id, submitted_data, goBack)
      : createReference(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteReference(reference_id, goBack);
  };

  const { reference, isLoading, isError } = useReference(reference_id);
  const fields = ["name", "release_date"];
  useEffect(() => {
    if (!!reference) {
      fields.forEach((field) =>
        setValue(field, reference.data.attributes[field])
      );
      setReferenceUniverse(
        getAllIncludedResourcesForAGivenType(reference, "universe")
      );
      setCoverPicture(reference.data.attributes.brand_logo_url);
      setPreviewURL(reference.data.attributes.brand_logo_url);
    }
  }, [reference]);

  if (isLoading && !!reference_id) {
    return <div>Reference loading...</div>;
  }

  if (isError) {
    return <div> loading failed ...</div>;
  }

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col">
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"text-3xl py-5"}>
              {isEditMode ? "Edition" : "Création"} d'une nouvelle reference
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Informations générales
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Ces informations seront visibles sur la page de description
                    du produit.
                  </p>
                </div>

                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Nom Entreprise
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("name")}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        autoComplete="name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="release_date"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Date
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("release_date")}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="release_date"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Logo
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <CoverPictureManager
                        coverPicture={coverPicture}
                        setCoverPicture={setCoverPicture}
                        coverPictureBlob={coverPictureBlob}
                        setCoverPictureBlob={setCoverPictureBlob}
                        setPreviewURL={setPreviewURL}
                        previewURL={previewURL}
                        setIsLoaded={setIsLoaded}
                        thumb_type={watch("thumb_type")}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="release_date"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Logo
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <SelectUniverses
                        selectedOptions={referenceUniverse}
                        setSelectedOptions={setReferenceUniverse}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-5 flex flex-row justify-between">
              <div>
                <span
                  onClick={handleDelete}
                  className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Supprimer le reference
                </span>
              </div>
              <div className="flex justify-end">
                <Link
                  to={"/admin/"}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  Annuler
                </Link>
                <button
                  disabled={!isLoaded}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
                >
                  {isLoaded
                    ? isEditMode
                      ? "Sauvegarder les modifications"
                      : "Enregistrer"
                    : "Chargement de la photo en cours"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AdminReferencesAddEditPage;
