import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";
import { useState, useEffect } from "react";

export class TagServices {
  static createTag = (data, callback) => {
    Api.post(
      `${API_V1_BASE_URL}/tags`,
      { tag: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      // .then(category => uploadFile(blobSignedID, category))
      .then(callback);
  };
  static updateTag = (id, data, callback) => {
    Api.put(
      `${API_V1_BASE_URL}/tags/${id}`,
      { tag: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      .then(callback);
  };
  static deleteTag = (id, data, callback) => {
    Api.delete(`${API_V1_BASE_URL}/tags/${id}`).then(callback);
  };
}

export const useTags = () => {
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchTags = async () => {
    try {
      const res = await Api.get(`${API_V1_BASE_URL}/tags`);
      if (res.status === 200) {
        setTags(res.data);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return { tags, isLoading, isError };
};

export const useTagsNames = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/tags/get_names`,
    getFetcher
  );

  return {
    tags: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useTag = (tag_id) => {
  const { data, error } = useSWR(
    !!tag_id ? `${API_V1_BASE_URL}/tags/${tag_id}` : null,
    getFetcher
  );

  return {
    tag: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useTagsOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_tags_options`,
    getFetcher
  );
  return {
    tags: data,
    isLoading: !error && !data,
    isError: error,
  };
};

// export const useProductsByCategories = (category_id) => {
//   const { data, error } = useSWR(
//     `${API_V1_BASE_URL}/category-products/${tag_id}/products`,
//     getFetcher
//   );
//   return {
//     products_categories: data,
//     isLoading: !error && !data,
//     isError: error,
//   };
// };
// export const bulkUpdateProductsByCategory = (category_id, records) => {
//   Api.put(`${API_V1_BASE_URL}/category-products/${category_id}/products`, {
//     products_by_category: [...records],
//   }).then((res) => {
//     if (res.status === 200) {
//       console.log("ordre updated OK");
//     }
//   });
// };
