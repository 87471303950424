import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { useProductsOptions } from "../../_services/product-services";
import Error from "../Error";
import Loading from "../utils/Loading";
import { useFilters, useFiltersOptions } from "../../_services/filter_services";

const SelectFilters = ({ selectedOptions, setSelectedOptions }) => {
  const { filters, isError, isLoading } = useFiltersOptions();

  const selectRef = useRef(null);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error error={isError} />;
  }
  return (
    <Select
      ref={selectRef}
      onChange={setSelectedOptions}
      options={filters.data}
      isMulti
      getOptionLabel={(option) => `${option.attributes.name}`}
      getOptionValue={(option) => `${option.attributes.id}`}
      value={selectedOptions}
    />
  );
};

export default SelectFilters;
