import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../../_helpers/api";
// import { Tab } from "@headlessui/react";
// import { DirectUpload } from "@rails/activestorage";
// import classNames from "classnames";
import SelectCategories from "../../../components/forms/SelectCategories";
import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";
import SelectMaterials from "../../../components/forms/SelectMaterials";
import ProductCard from "../../../components/products/ProductCard";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import PicturesManager from "../../../components/utils/PicturesManager";
import DocumentManager from "../../../components/utils/DocumentManager";
import SelectTags from "../../../components/forms/SelectTags";
import SelectFamilies from "../../../components/forms/SelectFamilies";
// import { useFamilyOptions } from "../../../_services/family-services";

const thumbTypeOptions = [
  { value: "large", label: "Horizontale - 16/9" },
  { value: "regular", label: "Verticale - 8/9" },
];

const AdminProductAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    //getValues,
    //formState: { errors },
  } = useForm();
  // const [result, setResult] = useState("");
  const [tags, setTags] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productMaterials, setProductMaterials] = useState([]);
  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");
  const [previewURLs, setPreviewURLs] = useState([]);

  const [pictures, setPictures] = useState([]);
  const [picturesBlobs, setPicturesBlobs] = useState([]);
  // const [familiesSelectOption, setFamiliesSelectOption] = useState([]);

  const [filesPDF, setFilesPDF] = useState([]);
  const [filesPDFBlob, setFilesPDFBlob] = useState([]);

  const [fileSketchup, setFileSketchup] = useState();
  const [fileSketchupBlob, setFileSketchupBlob] = useState({});
  const [selectedFamily, setSelectedFamily] = useState(null);

  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();

  let { product_id } = useParams();
  let isEditMode = !!product_id;

  const [refreshComponent, setRefreshComponent] = useState(false);

  // Fonction pour déclencher le rafraîchissement du composant
  const triggerRefreshComponent = () => {
    // Inversez la valeur de l'état pour provoquer un rafraîchissement
    setRefreshComponent((prevState) => !prevState);
  };

  const onSubmit = (data) => {
    return isEditMode ? updateProduct(product_id, data) : createProduct(data);
  };
  const updateProduct = (id, data) => {
    let productCategoriesIDS = productCategories.map(
      (productCategory) => productCategory.id
    );
    Api.patch(
      `/api/v1/products/${id}`,
      JSON.stringify({
        product: {
          ...data,
          cover_picture: coverPictureBlob,
          doc_pdf: filesPDFBlob,
          file_sketchup: fileSketchupBlob,
          pictures: picturesBlobs,
          category_ids: productCategoriesIDS,
          tag_names: tags.map(({ attributes }) => attributes.name),
          material_ids: productMaterials.map((material) => {
            return material.id;
          }),
          family_id: selectedFamily.id,
        },
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.data)
      .then(() => navigate("/admin"));
  };
  const createProduct = (data) => {
    let productCategoriesIDS = productCategories.map(
      (productCategory) => productCategory.id
    );
    Api.post(
      "/api/v1/products",
      JSON.stringify({
        product: {
          ...data,
          cover_picture: coverPictureBlob,
          pictures: picturesBlobs,
          doc_pdf: filesPDFBlob,
          category_ids: productCategoriesIDS,
          tag_names: tags.map(({ attributes }) => attributes.name),
          material_ids: productMaterials.map((material) => {
            return material.id;
          }),
          family_id: selectedFamily?.id,
        },
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.data)
      .then(() => navigate("/admin"));
  };
  const handleDelete = () => {
    Api.delete(`/api/v1/products/${product_id}`).then((result) => {
      navigate("/admin");
    });
  };

  const fields = [
    "reference",
    "name",
    "price",
    "structure",
    "properties",
    "width",
    "height",
    "depth",
    "materials",
    "description",
    "slug",
    "weight",
    "release_date",
    "more_details",
    "thumb_type",
    "video_url",
  ];

  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (product_id) {
      Api.get(`/api/v1/products/${product_id}`).then((response) => {
        // Handle the API response here
        setProduct(response.data);
      });
    }
  }, [product_id]);

  useEffect(() => {
    if (refreshComponent && product_id) {
      Api.get(`/api/v1/products/${product_id}`).then((response) => {
        // Handle the API response here
        setProduct(response.data);
      });
    }
  }, [refreshComponent, product_id]);

  useEffect(() => {
    if (!!product && product_id) {
      fields.forEach((field) =>
        setValue(field, product.data.attributes[field])
      );
      setProductCategories(
        getAllIncludedResourcesForAGivenType(product, "category")
      );
      setProductMaterials(
        getAllIncludedResourcesForAGivenType(product, "material")
      );
      setTags(getAllIncludedResourcesForAGivenType(product, "tag"));
      setCoverPicture(product.data.attributes.cover_picture_url);
      // if (!!previewURL) {
      //   setPreviewURL(product.data.attributes.cover_picture_url);
      // }

      setPictures(product.data.attributes.pictures_data);
      setFilesPDF(product.data.attributes.doc_pdf_data_list);
      setFileSketchup(product.data.attributes.file_sketchup_data);

      //  Set the options for selecting product family
      setSelectedFamily(
        getAllIncludedResourcesForAGivenType(product, "family")
      );
    }
  }, [product]);

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className={"flex flex-row justify-between items-center"}>
          <h3 className={"text-3xl py-5"}>
            {isEditMode ? "Edition" : "Création"} un nouveau produit
          </h3>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Informations générales
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Ces informations seront visibles sur la page de description du
                  produit.
                </p>
              </div>

              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="reference"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Réference
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("reference")}
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                      type="text"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("name")}
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                      autoComplete="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Prix
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("price")}
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                      autoComplete="price"
                      type="number"
                      step="0.01"
                    />
                  </div>
                </div>

                {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
                {/*  <label*/}
                {/*    htmlFor="slug"*/}
                {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"*/}
                {/*  >*/}
                {/*    URL*/}
                {/*  </label>*/}
                {/*  <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
                {/*    <div className="max-w-lg flex rounded shadow-sm">*/}
                {/*      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">*/}
                {/*        adm-mobilier.com/produits/*/}
                {/*      </span>*/}
                {/*      <input*/}
                {/*        {...register("slug", { required: false, disabled: true })}*/}
                {/*        type="text"*/}
                {/*        id="slug"*/}
                {/*        value={slugify(watch("name"))}*/}
                {/*        defaultValue={product}*/}
                {/*        className="flex-1 block w-full focus:ring-kakhi-500 focus:border-kakhi-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-50 "*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Description
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("description", { required: false })}
                      id="description"
                      name="description"
                      rows={5}
                      className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Description succinte du produit.
                    </p>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Caractéristiques
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("properties", { required: false })}
                      id="properties"
                      name="properties"
                      rows={5}
                      className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Caractéristique du produit.
                    </p>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Structures
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("structure", { required: false })}
                      id="structure"
                      name="structure"
                      rows={5}
                      className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Caractéristique du produit.
                    </p>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="more_details"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Informations complémentaires
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("more_details", { required: false })}
                      id="more_details"
                      name="more_details"
                      rows={5}
                      className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Informations complémentaires
                    </p>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Date de sortie
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("release_date")}
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                      autoComplete="name"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Video présentation - lien youtube
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("video_url")}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    autoComplete="name"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Type de vignette
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  {...register("thumb_type")}
                  className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                  autoComplete="name"
                >
                  {thumbTypeOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Famille de produits
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <SelectFamilies
                  selectedOption={selectedFamily}
                  setSelectedOption={setSelectedFamily}
                />
              </div>
            </div>

            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Photos
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Utilisez des photos présentant uniquement la réalisation en
                  question.
                </p>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Photo de couverture
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                    <CoverPictureManager
                      coverPicture={coverPicture}
                      setCoverPicture={setCoverPicture}
                      coverPictureBlob={coverPictureBlob}
                      setCoverPictureBlob={setCoverPictureBlob}
                      setPreviewURL={setPreviewURL}
                      previewURL={previewURL}
                      setIsLoaded={setIsLoaded}
                      thumb_type={watch("thumb_type")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Photo(s) de présentation
              </label>
              <PicturesManager
                pictures={pictures}
                setPictures={setPictures}
                previewURLs={previewURLs}
                picturesBlob={picturesBlobs}
                setPicturesBlob={setPicturesBlobs}
                setPreviewURLs={setPreviewURLs}
                setIsLoaded={setIsLoaded}
                isLoaded={isLoaded}
                resource={{ name: "products", id: product_id }}
              />
            </div>
            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Dimensions
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Les dimmension du produits en mm.
                </p>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Hauteur ( en mm )
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("height", { min: 0 })}
                      min={0}
                      type="number"
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-500 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Largeur ( en mm )
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("width", { min: 0 })}
                      min={0}
                      type="number"
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-500 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Profondeur ( en mm )
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("depth", { min: 0 })}
                      min={0}
                      type="number"
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-500 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Poids ( en g )
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("weight", { min: 0 })}
                      min={0}
                      type="number"
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-500 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Composition
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  De quels matériaux est composé le produit.
                </p>
              </div>
              <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div
                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                          id="label-notifications"
                        >
                          Matériaux
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="max-w-lg">
                          <div className="mt-4 space-y-4">
                            <div className="space-y-6">
                              <SelectMaterials
                                setSelectedOptions={setProductMaterials}
                                selectedOptions={productMaterials}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div
                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                          id="label-notifications"
                        >
                          Sous Catégorie
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="max-w-lg">
                          <div className="mt-4 space-y-4">
                            <div className="space-y-6">
                              <SelectCategories
                                productCategories={productCategories}
                                setProductCategories={setProductCategories}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div
                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                          id="label-notifications"
                        >
                          Tags
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="max-w-lg">
                          <div className="mt-4 space-y-4">
                            <div className="space-y-6">
                              <SelectTags value={tags} onChange={setTags} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Documents PDF
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Utilisez les documents que vous jugez bon de partager avec
                  votre prospect.
                </p>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {" "}
                  Document PDF
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                    <DocumentManager
                      filesPDF={filesPDF}
                      setFilesBlob={setFilesPDFBlob}
                      setIsLoaded={setIsLoaded}
                      triggerRefreshComponent={triggerRefreshComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  3D
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Utilisez les documents que vous jugez bon de partager avec
                  votre prospect.
                </p>
              </div> */}
            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {" "}
                  Document 3D
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                    <DocumentManager
                      file={fileSketchup}
                      setFilesBlob={setFileSketchupBlob}
                      setIsLoaded={setIsLoaded}
                    />
                  </div>
                </div>
              </div> */}
            {/* </div> */}
          </div>

          <div className="py-5 flex flex-row justify-between">
            <div>
              <span
                onClick={handleDelete}
                className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
              >
                Supprimer le produit
              </span>
            </div>
            <div className="flex justify-end">
              <Link
                to={"/admin/"}
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
              >
                Annuler
              </Link>
              <button
                disabled={!isLoaded}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
              >
                {isLoaded
                  ? isEditMode
                    ? "Sauvegarder les modifications"
                    : "Créer un nouveau produit"
                  : "Chargement de la photo en cours"}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* Preview */}
      <div className={"flex flex-col w-full w-1/2"}>
        <div className={"sticky top-0 p-4 m-4 bg-beige-400 rounded"}>
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la vignette de présentation
          </h3>
          <div className={"grid grid-cols-2"}>
            <ProductCard
              reference={watch("reference")}
              cover_picture_url={previewURL || coverPicture}
              thumb_type={watch("thumb_type")}
              name={watch("name")}
              readOnly={true}
              id={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminProductAddEditPage;
