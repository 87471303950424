import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const createContact = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/contacts`,
    { contact: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const updateMaterial = (id, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/contacts/${id}`,
    { contact: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const deleteMaterial = (id, callback) => {
  Api.delete(`${API_V1_BASE_URL}/contact/${id}`).then(callback);
};

export const useContacts = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/contacts`, getFetcher);

  return {
    contacts: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useContact = (contact_id) => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/contacts/${contact_id}`,
    getFetcher
  );

  return {
    contact: data,
    isError: error,
    isLoading: !error && !data,
  };
};

export const useContactsOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_contacts_options`,
    getFetcher
  );
  return {
    contacts: data,
    isLoading: !error && !data,
    isError: error,
  };
};
