import classNames from "classnames";
import * as PropTypes from "prop-types";
import React from "react";
// import { useMediaQuery } from "../hooks/useMediaQuery";
// import Breadcrumbs from "./breadcrumbs";

const HeroSection = ({ index, article }) => {
  return (
    // <div
    //   className={classNames(
    //     {
    //       "sm:flex-row-reverse": index % 2 !== 0,
    //       "sm:flex-row": index % 2 === 0,
    //     },
    //     "flex justify-between flex-col-reverse"
    //   )}
    // >
    //   <div
    //     className={classNames(
    //       "xl:w-2/5 sm:w-1/3 flex-col container px-5 max-w-lg",
    //       { "-mt-10": fixBreadcrumb && mathQuery && !isMobile }
    //     )}
    //   >
    //     {index === 0 && <Breadcrumbs breadCrumbs={breadcrumbs} />}

    //     {isH2 ? (
    //       <h2 className="font-freight font-kakhi sm:text-40 text-32 mb-4 sm:mt-5 mt-8">
    //         {article.title}
    //       </h2>
    //     ) : (
    //       <h1 className="font-freight font-kakhi sm:text-40 text-32 mb-4 sm:mt-5 mt-8">
    //         {article.title}
    //       </h1>
    //     )}
    //     <div
    //       className="sm:space-y-10 space-y-6 font-grotesque text-kakhi text-justify sm:text-20 text-16"
    //       dangerouslySetInnerHTML={{ __html: article.body }}
    //     />
    //     {article.link && (
    //       <a
    //         className="button-translation4 xl:flex hidden font-grotesque text-2xl mt-10 py-4 px-6 rounded shadow items-center"
    //         href={article.link}
    //       >
    //         En savoir plus
    //       </a>
    //     )}
    //   </div>
    //   <div style={{ width: isMobile ? "100%" : `${maxWidth}%` }}>
    //     <div
    //       className="imganim aspect-w-1 aspect-h-1 bg-cover bg-center rounded"
    //       style={{
    //         backgroundImage: `url(${article.imageURL})`,
    //       }}
    //     />
    //   </div>
    // </div>
    <div className="px-[20px] grid grid-cols-12">
      <div
        className={classNames(
          {
            "col-span-full lg:col-start-2 lg:col-end-7 row-start-1 row-end-2":
              index % 2 !== 0,
          },
          {
            "col-span-full lg:col-start-7 lg:col-end-12 row-start-1 row-end-2":
              index % 2 === 0,
          }
        )}
      >
        <div className="w-full h-auto overflow-hidden rounded-xl">
          <img
            className="w-full h-auto"
            src={article.imageURL}
            alt="Nos références"
          />
        </div>
      </div>
      <div
        className={classNames({
          "col-span-full lg:col-start-2 lg:col-end-6 flex flex-col justify-center lg:row-start-1 lg:row-end-2 mt-[8px] lg:mt-0":
            index % 2 === 0,
          "col-span-full lg:col-start-8 lg:col-end-12 flex flex-col justify-center lg:row-start-1 lg:row-end-2 mt-[8px] lg:mt-0":
            index % 2 !== 0,
        })}
      >
        <h2 className="font-playfair font-bold text-20 lg:text-24 2xl:text-32">
          {article.title}
        </h2>
        <div className="font-montserrat text-montserrat-14 sm:text-montserrat-16 mt-[8px] lg:mt-[32px]">
          <p dangerouslySetInnerHTML={{ __html: article.body }} />
        </div>
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  index: PropTypes.number,
  article: PropTypes.any,
};
export default HeroSection;
