import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import livraison from "../../../assets/images/livraison.jpg";
import HeroSection from "../components/HeroSection";

const LivraisonMontage = () => {
  const article = {
    title: "Livraison montage",
    body: "Chez ADM Mobilier, c’est clair pour chacun de nos collaborateurs : nous devons à nos clients un langage de vérité, et nous croyons en ces maîtres-mots : Souplesse - Adaptation - Fiabilité",
    imageURL: livraison,
    link: null,
  };

  const livraisonMontageBreadcrumbs = [
    { label: "Accueil", url: "/" },
    { label: "Services" },
    { label: "Livraison & montage", url: "/services/livraison-montage" },
  ];

  return (
    <GenericLayout
      title={"Nos Services | livraison - montage | Adm mobilier"}
      h1="Livraison - Montage"
      breadCrumbs={livraisonMontageBreadcrumbs}
    >
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection
            index={0}
            article={article}
            isH2
            fixBreadcrumb
            breakpointFixBreadcrumb={1749}
          />
        </div>
      </div>
      <hr className="border-1 border-kakhi xl:mx-96 lg:mx-72 md:mx-48 mx-4 my-10" />
      <div>
        <div className="font-freight xl:text-40 text-32 text-center justify-center mx-8">
          Chez ADM Mobilier, c’est clair pour chacun de nos collaborateurs :
          <br />
          nous devons à nos clients un langage de vérité, et nous croyons en ces
          quelques maîtres-mots
        </div>
        <div className="flex flex-col xl:flex-row xl:divide-x xl:divide-black mx-4 sm:mt-28 mt-8 xl:space-y-0 space-y-10">
          <div className="flex-1 xl:px-12 px-0">
            <h3 className="font-playfair text-20 sm:my-0 mt-10 xl:mb-8 md:mb-4 mb-2">
              Souplesse et Adaptation
            </h3>

            <p className="text-justify font-montserrat text-14 sm:text-base my-3">
              Notre métier n’est pas de livrer « sur le trottoir », ou d’envoyer
              des meubles en kit ! Dans le grand nord de la France, dès que le
              seuil de livraison gratuite est atteint, nos prix incluent le
              transport (par l’un de nos 5 camions), mais aussi le déballage et
              le montage de vos meubles, y compris la reprise des déchets
              d’emballage qui seront ensuite recyclés.
            </p>
            <p className="text-justify font-montserrat text-14 sm:text-base my-3">
              Pour toutes les autres destinations, nous faisons appel à un
              transporteur spécialement affrété, et définissons avec vous la
              mission d’une équipe d’installateurs-monteurs spécialisés, en
              sous-traitance, et le degré de son intervention.
            </p>
            <p className="text-justify font-montserrat text-14 sm:text-base my-3">
              Evidemment, si vous voulez enlever la marchandise, si vous
              préférez monter vous-même vos meubles, ou si vous souhaitez faire
              appel à vos propres sous-traitants, c’est possible aussi !
            </p>
          </div>
          <div className="flex-1 xl:px-12 px-0">
            <h3 className="font-playfair font-medium text-20 sm:my-0 mt-10 xl:mb-8 md:mb-4 mb-2">
              Fiabilité du délai de livraison
            </h3>

            <div>
              <p className="text-justify font-montserrat text-14 sm:text-base my-3">
                Annoncer un délai trompeur pour obtenir une commande ne fait pas
                partie de nos valeurs !
              </p>
              <p className="text-justify font-montserrat text-14 sm:text-base my-3">
                Si les meubles que vous choisissez sont en stock chez nous, nous
                pouvons immédiatement planifier une date de livraison. S’ils
                sont en stock dans les entrepôts de nos fournisseurs, nous
                donnons une semaine de livraison, en tenant compte du temps de
                transport.
              </p>
              <p className="text-justify font-montserrat text-14 sm:text-base my-3">
                Si les finitions que vous avez souhaitées sont personnalisées et
                fabriquées à la demande, elles impliqueront un délai de
                fabrication et de transport. Nous vous donnons alors une
                estimation de délai. Puis, lorsque nos fournisseurs nous auront
                confirmé leur délai précis, nous soumettons à votre accord la
                semaine de livraison possible.
              </p>
              <p className="text-justify font-montserrat text-14 sm:text-base my-3">
                Lorsqu’une semaine de livraison a été annoncée, notre service
                livraisons prend contact avec vous 48 à 72h à l’avance, afin de
                fixer une date précise qui convienne.
              </p>
              <p className="text-justify font-montserrat text-14 sm:text-base my-3">
                Si vous avez une date précise ou impérative, nous vérifions les
                possibilités de nos fabricants et transporteurs, et vous
                confirmons clairement une date avant de signer la commande. Et
                si des aléas retardent votre chantier, appelez-nous dès que
                possible. Dans la plupart des cas, nous pourrons stocker
                temporairement votre marchandise dans notre entrepôt, et définir
                ensemble une nouvelle date de livraison en fonction de nos
                plannings respectifs.
              </p>
            </div>
          </div>
          <div className="flex-1 xl:px-12 px-0">
            <h3 className="font-playfair text-20 sm:my-0 mt-10 xl:mb-8 md:mb-4 mb-2">
              Fiabilité de la durée d'installation
            </h3>

            <p className="text-justify font-montserrat text-14 sm:text-base my-3">
              L’idée est simple : quand les monteurs-installateurs quittent le
              chantier, nos clients doivent pouvoir mettre les pieds sous la
              table ! Nos monteurs sont expérimentés : régulièrement ils
              installent plusieurs centaines de places dans les restaurants
              scolaires ou d’entreprises, le plus souvent sur une seule journée.
              Pas question que des élèves ou des employés ne puissent pas
              déjeuner le lendemain ! Forts de cette expérience, nous vous
              annonçons clairement, dès la commande, si la livraison de votre
              mobilier prendra quelques heures, une demi-journée, une journée
              complète, ou davantage, même si cela reste exceptionnel.
            </p>
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default LivraisonMontage;
