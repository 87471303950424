import React, { useEffect, useRef } from "react";
import Select from "react-select";
import Error from "../Error";
import Loading from "../utils/Loading";
import { useMaterialsOptions } from "../../_services/material-services";

const SelectProducts = ({ selectedOptions, setSelectedOptions }) => {
  const { materials, isError, isLoading } = useMaterialsOptions();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error error={isError} />;
  }

  return (
    <div>
      <Select
        onChange={setSelectedOptions}
        options={materials.data}
        isMulti
        getOptionLabel={(option) => `${option.attributes.name}`}
        getOptionValue={(option) => `${option.attributes.id}`}
        value={selectedOptions}
      />
    </div>
  );
};

export default SelectProducts;
