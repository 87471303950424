import _ from "lodash";

export const getSpecificIncludedRelationship = (
  data,
  type,
  matchingTypeRelationshipTypeData
) => {
  if (matchingTypeRelationshipTypeData) {
    return _.filter(data.included, (includeObject) => {
      return (
        includeObject.type === type &&
        matchingTypeRelationshipTypeData.find(
          (el) => el.id === includeObject.id
        )
      );
    })
      .map(({ attributes }) => {
        return attributes.name;
      })
      .join(" ,");
  }
};
export const getSpecificIncludedHasOneRelationship = (
  includedData,
  type,
  matchingTypeRelationshipTypeData
) => {
  if (matchingTypeRelationshipTypeData) {
    return _.find(includedData, (includeObject) => {
      return (
        includeObject.type === type &&
        matchingTypeRelationshipTypeData.id === includeObject.id
      );
    });
  }
};
export const getSpecificCategorydHasOneRelationship = (includedData, type) => {
  return _.find(includedData, (includeObject) => {
    return includeObject.type === type;
  });
};

// TODO - get all resources for a given type ( eg: every products included ( JSONAPI-way) for a given resource ( eg: category )

export const getAllIncludedResourcesForAGivenType = (data, type) => {
  if (data && data.data && data.data.length > 1) {
    const referenceForAGivenUniverse = _.filter(data.included, () => {});
  }

  return _.filter(data.included, (includeObject) => {
    return includeObject.type === type;
  }).map((includedResource) => includedResource);
};
