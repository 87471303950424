export function formatPrice(priceInEuro) {
  if (!priceInEuro) {
    return "";
  }
  const formattedPrice = priceInEuro.toLocaleString("fr", {
    style: "currency",
    currency: "EUR",
  });
  return formattedPrice;
}
