import React from "react";
import logoFooterDesktop from "../../../assets/images/logo_footer.png";
import NewsletterInput from "./NewsletterInput";

const categories = [
  {
    label: "Produits",
    url: "/mobiliers-accessoires",
  },
  {
    label: "Services",
    url: "/services",
  },
  {
    label: "Références",
    url: "/references",
  },
  {
    label: "Inspirations",
    url: "/nos-inspirations",
  },
  {
    label: "Entreprise",
    url: "/presentation",
  },
  {
    label: "Contacts",
    url: "/contact",
  },
];

const contact = [
  {
    label: "ADM Mobiler",
    url: "/www.adm-mobilier.com",
  },
  {
    label: /* html */ `
    Rue Norbert Segard<br/>
    ZA des Champs<br/>
    59&nbsp;223 Roncq<br/>`,
    url: "/contact",
  },
  {
    label: "adm@adm-mobilier.com",
    url: "maito:adm@adm-mobilier.com",
  },
  {
    label: "www.adm-mobilier.com",
    url: "www.adm-mobilier.com",
  },
];

const Footer = () => {
  return (
    <footer className="px-[20px] pb-[20px] mt-28">
      <div className="bg-my-beige pt-[32px] lg:pt-[80px] 2xl:pt-[107px] pb-[56px] lg:pb-[30px] 2xl:pb-[54px]">
        <div className="block lg:hidden px-[12px]">
          <div className="flex justify-center">
            <img
              className="w-[82px] h-auto"
              src={
                "https://res.cloudinary.com/dlnlpzitl/image/upload/v1697789403/logo_footer_t6eov3.png"
              }
              alt="ADM Mobiler"
            />
          </div>
          <div className="flex flex-col items-center w-full mt-[32px]">
            <h2 className="font-playfair text-[16px] font-medium text-center">
              RECEVEZ NOTRE CATALOGUE
            </h2>
            <div className="mt-[8px] flex w-full justify-center">
              <NewsletterInput />
            </div>
          </div>
          <div className="flex flex-col items-center mt-[64px]">
            <h2 className="font-playfair text-[16px] font-medium text-center uppercase">
              Contact
            </h2>
            <div className="flex flex-col items-center mt-[40px]">
              {contact.map(({ label, url }, i) => (
                <a
                  href={url}
                  key={i}
                  className="inline-block font-avenir-text text-avenir-14 first:mt-0 mt-[16px]"
                  dangerouslySetInnerHTML={{ __html: label }}
                ></a>
              ))}
            </div>
          </div>
          <div className="w-full h-[2px] bg-kakhi-300 my-[32px]"></div>
          <div className="font-avenir-text text-avenir-14 flex flex-col items-center gap-y-[16px]">
            <a href="/#">Mentions légales</a>
            <p>Site web rélisé par MISTER_E</p>
          </div>
        </div>
        <div className="hidden lg:grid grid-cols-12">
          <div className="col-start-2 col-end-4">
            <h2 className="font-playfair text-[16px] 2xl:text-20 font-medium">
              CATÉGORIES
            </h2>
            <div className="mt-[32px] flex flex-col">
              {categories.map(({ label, url }, i) => (
                <a
                  href={url}
                  key={i}
                  className="hover:underline inline-block font-avenir-text text-avenir-14 2xl:text-avenir-16 first:mt-0 mt-[16px]"
                >
                  {label}
                </a>
              ))}
            </div>
          </div>
          <div className="col-start-5 col-end-9 flex justify-center">
            <div className="w-full lg:max-w-[288px] 2xl:max-w-[385px]">
              <img
                className="w-full h-auto"
                src={logoFooterDesktop}
                alt="ADM Mobilier"
              />
            </div>
          </div>
          <div className="col-start-10 col-end-12 text-right">
            <h2 className="font-playfair text-[16px] 2xl:text-20 font-medium">
              CONTACT
            </h2>
            <div className="mt-[32px] flex flex-col">
              {contact.map(({ label, url }, i) => (
                <a
                  href={url}
                  key={i}
                  className="hover:underline inline-block font-avenir-text text-avenir-14 2xl:text-avenir-16 first:mt-0 mt-[16px]"
                  dangerouslySetInnerHTML={{ __html: label }}
                ></a>
              ))}
            </div>
          </div>
          <div className="col-span-full lg:mt-[72px] 2xl:mt-[88px] flex justify-center">
            <div className="flex flex-col items-center w-full">
              <h2 className="font-playfair text-[16px] 2xl:text-20 font-medium">
                RECEVEZ NOTRE CATALOGUE
              </h2>
              <div className="lg:mt-[16px] 2xl:mt-[24px] w-full flex justify-center">
                <NewsletterInput />
              </div>
              <div className="font-avenir-text text-[10px] 2xl:text-[12px] mt-[32px] 2xl:mt-[48px] text-black">
                <p>
                  <a href="/#" className="hover:underline">
                    Mentions légales
                  </a>{" "}
                  - Site réalisé par MISTER_E
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
