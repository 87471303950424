import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";

export const divideIn2Array = (array = []) => {
  if (array?.length && array?.length > 10) {
    const midIndex = Math.floor(array.length / 2);
    const myArray = [[...array.slice(0, midIndex)], [...array.slice(midIndex)]];
    return myArray;
  }
  return [array];
};

function CategoriesFilterList({
  onChange,
  facet,
  isOne = false,
  label,
  name,
  filters,
}) {
  const [checkedkeys, setCheckedkeys] = useState([]);

  useEffect(() => {
    if (!filters[name]) {
      setCheckedkeys([]);
    }
  }, [filters]);

  const handleChange = (e) => {
    const currentValue = e.target.value;
    const checked = e.target.checked;
    if (isOne) {
      onChange &&
        onChange({ target: { value: checked ? [currentValue] : [], name } });
      setCheckedkeys(checked ? [currentValue] : []);
    } else {
      const currentChecked = checked
        ? checkedkeys.concat(currentValue)
        : checkedkeys.filter((item) => item != currentValue);
      setCheckedkeys(currentChecked);
      onChange && onChange({ target: { value: currentChecked, name } });
    }
  };

  const lists = useMemo(() => divideIn2Array(Object.entries(facet)), [facet]);

  return (
    <div
      className={classNames("flex flex-col space-y-7", {
        "col-span-2": label === "SPÉCIFICITÉS" || label === "Catégories",
      })}
    >
      <div className="text-stone-400 text-base font-semibold font-montserrat uppercase leading-tight tracking-tight">
        {label}
      </div>
      <div className="flex flex-col sm:flex-row gap-x-4">
        {lists.map((facet, index) => (
          <div key={index} className="flex-grow flex-col flex space-y-2">
            {facet.map(([key, value]) => (
              <label
                key={key}
                onClick={(e) => e.stopPropagation()}
                className="text-kakhi-500  text-sm font-montserrat leading-tight my-1 flex gap-x-1 flex-row items-center "
              >
                <input
                  type="checkbox"
                  checked={filters && filters[name]?.includes(key)}
                  value={key}
                  onChange={handleChange}
                  className="h-4 w-4 rounded-sm border text-stone-400 focus:ring-skin-600"
                />
                <span className="text-xs xl:text-sm">
                  {key} ({value})
                </span>
              </label>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoriesFilterList;
