import React from "react";

import GenericLayout from "../layouts/GenericLayout";
import { useParams } from "react-router-dom";
import Loading from "../components/utils/Loading";
import Error from "../components/Error";
import { useInspiration } from "../_services/inspiration-service";
// import Breadcrumbs from "../components/breadcrumbs";
import RealisationProductsSlider from "./RealisationProductsSlider";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";

const InspirationPage = () => {
  const { inspiration_slug } = useParams();
  const { inspiration, isError, isLoading } = useInspiration(inspiration_slug);
  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  const { title, description, pictures_data } = inspiration.data.attributes;
  const products = getAllIncludedResourcesForAGivenType(inspiration, "product");

  const realisationBC = [
    { label: "Adm mobilier", url: "/" },
    { label: "Nos inspirations", url: "/nos-inspirations" },
    { label: title, url: `/nos-inspirations/${inspiration_slug}` },
  ];

  return (
    <GenericLayout
      title={`${title} | Adm mobilier`}
      h1={title}
      breadCrumbs={realisationBC}
    >
      <div className="xl:mx-48 md:mx-12 mx-4 sm:mb-28">
        {/* <Breadcrumbs breadCrumbs={realisationBC} /> */}
        <div className="flex xl:flex-row flex-col gap-x-6">
          <div className="flex-1 flex-col">
            <div className="flex sm:flex-col flex-col-reverse xl:mr-24 mb-6">
              <div className="flex-1">
                <div className="flex items-end font-freight sm:justify-start justify-center">
                  <h2 className="sm:text-40 text-32 sm:mr-4 mr-0 mt-6">
                    {title}
                  </h2>
                </div>
                <p className="font-grotesque sm:text-20 text-16 sm:my-8 my-4 text-justify">
                  {description}
                </p>
              </div>

              <div
                className="xl:flex-1 aspect-w-1 aspect-h-1 bg-cover bg-center rounded"
                style={{
                  backgroundImage: `url(${
                    pictures_data && pictures_data[0] && pictures_data[0].url
                  })`,
                }}
              />
            </div>

            <div className="flex flex-col items-stretch">
              <div className="xl:self-end w-full xl:w-80">
                <div
                  className="aspect-h-12 aspect-w-9 bg-cover bg-center rounded"
                  style={{
                    backgroundImage: `url(${
                      pictures_data && pictures_data[1] && pictures_data[1].url
                    })`,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex-1 xl:mt-0 mt-8">
            <div
              className="xl:flex-1 aspect-w-8 aspect-h-12 w-full bg-cover bg-center rounded"
              style={{
                backgroundImage: `url(${
                  pictures_data && pictures_data[2] && pictures_data[2].url
                })`,
              }}
            />
          </div>
        </div>

        {products.lenght > 0 && (
          <h2 className="sm:block hidden sm:mb-2 mb-6 sm:mt-24  mt-6 font-freight sm:text-40 text-2xl text-left">
            Liste des produits proposés par ADM
          </h2>
        )}
        {products.lenght > 0 && (
          <h2 className="sm:hidden block sm:mb-2 mb-6 sm:mt-24  mt-6 font-freight sm:text-40 text-2xl text-left">
            Liste des produits proposés
          </h2>
        )}

        {products.lenght > 0 && (
          <RealisationProductsSlider products={products} />
        )}
      </div>
    </GenericLayout>
  );
};

export default InspirationPage;
