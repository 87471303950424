import slugify from "react-slugify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import {
  createInspiration,
  deleteInspiration,
  updateInspiration,
  useInspiration,
} from "../../../_services/inspiration-service";
import SelectProducts from "../../../components/forms/SelectProducts";
import _ from "lodash";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import PicturesManager from "../../../components/utils/PicturesManager";

const AdminInspirationAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");

  const [dataCoverPicture, setDataCoverPicture] = useState("");
  const [dataPictures, setDataPictures] = useState([]);

  const [pictures, setPictures] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);
  const [picturesBlobs, setPicturesBlobs] = useState([]);

  const navigate = useNavigate();

  let { inspiration_id } = useParams();
  let isEditMode = !!inspiration_id;

  const [isLoaded, setIsLoaded] = useState(true);

  const goBack = () => {
    navigate("/admin/inspirations");
  };

  const [selectedProducts, setSelectedProducts] = useState([]);
  const onSubmit = (data) => {
    let submitted_data = {
      ...data,
      product_ids: selectedProducts.map((product) => {
        return product.id;
      }),
      cover_picture: coverPictureBlob,
      pictures: picturesBlobs,
    };

    return isEditMode
      ? updateInspiration(inspiration_id, submitted_data, goBack)
      : createInspiration(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteInspiration(inspiration_id, goBack);
  };

  const { inspiration, isError, isLoading } = useInspiration(inspiration_id);

  useEffect(() => {
    if (!!inspiration && inspiration_id) {
      const fields = [
        "title",
        "description",
        "slug",
        "reference",
        "release_date",
      ];
      fields.forEach((field) =>
        setValue(field, inspiration.data.attributes[field])
      );

      setCoverPicture(inspiration.data.attributes.cover_picture_url);
      setPreviewURL(inspiration.data.attributes.cover_picture_url);
      setPictures(inspiration.data.attributes.pictures_data);

      setSelectedProducts(
        _.filter(inspiration.included, (include) => {
          return include.type === "product";
        })
      );
    }
  }, [inspiration]);

  if (isLoading && inspiration_id) {
    return <div>Chargement de l'inspiration ...</div>;
  }

  if (isError) {
    console.error(isError);
    return <div> Erreur dans le chargement ...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>
          {isEditMode ? "Edition d'une" : "Création d'une nouvelle "}{" "}
          inspiration
        </h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Informations générales
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Ces informations seront visibles sur la page de description du
                produit.
              </p>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Titre
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("title")}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    autoComplete="title"
                    type="text"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reference
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("reference")}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    autoComplete="reference"
                    type="text"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Date de réalisation
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("release_date")}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    autoComplete="reference"
                    type="date"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  URL
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      adm-mobilier.com/inspirations/
                    </span>
                    <input
                      {...register("slug", { required: false, disabled: true })}
                      type="text"
                      id="slug"
                      value={slugify(watch("title"))}
                      className="flex-1 block w-full focus:ring-kakhi-500 focus:border-kakhi-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-50 "
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Description
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    {...register("description", { required: false })}
                    id="description"
                    name="description"
                    rows={5}
                    className="max-w-lg shadow-sm block w-full focus:ring-kakhi-500 focus:border-kakhi-500 sm:text-sm border border-gray-300 rounded"
                    defaultValue={""}
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Description succinte du produit.
                  </p>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Photo de couverture
                </label>
                <CoverPictureManager
                  coverPicture={coverPicture}
                  setCoverPicture={setCoverPicture}
                  coverPictureBlob={coverPictureBlob}
                  setCoverPictureBlob={setCoverPictureBlob}
                  setPreviewURL={setPreviewURL}
                  previewURL={previewURL}
                  setIsLoaded={setIsLoaded}
                  // thumb_type={watch("thumb_type")}
                />
              </div>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Photo(s) de présentation
            </label>
            <PicturesManager
              pictures={pictures}
              setPictures={setPictures}
              previewURLs={previewURLs}
              picturesBlob={picturesBlobs}
              setPicturesBlob={setPicturesBlobs}
              setPreviewURLs={setPreviewURLs}
              setIsLoaded={setIsLoaded}
              isLoaded={isLoaded}
              resource={{ name: "inspirations", id: inspiration_id }}
            />
          </div>
          <SelectProducts
            selectedOptions={selectedProducts}
            setSelectedOptions={setSelectedProducts}
          />
        </div>
        <div className="py-5 flex flex-row justify-between">
          <div>
            <span
              onClick={handleDelete}
              className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
            >
              Supprimer le produit
            </span>
          </div>
          <div className="flex justify-end">
            <button
              onClick={goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
            >
              Annuler
            </button>
            <button
              disabled={!isLoaded}
              type="submit"
              className={classNames(
                !isLoaded
                  ? "bg-kakhi-400"
                  : "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
              )}
            >
              {isLoaded
                ? isEditMode
                  ? "Sauvegarder les modifications"
                  : "Créer une nouvelle sous catégorie"
                : "Chargememt de la photo en cours"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminInspirationAddEditPage;
