import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import useSWR from "swr";
import Api from "../../../_helpers/api";
import { useForm } from "react-hook-form";
import {
  createFamily,
  deleteFamily,
  updateFamily,
  useFamily,
} from "../../../_services/family-services";

const AdminFamiliesAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  let { family_id } = useParams();
  let isEditMode = !!family_id;

  const fetcher = (url) => Api.get(url).then((response) => response.data);
  const { data: familyData, error } = useSWR(
    () => (isEditMode ? `/api/v1/families/${family_id}` : null),
    fetcher
  );

  useEffect(() => {
    if (!!familyData) {
      const fields = ["name", "description"];
      fields.forEach((field) =>
        setValue(field, familyData.data.attributes[field])
      );
    }
  }, [familyData]);

  if (!familyData && isEditMode) {
    return <div>Chargement de la famille...</div>;
  }

  if (error) {
    console.error(error);
    return <div> Erreur dans le chargement ...</div>;
  }

  const goBack = () => {
    navigate("/admin/families");
  };

  const onSubmit = (data) => {
    console.log("submit");
    console.log(data);

    let submitted_data = {
      family: {
        ...data,
      },
    };

    return isEditMode
      ? updateFamily(family_id, submitted_data, goBack)
      : createFamily(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteFamily(family_id, goBack);
  };

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>
          {family_id ? "Edition d'un" : "Création d'un nouveau "} Famille
        </h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Nom de la famille
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                required
                name="name"
                className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                type="text"
                {...register("name")}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Description
              <br />
              <i>
                Qui pourra servir si l'on veut créer une page de présentation de
                la famille
              </i>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                name="description"
                className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                {...register("description")}
              />
            </div>
          </div>
        </div>
        <div className="py-5 flex flex-row justify-between">
          {family_id && (
            <div>
              <span
                onClick={handleDelete}
                className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
              >
                Supprimer cette famille
              </span>
            </div>
          )}
          <div className="flex justify-end">
            <button
              onClick={goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
            >
              Annuler
            </button>
            <button
              type="submit"
              className={classNames(
                "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
              )}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminFamiliesAddEditPage;
