import useSWR from "swr";
import Api, { API_V1_BASE_URL } from "../_helpers/api";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const useReference = (id) => {
  if (!!id) {
    const { data, error } = useSWR(
      id ? `${API_V1_BASE_URL}/references/${id}` : null,
      getFetcher
    );
    return {
      reference: data,
      isLoading: !error && !data,
      isError: error,
    };
  }
  return {
    reference: null,
    isLoading: null,
    isError: null,
  };
};
export const useReferences = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/references`, getFetcher);
  return {
    references: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useReferencesOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_references_options`,
    getFetcher
  );
  return {
    options: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const createReference = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/references`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const updateReference = (id, data, callback) => {
  if (!!id) {
    Api.put(
      `${API_V1_BASE_URL}/references/${id}`,
      { ...data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      .then(callback);
  }
};

export const deleteReference = (id, callback) => {
  if (!!id) {
    Api.delete(`${API_V1_BASE_URL}/references/${id}`).then(callback);
  }
  return new Error("id is empty");
};
