import GenericLayout from "../layouts/GenericLayout";
import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/utils/Loading";
import Error from "../components/Error";
import { useRealisation } from "../_services/realisation-service";
import Breadcrumbs from "../components/breadcrumbs";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";
import RealisationProductsSlider from "./RealisationProductsSlider";

const RealisationPage = () => {
  const { realisation_slug } = useParams();
  const { realisation, isError, isLoading } = useRealisation(realisation_slug);
  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  const { description, name, pictures_data } = realisation.data.attributes;
  const universes = getAllIncludedResourcesForAGivenType(
    realisation,
    "universe"
  );
  const products = getAllIncludedResourcesForAGivenType(realisation, "product");
  const realisationBC = [
    { label: "Adm", link: "/" },
    { label: "nos-realisations", link: "/univers" },
    {
      label: universes[0].attributes.name,
      link: `/nos-realisations/${universes[0].attributes.slug}`,
    },
    { label: name, link: `/realisations/${realisation_slug}` },
  ];

  return (
    <GenericLayout
      title={`${universes[0].attributes.name} | ${name} | Adm mobilier`}
      h1={name}
    >
      <div className="xl:mx-48 md:mx-12 mx-4 sm:mb-28">
        <Breadcrumbs breadCrumbs={realisationBC} />
        <div className="flex xl:flex-row flex-col gap-x-6">
          <div className="flex-1 flex-col">
            <div className="flex sm:flex-col flex-col-reverse xl:mr-24 mb-6">
              <div className="flex-1">
                <div className="flex items-end font-freight sm:justify-start justify-center">
                  <h2 className="sm:text-40 text-32 sm:mr-4 mr-0 mt-6">
                    {name}
                  </h2>
                </div>
                <p className="font-grotesque sm:text-20 text-16 sm:my-8 my-4 text-justify">
                  {description}
                </p>
              </div>
              {pictures_data && pictures_data[0] && (
                <div
                  className="xl:flex-1 aspect-w-1 aspect-h-1 bg-cover bg-center rounded"
                  style={{
                    backgroundImage: `url(${pictures_data[0].url})`,
                  }}
                />
              )}
            </div>

            {pictures_data && pictures_data[1] && (
              <div className="flex flex-col items-stretch">
                <div className="xl:self-end w-full xl:w-80">
                  <div
                    className="aspect-h-12 aspect-w-9 bg-cover bg-center rounded"
                    style={{
                      backgroundImage: `url(${pictures_data[1].url})`,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {pictures_data && pictures_data[2] && (
            <div className="flex-1 xl:mt-0 mt-8">
              <div
                className="xl:flex-1 aspect-w-8 aspect-h-12 w-full bg-cover bg-center rounded"
                style={{
                  backgroundImage: `url(${pictures_data[2].url})`,
                }}
              />
            </div>
          )}
        </div>

        <h2 className="sm:block hidden sm:mb-2 mb-6 sm:mt-24 sm:mt-8 mt-6 font-freight sm:text-40 text-2xl text-left">
          Liste des produits proposés par ADM
        </h2>
        <h2 className="sm:hidden block sm:mb-2 mb-6 sm:mt-24 sm:mt-8 mt-6 font-freight sm:text-40 text-2xl text-left">
          Liste des produits proposés
        </h2>

        <div className="flex flex-col sm:flex-row gap-x-6 sm:gap-y-0 gap-y-10">
          <RealisationProductsSlider products={products} />
        </div>
        {/*<div classNameName={"divide-y divide-black"}>*/}
        {/*  {inspiration.map((realisation, index) => {*/}
        {/*      return (*/}
        {/*        <Thumb*/}
        {/*          realisation_id={realisation.id}*/}
        {/*          isReverse={index % 2 !== 0}*/}
        {/*        />*/}
        {/*      );*/}
        {/*    }*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </GenericLayout>
  );
};

export default RealisationPage;
