/* eslint-disable no-undef */
import React, { useEffect } from "react";
import GenericLayout from "../layouts/GenericLayout";
import ProductGrid from "../components/products/ProductGrid";
import { useSelector, useDispatch } from "react-redux";
import { searchProductsSlice } from "../redux/slices/products";
import { getCategoryName } from "../redux/slices/category";

import {
  filterSlice,
  getFilterByCategory,
  getProductsByCategoryFiltered,
} from "../redux/slices/filter";
import { useLocation, useParams } from "react-router-dom";
import FilterProduit from "../components/filter-produit";
import PaginatedItems from "../components/pagination/Pagination";

export const isRegular = (thumb_type) => {
  return thumb_type === "regular";
};

export const isLarge = (thumb_type) => {
  return thumb_type === "large";
};

const CategoryPage = () => {
  let { category_slug } = useParams();

  const dispatch = useDispatch();
  const location = useLocation();

  const {
    products,
    nbPage,
    loading,
    category_id,
    paginationRequestedPageNumber,
  } = useSelector((state) => state.products);
  const { category_name } = useSelector((state) => state.categories);
  const { tags_name_list, selected_material_ids } = useSelector(
    (state) => state.filters
  );

  useEffect(() => {
    // runs on location, i.e. route, change - used to clear category_id in order to avoid preselection when navigate from one product page to another
    dispatch(filterSlice.actions.set_tags_name_list([]));
    dispatch(filterSlice.actions.set_selected_material_ids([]));
  }, [location]);

  useEffect(() => {
    if (category_slug) {
      dispatch(searchProductsSlice.actions.SET_IS_FILTERED_MODE(false));

      dispatch(
        searchProductsSlice.actions.SET_PAGINATION_REQUESTED_PAGE_NUMBER(0)
      );
      dispatch(
        searchProductsSlice.actions.SET_CATEGORY_ID(
          category_slug.split("--")[0]
        )
      );
      dispatch(getCategoryName(category_slug.split("--")[0]));
    }
  }, [category_slug]);

  useEffect(() => {
    if (category_id) {
      dispatch(getFilterByCategory(category_id));
    }
  }, [category_id]);

  // useEffect(() => {
  //   if (category_id) {
  //     dispatch(
  //       getProductsByCategory({
  //         category_id: category_id,
  //         paginationRequestedPageNumber: paginationRequestedPageNumber + 1,
  //       })
  //     );
  //   }
  // }, [category_id, paginationRequestedPageNumber]);

  // Handle filtering
  useEffect(() => {
    if (category_id) {
      dispatch(
        getProductsByCategoryFiltered({
          category_id: category_id,
          material_ids: selected_material_ids,
          tags_name_list: tags_name_list,
          paginationRequestedPageNumber: paginationRequestedPageNumber,
        })
      );
    }
  }, [tags_name_list, selected_material_ids, paginationRequestedPageNumber]);

  return (
    <GenericLayout
      title={`${category_name} | Adm mobilier`}
      h1={category_name || "Categorie"}
    >
      <div className="sm:mx-4">
        <FilterProduit categoryMode={true} />
        <ProductGrid loading={loading} products={products || []} />
        {!!products?.length && (
          <div className="flex justify-center mt-12 mb-8">
            <PaginatedItems
              pageCount={nbPage}
              currentPage={1}
              isSearchMode={false}
              categoryMode={true}
            />
          </div>
        )}
      </div>
    </GenericLayout>
  );
};

export default CategoryPage;
