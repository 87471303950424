/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { usePagination } from "react-instantsearch";
import "./styles.css";

const Pargination = forwardRef(({ onChangePage }, ref) => {
  const { nbPages, refine, currentRefinement } = usePagination();

  useImperativeHandle(ref, () => ({
    setPageZero() {
      refine(0);
    },
    setPage(page) {
      refine(page);
    },
  }));

  const handleChangaPage = useCallback(
    (ev) => {
      window.scroll({ top: 0 });
      onChangePage && onChangePage(ev.selected);
      setTimeout(() => {
        refine(ev.selected);
      }, 500);
    },
    [onChangePage]
  );

  return (
    <ReactPaginate
      className="flex flex-row items-center pagination"
      pageLinkClassName="page-pagination hover:bg-kakhi-300 hover:text-white !cursor-pointer border-t border-b border-l py-2 px-4 border-kakhi-400 font-brandon text-sm sm:text-lg"
      pageClassName="li-page-pagination"
      breakLabel="..."
      breakLinkClassName="!cursor-pointer break-pagination border-t border-b border-l py-2 px-4 border-kakhi-400 font-brandon text-sm sm:text-lg"
      nextLabel={<ChevronRightIcon className="h-4 w-4" />}
      nextClassName="next-pagination m-2"
      activeLinkClassName="bg-kakhi-400 text-white"
      previousClassName="prev-pagination m-2"
      onPageChange={handleChangaPage}
      pageCount={nbPages}
      forcePage={currentRefinement}
      previousLabel={<ChevronLeftIcon className="h-4 w-4" />}
      renderOnZeroPageCount={false}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
    />
  );
});

export default Pargination;
