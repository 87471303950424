import axios from "axios";
import { apiKeyAlogolia, indexName, projectId } from "../../packs";
import algoliasearch from "algoliasearch";

export const FACETS = [
  "categories.group",
  "categories.name",
  "materials.name",
  "tags.name",
];

export async function fetchFacetsData(
  facets = FACETS,
  indexNameParams = indexName,
  facetFilters = []
) {
  try {
    const client = algoliasearch(projectId, apiKeyAlogolia);
    const index = client.initIndex(indexNameParams);

    index
      .searchForFacetValues({
        facetName: "category", // Nom de la facette
        facetQuery: "", // La requête de facette, laissez vide pour obtenir toutes les valeurs
        maxFacetHits: 30, // Nombre maximum de valeurs de facettes à récupérer
      })
      .then(({ facetHits }) => {
        console.log(facetHits);
      })
      .catch((err) => {
        console.error(err);
      });

    return true;
  } catch (error) {
    console.error("Error fetching facets:", error);
    throw error;
  }
}
