import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { isLarge, isRegular } from "./CategoryPage";
import { useMediaQuery } from "react-responsive";

const RealisationProductsSlider = ({ products }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <div className="flex lg:flex-row flex-col w-full my-4">
      <Swiper
        spaceBetween={15}
        slidesPerView={isTablet ? 1 : 4}
        centeredSlides={true}
        loop={false}
        breakpoints={{
          640: {
            // slidesPerView: 1.5,
            spaceBetween: 10,
          },
          768: {
            // slidesPerView: 3.5,
            spaceBetween: 25,
          },
          1024: {
            // slidesPerView: 4.25,
            spaceBetween: 30,
            centeredSlides: false,
          },
        }}
        className={"w-full"}
      >
        {products.map(({ attributes }, index) => {
          return (
            <SwiperSlide
              key={`${attributes.id}__${index}`}
              className={classNames(
                { "w-1/2 sm:w-1/5": isRegular(attributes.thumb_type) },
                { "w-full sm:w-2/5": isLarge(attributes.thumb_type) },
                "rounded sm:leading-normal leading-tight sm:m-0 mb-8 h-auto"
              )}
            >
              <a
                href={`/mobiliers-accessoires/${attributes.slug}`}
                className=""
              >
                <div
                  className={classNames(
                    {
                      "aspect-h-9 aspect-w-8": isRegular(attributes.thumb_type),
                    },
                    {
                      "aspect-w-16 aspect-h-9": isLarge(attributes.thumb_type),
                    },
                    "rounded bg-center bg-cover"
                  )}
                  style={{
                    backgroundImage: `url(${attributes.cover_picture_url})`,
                  }}
                />
                <h2 className="mt-2 font-freight sm:text-32 text-base text-bold ">
                  {attributes.name}
                </h2>
                {/*<p className="font-grotesque text-justify sm:text-left text-xl mr-24 sm:block hidden line-clamp-2">*/}
                {/*  {attributes.description}*/}
                {/*</p>*/}
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default RealisationProductsSlider;
