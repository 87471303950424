import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import Api from "../../../_helpers/api";
import useSWR from "swr";
import {
  createFilter,
  deleteFilter,
  updateFilter,
} from "../../../_services/filter_services";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getTags } from "../../../redux/slices/tag";

const checkRangeDate = (value) =>
  Number.isNaN(value) && moment(value).isValid();

export const columns = [
  {
    label: "Nom du produit",
    columnName: "name",
    type: "text",
    value_type: "string",
  },
  {
    label: "Structures",
    columnName: "structure",
    type: "text",
    value_type: "string",
  },
  {
    label: "Description",
    columnName: "description",
    type: "text",
    value_type: "string",
  },
  {
    label: "Largeur",
    columnName: "width",
    type: "number",
    value_type: "float",
  },
  {
    label: "Hauteur",
    columnName: "height",
    type: "number",
    value_type: "float",
  },
  {
    label: "Profondeur",
    columnName: "depth",
    type: "number",
    value_type: "float",
  },
  {
    label: "Poids",
    columnName: "weight",
    type: "number",
    value_type: "float",
  },
  {
    label: "Date de sortie",
    columnName: "release_date",
    type: "date",
    value_type: "datetime",
  },
];
// range: 0, boolean: 1, value: 2, string_search: 3, date_range: 4, tag: 5
export const filterTypes = [
  {
    value: 5,
    label: "tag",
    columns: null,
    metaValue: "tag",
    isRange: false,
  },
  {
    value: 2,
    label: "valeur",
    columns: ["width", "height", "depth", "weight", "name", "release_date"],
    metaValue: "value",
    isRange: false,
  },
  {
    value: 0,
    label: "intervalle numerique",
    columns: ["width", "height", "depth", "weight"],
    metaValue: "range",
    isRange: true,
  },
  {
    value: 4,
    label: "intervalle de temps",
    columns: ["release_date"],
    metaValue: "date_range",
    isRange: true,
  },
];

const fetcher = (url) =>
  Api.get(url).then((response) => response.data.data.attributes);

const AdminFiltersAddEditPage = () => {
  const navigate = useNavigate();
  let { filter_id } = useParams();
  const { tags } = useSelector((state) => state.tags);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    column_name: null,
    filter_type: null,
    name: null,
    value: null,
    value_secondary: null,
    value_type: null,
  });

  // states
  const [errors, setErrors] = useState({
    value: false,
    value_secondary: false,
  });
  const [isEditMode] = useState(!!filter_id);
  const [isLoaded] = useState(true);

  const [columnsState, setColumnsState] = useState(columns);
  const [valueType, setValueType] = useState("text");
  const [activeColumnSelect, setActiveColumnSelect] = useState(false);
  const [isRange, setIsRange] = useState(false);
  const [isTag, setIsTag] = useState(false);

  const { data, error } = useSWR(
    () => (filter_id ? `/api/v1/filters/${filter_id}` : null),
    fetcher
  );

  const goBack = () => {
    navigate("/admin/filters");
  };

  const handleChange = (e) => {
    setFormData((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const submitted_data = {
      ...formData,
      filter_type: parseInt(formData.filter_type),
      value_secondary: isRange ? formData.value_secondary : null,
    };

    return isEditMode
      ? updateFilter(filter_id, submitted_data, goBack)
      : createFilter(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteFilter(filter_id, goBack);
  };

  const _setColumnsState = (filterType) => {
    const _columnsState = columns.filter(
      (item) =>
        filterType.columns &&
        filterType.columns.length &&
        filterType.columns.includes(item.columnName)
    );
    return _columnsState;
  };

  const handleChangeFilterType = (value) => {
    const filterType = filterTypes.find((item) => item.value == value);
    if (filterType) {
      if (filterType.metaValue !== "tag") {
        const _columnsState = _setColumnsState(filterType);
        setActiveColumnSelect(true);
        setColumnsState(_columnsState);
        setIsTag(false);
      } else {
        setActiveColumnSelect(false);
        setColumnsState([]);
        setIsTag(true);
      }
      setIsRange(filterType.isRange);
      setFormData((state) => ({ ...state, value: null, column_name: null }));
    }
  };

  const errorRange = () => {
    if (
      formData.value_secondary &&
      (checkRangeDate(formData.value) ||
        checkRangeDate(formData.value_secondary))
    ) {
      const error =
        new Date(formData.value) > new Date(formData.value_secondary);
      setErrors({ value: error, value_secondary: error });
    } else if (
      formData.value_secondary &&
      !isNaN(formData.value) &&
      !isNaN(formData.value_secondary)
    ) {
      const error = Number(formData.value) > Number(formData.value_secondary);
      setErrors({ value: error, value_secondary: error });
    }
  };

  useEffect(() => {
    errorRange();
  }, [formData.value, formData.value_secondary]);

  useEffect(() => {
    if (data) {
      const type = filterTypes.find(
        (item) => item.metaValue == data.filter_type
      );
      setFormData({
        ...data,
        filter_type: type?.value,
      });
      if (type && type.metaValue !== "tag") {
        const _columnsState = _setColumnsState(type);
        setActiveColumnSelect(true);
        setColumnsState(_columnsState);
        setIsRange(type.isRange);
      } else if (type && type.metaValue === "tag") {
        setIsTag(true);
      }
    }
  }, [data]);

  useEffect(() => {
    dispatch(getTags());
  }, []);

  const handleChangeColumnName = (value) => {
    if (value) {
      const column = columns.find((item) => item.columnName == value);
      setFormData((state) => ({ ...state, value_type: column.value_type }));
      if (column) setValueType(column.type);
    }
  };

  if (!data && filter_id) {
    return <div>Chargement du filtre...</div>;
  }

  if (error) {
    return <div> Erreur dans le chargement ...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>
          {isEditMode ? "Edition d'un" : "Création d'un nouveau "} Filtre
        </h3>
      </div>
      <form onSubmit={onSubmit} className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Informations générales
              </h3>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    value={formData.name || ""}
                    name="name"
                    required
                    onChange={handleChange}
                    className={classNames(
                      "max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm rounded",
                      { "border-gray-300": !errors.name },
                      { "border-myred": errors.name }
                    )}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Type
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    value={formData.filter_type}
                    name="filter_type"
                    required
                    onChange={(e) => {
                      handleChange(e);
                      handleChangeFilterType(e.target.value);
                    }}
                    className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                  >
                    <option value=""></option>
                    {filterTypes.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {columnsState && !isTag ? (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="column_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Le filtre s'applique sur
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        name="column_name"
                        value={formData.column_name || ""}
                        required
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeColumnName(e.target.value);
                        }}
                        className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                      >
                        <option value=""></option>
                        {columnsState.map(({ label, columnName }) => (
                          <option
                            key={columnName}
                            disabled={!activeColumnSelect}
                            value={columnName}
                          >
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Valeur(s)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                      <input
                        name="value"
                        value={formData.value || ""}
                        onChange={handleChange}
                        type={valueType}
                        className={classNames(
                          "max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm rounded",
                          { "border-gray-300": !errors.value },
                          { "border-myred": errors.value }
                        )}
                      />
                      {isRange && (
                        <>
                          <span className="mx-4">Jusqu'à</span>
                          <input
                            name="value_secondary"
                            value={formData.value_secondary || ""}
                            onChange={handleChange}
                            type={valueType}
                            className={classNames(
                              "max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm rounded",
                              { "border-gray-300": !errors.value_secondary },
                              { "border-myred": errors.value_secondary }
                            )}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="column_name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Tag
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      name="value"
                      value={formData.value || ""}
                      required
                      onChange={handleChange}
                      className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                    >
                      <option value=""></option>
                      {tags.map(({ attributes }) => (
                        <option key={attributes.id} value={attributes.name}>
                          {attributes.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="py-5 flex flex-row justify-between">
          <div>
            {isEditMode && (
              <span
                onClick={handleDelete}
                className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
              >
                Supprimer le filtre
              </span>
            )}
          </div>
          <div className="flex justify-end">
            <button
              onClick={goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500"
            >
              Annuler
            </button>
            <button
              disabled={!isLoaded}
              type="submit"
              className={classNames(
                !isLoaded
                  ? "bg-kakhi-400"
                  : "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
              )}
            >
              {isLoaded
                ? isEditMode
                  ? "Sauvegarder les modifications"
                  : "Créer un nouveau filtre"
                : "Chargememt de la photo en cours"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminFiltersAddEditPage;
