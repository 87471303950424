import React from "react";

const ButtonUnderLineEffect = () => {
  return (
    <a
      href="/mobiliers-accessoires"
      className="hover:text-gray-600 lg:hover:bg-white demande_devis_btn_hero font-avenir-text text-avenir-14 2xl:text-avenir-16 px-[40px] 2xl:px-[48px] py-[12px] 2xl:py-[16px] border border-kakhi-400 lg:border-white rounded-lg"
    >
      <span className="container_btn_hero">
        <span className="wrapper_btn_hero">
          <span className="label_btn_hero">Découvrir nos produits</span>
          <span className="underline_container_btn_hero border-b-gray-600">
            <span />
          </span>
        </span>
      </span>
    </a>
  );
};

export const HeroHomepage = () => {
  return (
    <div id="home-hero" className="relative w-full px-[20px]">
      <div className="relative w-full z-0 h-[410px] lg:h-[490px] 2xl:h-[664px] overflow-hidden rounded-xl">
        <img
          src={
            "https://res.cloudinary.com/dlnlpzitl/image/upload/v1697788132/hero_sgimev.jpg"
          }
          className="w-full h-full object-cover object-center hidden lg:block"
          alt="ADM Mobilier"
        />
      </div>
      <div className="hidden lg:block absolute w-full h-full z-[5] top-0 left-0 px-[20px] overflow-hidden">
        <div
          style={{ background: "rgba(75, 79, 64, 0.75)" }}
          className="w-full h-full rounded-xl"
        />
      </div>
      <div className="absolute z-10 top-0 left-0 w-full h-full px-[20px] flex items-center justify-center">
        <div className="w-full lg:max-w-[929px] text-kakhi-500 lg:text-white flex flex-col items-left lg:items-center lg:px-[40px] sm:px-0">
          <h1 className="font-playfair tracking-[1px] lg:tracking-[1.6px] 2xl:tracking-[2.56px] text-[22px] lg:text-[40px] 2xl:text-[48px] uppercase lg:text-center">
            Créez un espace de restauration unique
          </h1>
          <div className="w-full max-w-[613px] mt-[32px] lg:mt-[24px] 2xl:mt-[32px]">
            <p className="font-avenir-text text-kakhi-400 lg:text-white lg:text-center text-14 2xl:text-20 text-left">
              Vos idées, votre ambiance, notre expertise, <br />
              Explorez l&apos;infini du mobilier personnalisable avec ADM
              Mobilier
            </p>
          </div>
          <div className="mt-[32px] lg:mt-[24px] 2xl:mt-[32px]">
            <ButtonUnderLineEffect />
          </div>
        </div>
      </div>
    </div>
  );
};
