import React from "react";

const InspirationItem = ({ inspiration }) => {
  const { title, cover_picture_url, description, slug } =
    inspiration.attributes;

  return (
    <a href={`/nos-inspirations/${slug}`} className="group relative poi">
      <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded overflow-hidden group-hover:opacity-75 lg:h-80">
        <img
          src={cover_picture_url}
          alt={name}
          className="w-full h-full object-center object-cover lg:w-full lg:h-full"
        />
      </div>
      <div className="flex justify-between">
        <div>
          <h2 className="font-freight xl:text-40 sm:text-3xl text-20 font-bold first-letter:capitalize">
            {title}
          </h2>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </a>
  );
};

export default InspirationItem;
