import React, { useEffect, useState } from "react";
import { bulkUpdateProductsByCategory } from "../../../_services/category-services";
import { useNavigate, useParams } from "react-router-dom";
import _, { isEmpty, orderBy, uniqueId } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getSpecificIncludedHasOneRelationship } from "../../../_helpers/jsonapi-deserializer";
import classNames from "classnames";
import Api, { API_V1_BASE_URL } from "../../../_helpers/api";

const AdminCategoriesAddEditPage = () => {
  const navigate = useNavigate();

  const resetDisplayPositions = (list) => {
    return list.map((c, index) => {
      const slide = c;
      slide.attributes.order = index + 1;
      return slide;
    });
  };

  const [list, setList] = useState([]);
  const [includedData, setIncludedData] = useState([]);
  let { category_id } = useParams();
  const [content, setContent] = useState([]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(resetDisplayPositions(items));
    bulkUpdateProductsByCategory(category_id, items);
  }

  const [products_categories, setProductsCategories] = useState([]);

  useEffect(() => {
    Api.get(`${API_V1_BASE_URL}/category-products/${category_id}/products`)
      .then((response) => {
        console.log("response", response);
        setProductsCategories(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  console.log(products_categories);

  useEffect(() => {
    if (!isEmpty(list)) {
      setContent(resetDisplayPositions(list));
    }
  }, [list]);

  useEffect(() => {
    if (products_categories) {
      setList(orderBy(products_categories.data, ["attributes.order"], ["asc"]));
      setIncludedData(products_categories.included);
    }
  }, [products_categories]);

  // console.log(products_categories);
  // console.log(_.orderBy(products_categories.data, ["order"], ["asc"]));
  // console.log(
  //   _.map(
  //     _.orderBy(products_categories.data, ["order"], ["asc"]), (p) => {
  //       return `${p.attributes.order} ${p.attributes.product_id}`;
  //     })
  // );
  return (
    <div className={"flex flex-row gap-8 relative grid grid-cols-4 w-full p-4"}>
      <div className="flex flex-col col-span-3">
        <div className={"flex flex-row justify-between items-center"}>
          <h3 className={"text-3xl py-5"}>
            Ordre des produits dans la categorie{" "}
            <b>
              {" "}
              {
                _.find(products_categories.included, (includeObject) => {
                  return includeObject.type === "category";
                }).attributes.name
              }
            </b>
          </h3>
        </div>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                className="flex flex-col gap-y-3 col-span-1"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {list.map(({ attributes, relationships }, index) => {
                  // console.log(relationships.product.data);
                  // console.log(attributes);
                  // console.log(getSpecificIncludedHasOneRelationship(includedData, "product", relationships.product?.data));

                  const { cover_picture_url, name, id } =
                    getSpecificIncludedHasOneRelationship(
                      includedData,
                      "product_light",
                      relationships.product?.data
                    ).attributes;

                  return (
                    <Draggable
                      key={uniqueId("sortable__item")}
                      draggableId={attributes.product_id}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          className={
                            "border shadow flex flex-row bg-skin-400 items-center"
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={"w-24"}>
                            <div
                              className={
                                "block aspect-w-1 aspect-h-1 bg-cover bg-center rounded"
                              }
                              style={{
                                backgroundImage: `url(${cover_picture_url})`,
                              }}
                            />
                          </div>
                          <div className={"text-xl flex flex-row p-4"}>
                            <span>{name} </span>
                          </div>
                          <span>#{attributes.order}</span>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <div className="py-5 flex flex-row justify-between">
          <button
            type={"button"}
            onClick={() => navigate(`/admin/categories/${category_id}`)}
            className={classNames(
              "bg-transparent border border-kakhi-700 text-kakhi-700 hover:text-skin-500 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
              "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white "
            )}
          >
            Valider et retourner à la sous catégorie
          </button>
        </div>
        {/*{*/}
        {/*  _.map(products_categories, (p) => {*/}
        {/*    return (*/}
        {/*      <div className={"col-span-2 row-span-1"}>{p.attributes.order} {p.attributes.product_id}</div>*/}
        {/*    );*/}
        {/*  })*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default AdminCategoriesAddEditPage;
