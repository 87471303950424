import React, { useRef } from "react";
import GenericLayout from "../layouts/GenericLayout";
import { gsap } from "gsap";
import _ from "lodash";
import showroom from "../../../assets/images/showroom.jpg";
import livraison from "../../../assets/images/livraison_montage.jpg";
import entrepot from "../../../assets/images/entrepot.jpg";
import HeroSection from "../components/HeroSection";

const Services = () => {
  const content = [
    {
      title: "Show-room",
      body:
        "<p>Sur rdv, du lundi au vendredi avec un de nos" +
        " commerciaux, nos deux salles d’exposition (mobilier d’intérieur" +
        " / mobilier de terrasse) vous permettent de tester et comparer plus" +
        " de 300 modèles.</p>" +
        "<p>Notre matériauthèque met à votre disposition nos échantillons" +
        " de revêtements de sièges, nos nuanciers de teintes bois et coloris " +
        "de structures, et nos gammes de stratifiés. Tout ce qu’il faut pour" +
        " personnaliser et harmoniser les matières" +
        " et les coloris de vos meubles.</p>",
      imageURL: showroom,
      link: "/services/showroom",
    },
    {
      title: "Entrepôt / Stockage / Atelier",
      body:
        "<p>Avec plus de 5 000 m3 de capacité de stockage, notre entrepôt nous permet de disposer d’un stock permanent." +
        " Cela nous donne la possibilité de livrer rapidement, lors de la saison des terrasses par exemple. " +
        "Aussi celle de raccourcir les délais des commandes contenant des composants standards. </p>" +
        "<p>Grâce à ce grand volume, nous sommes en mesure de regrouper et stocker en sécurité la totalité" +
        " des produits d’une commande au fur et à mesure de l’arrivée des marchandises de nos différents " +
        "fabricants. Cela nous permet d’assurer sereinement la place de stockage nécessaire pour les quantités " +
        "importantes de chantiers de 200 à 300 places, avant leur livraison.</p>" +
        "<p>Pour certains de nos clients (chaînes de restaurants, ou réseau de franchisés), ayant des besoins" +
        " récurrents ou des impératifs de réapprovisionnement rapide, nous avons la possibilité de stocker" +
        " en permanence plusieurs centaines de modèles. Enfin, notre atelier de préparation nous procure la " +
        "possibilité de faire le prémontage de certains meubles, ou de leurs composants (les piètements de" +
        " tables par exemple), avant la livraison, ce qui apporte un gain de temps précieux, " +
        "pour nous comme pour nos clients !.</p>",
      imageURL: entrepot,
      link: "/services/entrepot-stockage-atelier",
    },
    {
      title: "Livraisons - Montage",
      body:
        "<p>Chez ADM Mobilier, c’est clair pour chacun de nos collaborateurs : " +
        "nous devons à nos clients un langage de vérité, et nous croyons en ces" +
        " quelques maîtres-mots.</p>",
      imageURL: livraison,
      link: "/services/livraison-montage",
    },
  ];

  const serviceRef = useRef(null);

  const handleScroll = () => {
    const q = gsap.utils.selector(presentationPageRef);
    gsap.to(q(".imganim"), {
      scale: 1.5,
      duration: 1,
      scrollTrigger: {
        trigger: "imganim",
        scrub: true,
        start: "top bottom",
      },
    });
  };

  const servicesBreadCrumbs = [
    { label: "Adm mobilier", link: "/" },
    { label: "Services", link: "/services" },
  ];

  return (
    <GenericLayout title="Adm Mobilier : Nos Services" h1="Services">
      <div
        ref={serviceRef}
        onScroll={handleScroll}
        className={" xl:mx-24 md:mx-12 mx-4"}
      >
        <div className="sm:space-y-20 space-y-12">
          {content.map((article, index) => {
            return (
              <HeroSection
                key={_.uniqueId()}
                index={index}
                article={article}
                breadcrumbs={servicesBreadCrumbs}
                isH2
              />
            );
          })}
        </div>
      </div>
    </GenericLayout>
  );
};

export default Services;
