/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import PopoverLink from "./PopoverLink";
import { Accordion } from "./utils/Accordions";
import { useLocation } from "react-router-dom";
import SearchInput from "./search-input/SearchInput";
import SousMenuService from "./service-sous-menu/ServiceSousMenu";
import { uniqueId } from "lodash";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useDispatch } from "react-redux";
import { clearSearch } from "../../../javascript/src/redux/slices/products";

import algoliasearch from "algoliasearch";
import { apiKeyAlogolia, indexName, projectId } from "../../packs";

const navigation = [
  // { name: "Réalisations", href: "/nos-realisations", current: true },
  { name: "Services", href: "/services", current: false },
  { name: "Références", href: "/references", current: false },
  { name: "Inspirations", href: "/nos-inspirations", current: false },
  { name: "Présentation", href: "/presentation", current: false },
  { name: "Contact", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { pathname } = useLocation();
  const [maxHeight, setMaxHeight] = useState();

  const isMobile = useMediaQuery(640);

  const dispatch = useDispatch();

  const clearSearchFunction = () => {
    dispatch(clearSearch());
  };

  // Define a state variable to manage the open/close state of the Disclosure.Panel
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the state
  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  useEffect(() => {
    const fixMaxHeight = () => {
      setMaxHeight(window.innerHeight - 64);
    };
    fixMaxHeight();
    window.addEventListener("resize", fixMaxHeight);

    return () => {
      window.removeEventListener("resize", fixMaxHeight);
    };
  }, []);

  return (
    <Disclosure
      as="header"
      className="bg-skin-500 sticky top-0 w-full z-50"
      id="navbar"
    >
      {({ open }) => (
        <div className="grid grid-cols-12 px-[20px]">
          <div className="col-span-full xl:col-start-2 xl:col-end-12 w-full flex py-[20px] sm:pb-0 sm:pt-[16px]">
            <div className="relative z-10 flex">
              <div className="flex items-center lg:ml-4 xl:ml-0">
                <a href="/" onClick={() => clearSearchFunction()}>
                  <img
                    className="block h-auto w-[71px] sm:w-32"
                    src={logo}
                    alt="Workflow"
                  />
                </a>
              </div>
            </div>
            <div className="lg:divide-y lg:divide-brown-500 lg:px-8 lg:mx-auto flex w-full lg:block justify-end">
              <div className="relative sm:h-16 sm:w-full flex justify-between z-50">
                {!isMobile && (
                  <div className="relative z-0 flex-1 px-2 flex items-center justify-center lg:absolute lg:inset-0">
                    <div className="w-full lg:max-w-lg">
                      <SearchInput />
                    </div>
                  </div>
                )}
                <div className="relative z-10 items-center flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    onClick={togglePanel}
                    className="rounded sm:p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-skin-800"
                  >
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XMarkIcon
                        key={"x_icon"}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars3Icon
                        key={"menu_icon"}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
              <nav
                className="hidden lg:flex lg:py-2  lg:space-x-8 justify-center"
                aria-label="Global"
              >
                <PopoverLink
                  title={"Produits"}
                  path={"/mobiliers-accessoires"}
                />
                {navigation.map((item) => {
                  if (item.name === "Services") {
                    return (
                      <SousMenuService
                        key={uniqueId("service")}
                        label={item.name}
                      />
                    );
                  }
                  return (
                    <a
                      key={uniqueId("accordion__")}
                      href={item.href}
                      onClick={() => clearSearchFunction()}
                      className={classNames(
                        item.href === pathname
                          ? "underline"
                          : "text-kakhi-500 hover:bg-kakhi-50 hover:text-kakhi-500",
                        "rounded py-2 px-3 inline-flex items-center text-base font-brandon decouvrir"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>

          {isMobile && (
            <div className="col-span-full pb-[8px]">
              <SearchInput />
            </div>
          )}

          <Disclosure.Panel
            as="nav"
            style={{ maxHeight: maxHeight }}
            className="lg:hidden overflow-auto col-span-full xl:col-start-2 xl:col-end-12"
            aria-label="Global"
            open={isOpen}
          >
            <div className="pt-2 pb-3 px-2 space-y-1">
              <Accordion title={"Mobiliers et Accessoires"}>
                <PopoverLink
                  title={"Mobiliers et Accessoires"}
                  path={"/mobiliers-accessoires"}
                />
              </Accordion>
              {navigation.map((item) => {
                if (item.name === "Services") {
                  return (
                    <Accordion key={uniqueId("accordion__")} title={item.name}>
                      <SousMenuService label={item.name} />
                    </Accordion>
                  );
                }
                return (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.href === pathname
                        ? "bg-kakhi-100 text-kakhi-500"
                        : "text-kakhi-500 hover:bg-kakhi-50 hover:text-kakhi-500",
                      "block rounded py-2 px-3 text-base font-medium font-grotesque"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                );
              })}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
