import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GenericLayout from "../layouts/GenericLayout";
import Thumb from "../components/realisations/Thumb";
import _ from "lodash";
import Api from "../_helpers/api";

const UniversePage = () => {
  const { universe_id } = useParams();
  const [realisations, setRealisations] = useState([]);
  const [universe, setUniverse] = useState(null);

  useEffect(() => {
    // fetch realisations linked to a universe bases on his slug
    Api.get(`/api/v1/universes/${universe_id}/realisations`).then(
      ({ data }) => {
        setRealisations(data.realisations.data);
        setUniverse(data.universe);
      }
    );
  }, []);

  return (
    <GenericLayout
      title={`${universe?.name ?? "ADM"} | Adm mobilier`}
      h1={universe?.name ?? "ADM"}
      breadCrumbs={[
        {
          label: "Accueil",
          url: "/",
        },
        {
          label: "Références",
          url: "/references",
        },
        {
          label: `${universe?.name}`,
          url: `/nos-realisations/${universe_id ?? ""}`,
        },
      ]}
    >
      {/* <h1 className="text-center uppercase sm:text-72 text-24 mt-8 sm:mb-20 mb-10 font-freight stroke-marron tracking-wider">
        {name}
      </h1> */}
      <div className="xl:mx-48 md:mx-12 mx-4 sm:mb-28">
        {/* <Breadcrumbs breadCrumbs={universBC ?? ""} /> */}
        <div className={"flex flex-col space-y-4"}>
          {realisations.length > 0 &&
            realisations.map(({ attributes }, index) => {
              return (
                <Thumb
                  key={_.uniqueId("realisations__")}
                  attributes={attributes}
                  isReverse={index % 2 !== 0}
                />
              );
            })}
        </div>
      </div>
    </GenericLayout>
  );
};

export default UniversePage;
