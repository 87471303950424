import React from "react";
import classNames from "classnames";

const RealisationThumb = ({ attributes, isReverse = false }) => {
  const { id, name, description, cover_picture_url, place } = attributes;

  return (
    <div
      className={classNames(
        {
          "sm:flex-row-reverse": isReverse,
          "sm:flex-row": !isReverse,
        },
        "flex justify-between flex-col-reverse justify-between py-4"
      )}
    >
      <div className="xl:w-2/5 sm:w-1/3 flex-col container lg:px-5 max-w-lg">
        <div className="flex items-end font-freight sm:justify-start justify-center mb-0 lg:mb-4">
          <h2 className="sm:text-40 text-32 sm:mr-4 mr-0 mt-6 capitalize">
            "{name}"
          </h2>
          <p className="items-end sm:text-20 text-16 sm:ml-0 ml-4">-{place}-</p>
        </div>
        <div
          className="sm:space-y-10 space-y-6 font-grotesque text-kakhi text-justify sm:text-20 text-16 "
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {/* TODO manage page reference */}
        {/* <a
          className="button-translation4 cursor-pointer flex font-grotesque text-base lg:text-2xl mt-5 lg:mt-10 py-4 px-6 rounded shadow items-center"
          href={`/realisations/${id}`}
        >
          En savoir plus
        </a> */}
      </div>
      <div className={"sm:w-3/5"}>
        <div
          className="imganim aspect-w-1 aspect-h-1 bg-cover bg-center rounded"
          style={{
            backgroundImage: `url(${cover_picture_url})`,
          }}
        />
      </div>
    </div>
  );
};

export default RealisationThumb;
