import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { createUser } from "../_services/user-services";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { MyButtonNative } from "./my-button/MyButton";
import removeCharAt from "../_helpers/removeChartAt";

const NewsletterInput = ({ isV2 = false, isInPromoTerrase = false }) => {
  const { register, handleSubmit } = useForm();

  const [sent, setSent] = useState(false);

  const onSubmit = (data) =>
    createUser(
      {
        email: data.email,
        purpose: !isV2 ? "newsletter" : "promo_terrasse",
      },
      () => {
        setSent(true);
        if (isInPromoTerrase) {
          const fileUrl = `${
            process.env.BASE_URL?.charAt(process.env.BASE_URL?.length - 1) ===
            "/"
              ? removeCharAt(
                  process.env.BASE_URL,
                  process.env.BASE_URL?.length - 1
                )
              : process.env.BASE_URL
          }/files/ADM_Brochure_Promotions_Terrasse.pdf`;

          const a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);

          a.href = fileUrl;

          const filename = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          a.download = filename;

          a.click();

          document.body.removeChild(a);
        }
      }
    );

  return (
    <div
      className={classNames("flex flex-col items-start text-center", {
        "max-w-[337px] w-full lg:max-w-[447px]": !isV2,
      })}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames("flex items-center flex-nowrap w-full", {
          "gap-x-[16px]": isV2,
        })}
      >
        <div className="w-full">
          <label htmlFor="cta-email" className="sr-only">
            Email address
          </label>
          <input
            id="cta-email"
            {...register("email", { required: true })}
            type="email"
            className={classNames(
              "block w-full border border-transparent px-[20px]  lg:text-avenir-16 text-gray-900 placeholder-gray-500 focus:!outline-none",
              {
                "py-[12px] lg:px-[24px] lg:py-[17px] text-[12px]": !isV2,
                "h-[52px] rounded-md w-full xl:min-w-[387px]": isV2,
              }
            )}
            placeholder="Entrer votre e-mail"
          />
        </div>
        <div>
          {isV2 ? (
            <MyButtonNative label="Télécharger" type="submit" dark />
          ) : (
            <button
              type="submit"
              className="block w-full p-[15px] lg:p-[17px] bg-kakhi-500 text-[12px] lg:text-avenir-14  text-white shadow hover:bg-kakhi-400"
            >
              OK
            </button>
          )}
        </div>
      </form>
      {sent && (
        <div
          className={
            "hidden mx-18 sm:mx-auto sm:max-w-xl sm:flex flex-col text-center "
          }
        >
          {isV2 ? (
            <div className=" bg-kakhi-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-kakhi-100"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-kakhi-100">
                    Le téléchargeant est en cours
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-kakhi-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-kakhi-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-kakhi-500">
                    Vous êtes inscrit à notre newsletter
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsletterInput;
