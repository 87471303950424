/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../_helpers/api";

const initialState = {
  realizations: [],
  univers: null,
  univer: null,
  loading: false,
};

export const realizationssSlice = createSlice({
  name: "realizations",
  initialState,
  reducers: {
    SET_REALIZATIONS(state, action) {
      state.realizations = action.payload;
    },
    SET_LOADING(state, action) {
      state.loading = action.payload;
    },
    SET_UNIVERS(state, action) {
      state.univers = action.payload;
    },
    SET_UNIVER(state, action) {
      state.univer = action.payload;
    },
  },
});

export default realizationssSlice.reducer;

export const getRealizations = createAsyncThunk(
  "getRealizations",
  async (_, { dispatch }) => {
    try {
      dispatch(realizationssSlice.actions.SET_LOADING(true));
      const {
        data: { data },
      } = await Api.get("/api/v1/realisations");
      dispatch(realizationssSlice.actions.SET_REALIZATIONS(data));
      dispatch(realizationssSlice.actions.SET_LOADING(false));
      return data;
    } catch (e) {
      console.log(e);
      dispatch(realizationssSlice.actions.SET_LOADING(false));
      return [];
    }
  }
);

export const getRealisation = createAsyncThunk(
  "getRealisation",
  async (slug) => {
    try {
      const { data } = await Api.get(`/api/v1/realisations/${slug}`);
      return data;
    } catch (e) {
      return null;
    }
  }
);

export const getUniver = createAsyncThunk(
  "getUniver",
  async (slug, { dispatch }) => {
    try {
      const { data } = await Api.get(`/api/v1/universes/${slug}`);
      dispatch(realizationssSlice.actions.SET_UNIVER(data));
      return data;
    } catch (e) {
      return null;
    }
  }
);

export const getUnivers = createAsyncThunk(
  "getUnivers",
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await Api.get(`/api/v1/universes`);
      dispatch(realizationssSlice.actions.SET_UNIVERS(data));
      return data;
    } catch (e) {
      return null;
    }
  }
);
