/* eslint-disable no-undef */
import React, { Fragment, useEffect, useRef } from "react";
import { DirectUpload } from "@rails/activestorage";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import Api from "../../_helpers/api";
import { PortalWithState } from "react-portal";
import { Dialog, Transition } from "@headlessui/react";

const PicturesManager = ({
  pictures = [],
  setPictures,
  setPreviewURLs,
  setPicturesBlob,
  picturesBlob,
  previewURLs = null,
  setIsLoaded,
  resource = { name: "products", id: "", slug: null },
}) => {
  const pictureInputRef = useRef();

  const uploadFile = (input, file) => {
    setIsLoaded(false);
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        const dataURL = URL.createObjectURL(file);
        setPreviewURLs((dataPictures) =>
          [
            {
              url: dataURL,
              id: blob.id,
              signed_id: blob.signed_id,
            },
          ].concat(dataPictures)
        );
        setPicturesBlob((picturesBlob) =>
          [blob.signed_id].concat(picturesBlob)
        );
        setIsLoaded(true);
      }
    });
  };

  const handleFileUpload = (files) => {
    files.forEach((file, index) => {
      uploadFile(pictureInputRef, file, index);
    });
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles),
    maxFiles: 5,
  });

  const handleDeletePictureAttachment = (file) => {
    setPreviewURLs((previewURLs) =>
      _.filter(previewURLs, (previewURL) => previewURL.id !== file.id)
    );
    const index = picturesBlob.indexOf(file.signed_id);
    if (index > -1) {
      setPicturesBlob((picturesBlob) => picturesBlob.splice(index, 1));
    }

    if (resource.slug || resource.id) {
      Api.delete(
        `api/v1/${resource.name}/${resource.id}/attachment/${file.id}`
      ).then(({ data }) => {
        setPictures(data.data.attributes.pictures_data);
      });
    }
    // stopPropagation();
  };

  const Thumbs = () => {
    return (
      <>
        {_.filter(
          _.concat(previewURLs, pictures),
          (array) => array && array.length !== 0
        ).map((file) => {
          return (
            <PortalWithState
              key={_.uniqueId(`slide__`)}
              closeOnOutsideClick
              closeOnEsc
            >
              {({ openPortal, closePortal, isOpen, portal }) => (
                <>
                  <div
                    onClick={openPortal}
                    style={{
                      backgroundImage: `url(${file ? file.url : file})`,
                    }}
                    className={
                      "aspect-w-1 aspect-h-1 w-full bg-center bg-cover rounded hover:cursor-zoom-in col-span-1 h-20"
                    }
                  />
                  {portal(
                    <Transition.Root show={isOpen} as={Fragment}>
                      <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={closePortal}
                      >
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                          </Transition.Child>

                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                          >
                            &#8203;
                          </span>
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          >
                            <div className="relative inline-block align-bottom bg-white rounded px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                              <div>
                                <div className="mt-3 text-center sm:mt-5">
                                  <div className="mt-2">
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          file ? file.url : file
                                        })`,
                                      }}
                                      className={
                                        "aspect-w-1 aspect-h-1 w-full bg-center bg-cover"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5 sm:mt-6 flex flex-row space-x-4">
                                <button
                                  type="button"
                                  className="inline-flex justify-center w-full rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                  onClick={() =>
                                    handleDeletePictureAttachment(file)
                                  }
                                >
                                  supprimer
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex justify-center w-full rounded border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                  onClick={closePortal}
                                >
                                  fermer
                                </button>
                              </div>
                            </div>
                          </Transition.Child>
                        </div>
                      </Dialog>
                    </Transition.Root>
                  )}
                </>
              )}
            </PortalWithState>
          );
        })}
      </>
    );
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name} className={"text-sm font-mono "}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  useEffect(() => {
    if (pictures) {
      pictures.forEach((file) => URL.revokeObjectURL(file.preview));
    }
    // Make sure to revoke the data uris to avoid memory leaks
  }, [pictures]);

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2">
      <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              Drag &apos;n&apos; drop some files here, or click to select files
            </p>
          </div>
        </section>
      </div>
      <aside className={"grid grid-cols-2 gap-3 mt-4"}>
        <Thumbs />
        {fileRejections.length !== 0 && (
          <>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
          </>
        )}
      </aside>
    </div>
  );
};

export default PicturesManager;
