import React from "react";
import classNames from "classnames";

export const Arrow = ({
  classNameArrowContainer,
  classNameLine,
  dark = false,
  hiddenLine = true,
}) => {
  return (
    <div className={classNames({ "hidden sm:inline-block": hiddenLine })}>
      <div className={classNames("flex items-center", classNameArrowContainer)}>
        <div
          className={classNames(
            "line block w-[32px] lg:w-[42px] h-[1px] mr-[-2px]",
            classNameLine,
            { "bg-gray-600": !dark, "bg-white": dark }
          )}
        />
      </div>
    </div>
  );
};

const MyButton = ({
  label,
  href,
  className,
  hiddenLine = true,
  onClick,
  to,
}) => {
  const Child = () => {
    return (
      <div className="flex items-center gap-x-[16px]">
        <div className="label whitespace-nowrap">{label}</div>
        <Arrow
          hiddenLine={hiddenLine}
          classNameLine="line"
          classNameArrowContainer="arrow_container"
        />
      </div>
    );
  };

  if (href || to) {
    return (
      <a
        href={href || to}
        className={classNames(
          "my_button inline-block",
          {
            "px-[40px] py-[12px] font-avenir-text text-avenir-14 2xl:text-avenir-16 border border-my-grey":
              !className,
          },
          className
        )}
      >
        <Child />
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        "my_button inline-block",
        {
          "px-[40px] py-[12px] font-avenir-text text-avenir-14 2xl:text-avenir-16 border border-my-grey":
            !className,
        },
        className
      )}
    >
      <Child />
    </button>
  );
};

export const MyButtonNative = ({
  type = "button",
  label,
  onClick,
  dark = false,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classNames(
        "my_button inline-block px-[12px] py-[16px] xs:px-[32px] xs:py-[16px] font-avenir-text text-avenir-14 2xl:text-avenir-16 border",
        { "border-my-grey": !dark, "border-white text-white": dark }
      )}
    >
      <div className="flex items-center gap-x-[16px]">
        <div className="label whitespace-nowrap">{label}</div>
        <Arrow
          dark
          classNameLine="line"
          classNameArrowContainer="arrow_container"
        />
      </div>
    </button>
  );
};

export default MyButton;
