import { create } from "zustand";

const useFilterStateStore = create((set) => ({
  isFilterPanelOpen: false,
  setIsFilterPanelOpen: (isOpen) => {
    console.log("setIsFilterPanelOpen", isOpen);
    set({ isFilterPanelOpen: isOpen });
  },
}));

export default useFilterStateStore;
