import React from "react";
import { useUniverses } from "../../_services/universe-services";
import Error from "../Error";
import Loading from "../utils/Loading";
import UniverseItem from "./UniverseItem";

const UniverseItems = () => {
  const { universes, isError, isLoading } = useUniverses();

  if (isError) {
    return <Error error={isError} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-4">
      <div className="mt-2 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
        {universes.data.map((universe, index) => {
          return (
            <UniverseItem key={`universe__${index}`} universe={universe} />
          );
        })}
      </div>
    </div>
  );
};

export default UniverseItems;
