import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import UniverseItems from "../components/universes/UniverseItems";
import univers from "../../../assets/images/univers.jpeg";
import HeroSection from "../components/HeroSection";

const article = {
  title: "Notre cœur de métier",
  body: "<p>Notre cœur de métier réside dans la création de mobilier sur mesure pour les professionnels de l'aménagement d'espaces de restauration collectifs. Forts de notre expertise, nous collaborons avec vous pour donner vie à des projets uniques. Nos solutions de mobilier, conçues avec soin, allient fonctionnalité, esthétique et durabilité. Avec ADM Mobilier, chaque projet devient une réalité sur mesure, adaptée à vos besoins et aspirations.</p>",
  imageURL: univers,
  link: null,
};

const universBC = [
  { label: "Adm mobilier", link: "/" },
  { label: "Nos réalisations", link: "/nos-references" },
];

const UniversesPage = () => {
  return (
    <GenericLayout
      title={"Réalisations d’Adm Mobilier en restaurant, cafés, terrasses...u"}
      h1="Les réalisations d’Adm Mobilier"
    >
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection
            article={article}
            breadcrumbs={universBC}
            index={0}
            isH2
          />
        </div>
      </div>
      <div className="mx-4">
        <h2 className="sm:mt-24 mt-12 font-freight sm:text-40 text-3xl sm:jusitfy-left justify-center tracking-tight">
          Notre métier : les sièges et les tables pour la restauration
        </h2>
      </div>
      <UniverseItems />
    </GenericLayout>
  );
};

export default UniversesPage;
