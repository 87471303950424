import React from "react";
import MyButton from "../my-button/MyButton";
import classNames from "classnames";

export default function Hero({
  heroImage,
  titleH1,
  titleH2,
  texts,
  cta,
  className,
}) {
  return (
    <div className={classNames("grid grid-cols-12", className)}>
      <div className="col-span-full xl:col-start-2 xl:col-end-12 grid grid-cols-12 xl:grid-cols-10 items-center">
        <div className="col-span-full md:col-start-1 space-y-6 md:space-y-8 md:col-end-6 xl:col-end-5">
          <h1
            className="font-playfair text-[#B7A68D] text-[32px] md:text-[40px] uppercase"
            dangerouslySetInnerHTML={{ __html: titleH1 }}
          ></h1>
          <h2
            style={{ lineHeight: "normal" }}
            className="font-playfair text-kakhi-500 font-bold text-[20px] md:text-[24px]"
          >
            {titleH2}
          </h2>
          <div
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: texts }}
          ></div>
          {cta && (
            <div className="w-full">
              <MyButton label={cta?.label} href={cta?.href} />
            </div>
          )}
        </div>
        <div className="col-span-full md:col-start-7 md:xl:col-start-6 col-end-13 xl:col-end-11 mt-[64px] md:mt-0">
          <img
            className="w-full h-[328px] md:h-[512px] 2xl:h-auto rounded-md object-left object-cover
            "
            src={heroImage}
            alt={titleH1}
          />
        </div>
      </div>
    </div>
  );
}
