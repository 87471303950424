import { useAuth } from "../hooks/useAuth";
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  AdjustmentsVerticalIcon,
  BriefcaseIcon,
  ChatBubbleLeftIcon,
  CogIcon,
  MagnifyingGlassIcon,
  HomeIcon,
  Bars3Icon,
  UsersIcon,
  ArrowRightOnRectangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import {
  TagIcon,
  StarIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import logo from "../../../assets/images/logo.png";

import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

const navigation = [
  { name: "Produits", href: "/admin/products", icon: HomeIcon, current: false },
  {
    name: "Sous-catégories",
    href: "/admin/categories",
    icon: BriefcaseIcon,
    current: false,
  },
  {
    name: "Familles",
    href: "/admin/families",
    icon: UsersIcon,
    current: false,
  },
  {
    name: "Tags",
    href: "/admin/tags",
    icon: TagIcon,
    current: false,
  },
  {
    name: "Matériaux",
    href: "/admin/materials",
    icon: CogIcon,
    current: false,
  },
  {
    name: "Filtres",
    href: "/admin/filters",
    icon: AdjustmentsVerticalIcon,
    current: false,
  },
  {
    name: "Univers",
    href: "/admin/univers",
    icon: MagnifyingGlassIcon,
    current: false,
  },
  {
    name: "Inspirations",
    href: "/admin/inspirations",
    icon: ChatBubbleLeftIcon,
    current: false,
  },
  {
    name: "Realisations",
    href: "/admin/realisations",
    icon: RectangleGroupIcon,
    current: false,
  },
  {
    name: "References",
    href: "/admin/references",
    icon: StarIcon,
    current: false,
  },
  {
    name: "Prospect",
    href: "/admin/prospects",
    icon: ChatBubbleLeftIcon,
    current: false,
  },
  { name: "Contacts", href: "/admin/contacts", icon: UsersIcon, current: true },
];

const tabs = [
  { name: "General", href: "#", current: true },
  { name: "Password", href: "#", current: false },
  { name: "Notifications", href: "#", current: false },
  { name: "Plan", href: "#", current: false },
  { name: "Billing", href: "#", current: false },
  { name: "Team Members", href: "#", current: false },
];

const AdminLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logOut(() => {
      navigate("/");
    });
  };

  return (
    <div id={"admin-layout"}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:bg-gray-600"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <img className="h-8 w-auto" src={logo} alt="Adm mobilier" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <div className="space-y-1">
                    {navigation.map((item) => {
                      return (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            {
                              "bg-kakhi-100 border-kakhi-600 text-kakhi-600 hover:bg-kakhi-200":
                                location.pathname.includes(item.href),
                              "border-transparent text-gray-600 hover:bg-gray-500 hover:text-gray-900":
                                !location.pathname.includes(item.href),
                            },
                            "group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                          )}
                          aria-current={
                            location.pathname === item.href ? "page" : undefined
                          }
                        >
                          <item.icon
                            className={classNames(
                              location.pathname.includes(item.href)
                                ? "text-kakhi-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      );
                    })}
                  </div>
                  <div className="mt-auto pt-10 space-y-1">
                    <button
                      onClick={handleLogout}
                      className="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                    >
                      Déconnexion
                      <ArrowRightOnRectangleIcon
                        className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <nav className="bg-gray-50 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
          <div className="flex-shrink-0 px-4 flex items-center">
            <h2 className={"font-bold"}>ADM mobilier</h2>
          </div>
          <div className="flex-grow mt-5">
            <div className="space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    {
                      "bg-kakhi-100 border-kakhi-600 text-kakhi-600":
                        !!location.pathname.includes(item.href),
                      "border-transparent text-gray-600 hover:bg-kakhi-500 hover:text-kakhi-100":
                        !location.pathname.includes(item.href),
                    },
                    "group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                  )}
                >
                  <item.icon
                    className={classNames(
                      {
                        "text-kakhi-500": !!location.pathname.includes(
                          item.href
                        ),
                        "text-gray-400 group-hover:text-gray-500":
                          !location.pathname.includes(item.href),
                      },
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex-shrink-0 block w-full">
            <button
              onClick={handleLogout}
              className="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
            >
              <ArrowRightOnRectangleIcon
                className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Déconnexion
            </button>
          </div>
        </nav>
      </div>

      {/* Content area */}
      <div className="md:pl-64">
        <div className="max-w-screen-2xl mx-auto flex flex-col md:px-8 xl:px-0">
          <div className="sticky top-0 z-10 flex-shrink-0 flex">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-kakhi-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <main className="flex-1">{children}</main>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
