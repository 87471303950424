import { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";
import _ from "lodash";

export const useProductsOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_products_options`,
    getFetcher
  );
  return {
    products: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useProductCount = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/count_products`,
    getFetcher
  );

  return {
    count: data,
    isLoading: !error && !data,
    isError: error,
  };
};
