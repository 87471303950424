import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  createTag,
  editTag,
  getTag,
  deleteTag,
} from "../../../redux/slices/tag";
import { useSelector } from "react-redux";
import { useTag } from "../../../_services/tag-services";
import Loading from "../../../components/utils/Loading";

const AdminTagsAddEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { tag_id } = useParams();
  const [tagName, setTagName] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  // const state = useSelector((state) => state);
  let isEditMode = !!tag_id;

  const { tag, isLoading, isError } = useTag(tag_id);

  const onSubmit = () => {
    isEditMode
      ? dispatch(editTag({ tag_id: tag_id, name: tagName }))
      : dispatch(createTag(tagName));
    goBack();
  };

  const goBack = () => {
    navigate("/admin/tags");
  };

  const handleDelete = () => {
    if (!tag_id) return;
    deleteTag(tag_id, goBack);
  };

  const getTagAsync = async (tag_id) => {
    setIsLoaded(true);
    const resp = await dispath(getTag(tag_id));
    setIsLoaded(false);
  };

  useEffect(() => {
    if (tag?.data[0]) {
      setTagName(tag.data[0].attributes.name);
      // getTagAsync(tag_id);
    }
  }, [tag]);

  if (isError) return false;

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>
          {tag_id ? "Edition d'un" : "Création d'un nouveau "} tag
        </h3>
      </div>
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Nom du tag
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                required
                name="name"
                value={tagName}
                className="max-w-lg block w-full shadow-sm focus:ring-kakhi-200 focus:border-kakhi-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
                type="text"
                onChange={(e) => setTagName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="py-5 flex flex-row justify-between">
          {tag_id && (
            <div>
              <button
                onClick={handleDelete}
                className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Supprimer ce tag
              </button>
            </div>
          )}
          <div className="flex justify-end">
            <button
              onClick={goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500 cursor-pointer"
            >
              Annuler
            </button>
            <button
              onClick={onSubmit}
              className={classNames(
                !isLoaded
                  ? "bg-kakhi-400"
                  : "bg-kakhi-600 hover:bg-kakhi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kakhi-500",
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white cursor-pointer focus:ring-kakhi-700"
              )}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTagsAddEditPage;
