/* eslint-disable no-undef */
import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import heroImg from "../../../assets/images/page-references/hero.png";
import HeroSection from "../components/HeroSection";

// import { ServiceItem } from "./Homepage";

// images secteurs activites
import img1 from "../../../assets/images/page-references/secteur-activite/img1.png";
import img2 from "../../../assets/images/page-references/secteur-activite/img2.png";
import img3 from "../../../assets/images/page-references/secteur-activite/img3.png";
import img4 from "../../../assets/images/page-references/secteur-activite/img4.png";
import img5 from "../../../assets/images/page-references/secteur-activite/img5.png";
import img6 from "../../../assets/images/page-references/secteur-activite/img6.png";
import img7 from "../../../assets/images/page-references/secteur-activite/img7.png";
import img8 from "../../../assets/images/page-references/secteur-activite/img8.png";

const secteursActivites = [
  {
    title: "Café / Bar",
    text: "Découvrez notre mobilier sur mesure pour l'atmosphère conviviale des cafés-bars.",
    img: img1,
  },
  {
    title: "Cafétéria",
    text: "Découvrez nos références dans l'aménagement de cafétérias inspirantes et fonctionnelles.",
    img: img2,
  },
  {
    title: "Centre de santé",
    text: "Explorez nos réalisations dans l'aménagement de centres de santé",
    img: img3,
  },
  {
    title: "Restaurant",
    text: "Découvrez nos références dans le monde de la restauration",
    img: img4,
  },
  {
    title: "Restauration d'enseignement",
    text: "Consultez nos projets d'aménagement pour la restauration d'enseignement.",
    img: img5,
  },
  {
    title: "Restaurant d'entreprise",
    text: "Découvrez nos réalisations dans le domaine des restaurants d'entreprise.",
    img: img6,
  },
  {
    title: "Terrasse",
    text: "Explorez nos références en matière d'aménagement de terrasses.",
    img: img7,
  },
  {
    title: "Espace collectif",
    text: "Découvrez nos projets dans la création d'espaces collectifs inspirants.",
    img: img8,
  },
];

export const ReferenceItem = ({ title, img, text, href = "#" }) => {
  return (
    <div className="service-item-home mt-[32px] 2xl:mt-[50px] block">
      <div className="h-auto w-full overflow-hidden rounded-xl image-card-realization aspect-1 bg-cover bg-center">
        <img src={img} className="w-full h-full" alt={title} />
      </div>
      <div className="w-full mt-[8px]">
        <h3 className="font-playfair font-medium text-14 lg:text-16 2xl:text-20 flex items-center gap-x-[8px] lg:gap-x-[16px]">
          <span className="block">{title}</span>
        </h3>
        <p className="font-avenir-text text-avenir-14 2xl:text-avenir-16 mt-[4px] lg:mt-[8px]">
          {text}
        </p>
      </div>
    </div>
  );
};

const dataHero = {
  title: "Notre coeur de métier",
  body: "Notre cœur de métier réside dans la création de mobilier sur mesure pour les professionnels de l'aménagement d'espaces de restauration collectifs. Forts de notre expertise, nous collaborons avec vous pour donner vie à des projets uniques. Nos solutions de mobilier, conçues avec soin, allient fonctionnalité, esthétique et durabilité. Avec ADM Mobilier, chaque projet devient une réalité sur mesure, adaptée à vos besoins et aspirations.",
  imageURL: heroImg,
};

const breadCrumbs = [
  {
    label: "Accueil",
    url: "/",
  },
  {
    label: "Références",
    url: "/references",
  },
];

const ReferencePage = () => {
  // ROLLBACK UNTIL WE HAVE REAL DATA
  // const { univers } = useSelector((state) => state.realizations);
  // const dispatch = useDispatch();

  // const secteursActivites = useMemo(() => {
  //   if (univers?.length) {
  //     return univers.map(
  //       ({ attributes: { cover_picture_url, name, description, slug } }) => ({
  //         title: name,
  //         text: description,
  //         img: cover_picture_url,
  //         slug,
  //       })
  //     );
  //   }
  //   return null;
  // }, [univers]);

  // useEffect(() => {
  //   dispatch(getUnivers());
  // }, []);

  return (
    <GenericLayout
      title="Nos références | Adm mobilier"
      h1="Nos références"
      breadCrumbs={breadCrumbs}
    >
      <HeroSection article={dataHero} />
      <div className="px-[20px] py-[64px] lg:py-[88px] 2xl:py-[120px]">
        <h2 className="font-playfair font-bold text-20 lg:text-24 2xl:text-32">
          Nos différents secteurs d&apos;activités
        </h2>
        <p>
          <i>La liste de nos références sera bientôt disponible.</i>
        </p>
        <section className="w-full py-12">
          <div className="grid gap-6 md:gap-8 px-4 md:px-6">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              {secteursActivites &&
                secteursActivites.map(({ title, text, img, slug }) => (
                  <ReferenceItem
                    key={slug}
                    title={title}
                    text={text}
                    img={img}
                    href={`/nos-realisations/${slug}`}
                  />
                ))}
            </div>
          </div>
        </section>
      </div>
    </GenericLayout>
  );
};

export default ReferencePage;
